import React from 'react'
import { connect } from 'react-redux'
import {
  Nav,
  NavLink,
  NavItem
} from 'reactstrap'

import ProtectedComponent from '../../common/ProtectedComponent'

import { NavLink as Link } from 'react-router-dom'

import Button from '../../common/Button'

const NavTabs = props => {
  const urlBase = '/project/' + props.uuid
  return (
    <Nav tabs className='d-flex align-items-center'>
      <NavItem>
        <NavLink
          exact
          tag={Link}
          to={`${urlBase}`}
        >
        Summary
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          to={`${urlBase}/tasks`}
        >
        Tasks
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          to={`${urlBase}/tickets`}
        >
        Tickets
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          to={`${urlBase}/phases`}
        >
        Phases
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          tag={Link}
          to={`${urlBase}/files`}
        >
        Files
        </NavLink>
      </NavItem>
      <NavItem className='d-none'>
        <NavLink
          tag={Link}
          to={`${urlBase}/notes`}
        >
        Notes
        </NavLink>
      </NavItem>
      <ProtectedComponent
        protectionOptions={{
          group: { value: 2, exact: false },
          level: { value: 3, exact: false }
        }}>
        <Button
          tag={Link}
          className='mx-2'
          size='small'
          to={`${urlBase}/settings`}
        >
          Settings
        </Button>
      </ProtectedComponent>
    </Nav>
  )
}

const mapStateToProps = (stateProps, ownProps) => ({
  // TODO: maybe badges with count of data?
})

export default connect(
  mapStateToProps,
  {}
)(NavTabs)
