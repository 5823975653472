import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncFiles } from '../../../../../actions/middleware/Files.Actions'

import { Row, Col } from 'reactstrap'

import FileListItem from '../../../../common/FileListItem'

class List extends Component {
  componentDidMount () {
    this.props.syncFiles()
  }

  render () {
    return (
      <Row>
        <Col>
          list of files
          {this.props.files.map(f =>
            <FileListItem
              key={f.uuid}
              withLink
              {...f} />)}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ files }, ownProps) => {
  return {
    files: files.filter(f => f.project === ownProps.project)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncFiles: () => dispatch(syncFiles(ownProps.project))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
