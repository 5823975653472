const initialState = {
  uuid: '',
  email: '',
  username: '',
  role: {
    group: 0,
    level: 0
  },
  profile: {
    name: '',
    phone: '',
    thumb: { s: '', n: '' }
  },
  billing: {} // billing data can be usable
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_SET':
      return Object.assign({}, state, action.payload)
    case 'USER_UPDATE_PROFILE':
      return Object.assign({}, state, { profile: action.payload })
    case 'USER_RESET':
      return Object.assign({}, {
        uuid: '',
        email: ''
      })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default UserReducer
