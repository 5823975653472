const defaultObject = {
  uuid: '',
  name: '',
  thumb: {
    s: '',
    n: ''
  },
  members: [],
  creator: '',
  error: '',
  syncedAt: Date.now(),
  syncing: false
}

export const createGroupObject = entity => {
  return Object.assign(
    {},
    defaultObject,
    entity
  )
}
