export const createProject = form => ({
  type: 'PROJECTS',
  payload: {
    operation: 'CREATE',
    form
  }
})

export const updateProjectStatus = (uuid, status) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'UPDATE_STATUS',
    project: uuid,
    status
  }
})

export const updateProjectProperties = (uuid, form) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'UPDATE_PROPERTIES',
    project: uuid,
    form
  }
})

export const syncProject = uuid => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC',
    uuid
  }
})

export const syncProjectlist = uuid => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC_LIST',
    uuid
  }
})

export const syncParticipants = uuid => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC_PARTICIPANTS',
    uuid
  }
})

export const grantAccess = (project, entity) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'GRANT_ACCESS',
    project,
    entity
  }
})

export const revokeAccess = (project, entity) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'REVOKE_ACCESS',
    project,
    entity
  }
})

export const syncPhases = uuid => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC_PHASES',
    uuid
  }
})

export const addPhase = (project, name) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'ADD_PHASE',
    project,
    name
  }
})

export const updatePhase = (project, phase, name) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'UPDATE_PHASE',
    project,
    phase,
    name
  }
})

export const deletePhase = (project, phase) => ({
  type: 'PROJECTS',
  payload: {
    operation: 'DELETE_PHASE',
    project,
    phase
  }
})

export const syncTasks = uuid => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC_TASKS',
    uuid
  }
})

export const syncAllActivities = () => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC_ALL_ACTIVITIES'
  }
})

export const syncTickets = uuid => ({
  type: 'PROJECTS',
  payload: {
    operation: 'SYNC_TICKETS',
    uuid
  }
})
