// import { resetUser } from '../../../actions/reducer/User.Actions'

import {
  reset
} from '../../../actions/Global.Actions'

import { push } from 'connected-react-router'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const deleteSession = () => {
  const cookieOptions = { path: '/' }
  // TODO: .env file
  if (process.env.NODE_ENV === 'production') {
    cookieOptions.secure = true
    cookieOptions.domain = '.infinite-edu.hu'
  }
  cookies.remove('session', cookieOptions)
  cookies.remove('token', cookieOptions)
}

const signOut = () => (dispatch, _Backend) => {
  return _Backend.call('signOut')
    .finally(() => {
      deleteSession()
      _Backend.emit('abort')
      dispatch(reset())
      dispatch(push('/auth/sign-in'))
      return Promise.resolve()
    })
}

export default signOut
