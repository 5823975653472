
import {
  updateProjectItem
} from '../../../actions/reducer/Projects.Actions'

const updateStatus = (uuid, status) => (dispatch, _Backend) => {
  return _Backend.call('updateProjectStatus', uuid, status)
    .then((result) => {
      // TODO: update project!
      dispatch(updateProjectItem({ uuid, status }))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default updateStatus
