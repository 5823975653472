import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createTicket } from '../../../../actions/middleware/Tickets.Actions'
import { resetForm } from '../../../../actions/reducer/Form.Actions'

import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

// back button maybe import { Link } from 'react-router-dom'

import Button from '../../../common/Button'

class AddTicket extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      description: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let {
      name,
      description
    } = this.state
    this.props.createTicket({
      name,
      description
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangeDescription (e) {
    let description = e.target.value
    this.setState({
      description,
      isValidDescription: !!description
    })
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  render () {
    const { isValidName, isValidDescription } = this.state
    const canSubmit = isValidName && isValidDescription
    return (
      <Col md={{ size: 6, offset: 3 }}>
        <Form
          onSubmit={this.handleSubmit}
          encType='multipart/form-data'
          className='border rounded shadow-sm my-4 p-3'>
          <h5>Submit new ticket</h5>
          {this.props.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.errors.map(el => el).join(' ')}
          </Alert>}
          <FormGroup>
            <Label for='phase-name'>Summary</Label>
            <Input
              type='text'
              maxLength={128}
              name='phase-name'
              id='phase-name'
              tabIndex={1}
              placeholder='Task name'
              disabled={this.props.submitting}
              invalid={!!this.state.name && !isValidName}
              onChange={this.handleChangeName}
              required />
            <FormText color='muted'>
                  128/{this.state.name.length}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='project-description'>Details</Label>
            <Input
              type='textarea'
              maxLength={10000}
              name='project-description'
              id='project-description'
              tabIndex={2}
              placeholder='Details...'
              invalid={!!this.state.description && !isValidDescription}
              onChange={this.handleChangeDescription}
              disabled={this.props.submitting}
              required />
            <FormText color='muted'>
              10000/{this.state.description.length}
            </FormText>
          </FormGroup>
          <Button
            color='add'
            type='submit'
            tabIndex={3}
            disabled={!canSubmit || this.props.submitting}
          >
            Submit
          </Button>
        </Form>
      </Col>
    )
  }
}

const mapStateToProps = ({ form, projects }, ownProps) => ({
  project: projects
    .items.find(pr => pr.uuid === ownProps.match.params.projectUuid),
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  createTicket: form => dispatch(createTicket(
    ownProps.match.params.projectUuid,
    form)),
  resetForm: () => dispatch(resetForm())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTicket)
