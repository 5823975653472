
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,64}/
const secretRegex = /^[a-z0-9]{6,6}$/
const usernameRegex = /^[a-z0-9]{8,32}$/

const isEmail = (value) => {
  return emailRegex.test(value)
}

const isPassword = (value) => {
  return passwordRegex.test(value)
}

const isSecret = (value) => {
  return secretRegex.test(value)
}

const isUsername = (value) => {
  return usernameRegex.test(value)
}

const compressImage = (
  fileInputChangeEvent,
  { width, height }
) => {
  return new Promise((resolve, reject) => {
    // const fileName = fileInputChangeEvent.target.files[0].name
    const reader = new window.FileReader()
    reader.readAsDataURL(fileInputChangeEvent.target.files[0])
    reader.onload = event => {
      const inputImage = new window.Image()
      inputImage.src = event.target.result
      inputImage.onload = () => {
        // let's store the width and height of our image
        const inputWidth = inputImage.naturalWidth
        const inputHeight = inputImage.naturalHeight

        // get the aspect ratio of the input image
        const inputImageAspectRatio = inputWidth / inputHeight
        const aspectRatio = width / height

        // if it's bigger than our target aspect ratio
        let outputWidth = inputWidth
        let outputHeight = inputHeight
        if (inputImageAspectRatio > aspectRatio) {
          outputWidth = inputHeight * aspectRatio
        } else if (inputImageAspectRatio < aspectRatio) {
          outputHeight = inputHeight / aspectRatio
        }

        // calculate the position to draw the image at
        const outputX = (outputWidth - inputWidth) * 0.5
        const outputY = (outputHeight - inputHeight) * 0.5

        // create a canvas that will present the output image
        const outputImage = document.createElement('canvas')

        // set it to the same size as the image
        outputImage.width = width
        outputImage.height = height

        // console.log('out dimensions', outputX, outputY, outputWidth, outputHeight)

        // draw our image at position 0, 0 on the canvas
        const ctx = outputImage.getContext('2d')
        ctx.drawImage(inputImage,
          -outputX, -outputY,
          outputWidth, outputHeight,
          0, 0,
          width, height
        )
        ctx.canvas.toBlob((blob) => {
          const file = new window.File([blob], 'thumb.png', {
            type: 'image/png',
            lastModified: Date.now()
          })
          return resolve(file)
        }, 'image/png', 1)
      }
      reader.onerror = error => {
        return reject(error)
      }
    }
  })
}

const _idGenerator = function * () {
  var index = 1
  while (true) { yield index++ }
}
const gen = _idGenerator()

const generateId = () => gen.next().value

export {
  isEmail,
  isPassword,
  isSecret,
  isUsername,
  generateId,
  compressImage
}
