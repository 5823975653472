// import functors
import { account } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const AccountMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'ACCOUNT') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'UPDATE_PROFILE':
      chain(
        account.updateProfile(action.payload.form)
      )(dispatch)
      break
    case 'UPDATE_PASSWORD':
      chain(
        account.updatePassword(action.payload.form)
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default AccountMiddleware
