import React, { Component } from 'react'
import { connect } from 'react-redux'

import { commentTicket } from '../../../../actions/middleware/Tickets.Actions'

import {
  Form,
  FormGroup,
  Input,
  FormText,
  Alert
} from 'reactstrap'

import Button from '../../../common/Button'

// TODO: error, chaining syncs no project uuid while render link to phase

class CommentForm extends Component {
  constructor () {
    super()

    this.state = {
      body: ''
    }

    this.commentRef = React.createRef()

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeBody = this.handleChangeBody.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.commentTicket(this.state.body)
  }

  handleChangeBody (e) {
    let body = e.target.value
    this.setState({
      body
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.form.submitting &&
      !this.props.form.submitting &&
      this.props.form.errors.length === 0) {
      this.commentRef.current.value = ''
      this.setState({ body: '' })
    }
  }

  render () {
    return (
      <Form
        onSubmit={this.handleSubmit}
        encType='multipart/form-data'
        className='p-3'>
        {this.props.form.errors.length > 0 &&
          <Alert color='danger'>
            {this.props.form.errors
              .map((el, i) =>
                <span key={i}>{el}</span>
              )
            }
          </Alert>}
        <FormGroup>
          <Input
            innerRef={this.commentRef}
            type='textarea'
            name='text'
            id='comment'
            maxLength={2000}
            disabled={this.props.form.submitting}
            onChange={this.handleChangeBody}
            placeholder='Comment...'
          />
          <FormText color='muted'>
            2000/{this.state.body.length}
          </FormText>
        </FormGroup>
        <Button color='add'
          type='submit'
          tabIndex={3}
          disabled={!this.state.body || this.props.form.submitting}
        >
          Comment
        </Button>
      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  commentTicket: comment => dispatch(commentTicket(
    ownProps.uuid,
    {
      FormId: ownProps.form.id,
      FormFields: {
        comment
      }
    }
  ))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentForm)
