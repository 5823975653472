export const addUser = projectObject => ({
  type: 'USERS_ADD_ITEM',
  payload: projectObject
})

export const updateUser = projectObject => ({
  type: 'USERS_UPDATE_ITEM',
  payload: projectObject
})

export const removeUser = uuid => ({
  type: 'USERS_REMOVE_ITEM',
  payload: uuid
})

export const setUserlistSyncedAt = syncedAt => ({
  type: 'USERS_SET_SYNCED_AT',
  payload: syncedAt
})

export const setUserlistSyncing = isSyncing => ({
  type: 'USERS_SET_SYNCING',
  payload: isSyncing
})

export const setUserSyncing = (uuid, isSyncing) => ({
  type: 'USERS_SET_USER_SYNCING',
  payload: {
    uuid,
    isSyncing
  }
})
