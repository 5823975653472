
import {
  addTicketAssignee
} from '../../../actions/reducer/Ticket.Actions'

const assign = (ticket, user) => (dispatch, _Backend) => {
  return _Backend.call('assignUserToTicket', ticket, user)
    .then((result) => {
      // TODO: update task!
      dispatch(addTicketAssignee(ticket, user))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default assign
