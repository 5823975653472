
import {
  removeTaskAssignee
} from '../../../actions/reducer/Task.Actions'

const unassign = (task, user) => (dispatch, _Backend) => {
  return _Backend.call('unassignUser', task, user)
    .then((result) => {
      // TODO: update task!
      dispatch(removeTaskAssignee(task, user))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default unassign
