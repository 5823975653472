/**
 * Translations
 */

const hu = {
  /* Sign in form */
  TXT_SIGN_IN_FORM__HEADING: 'Bejelentkezés',
  TXT_SIGN_IN_FORM__INPUT_LABEL_EMAIL: 'E-mail cím',
  TXT_SIGN_IN_FORM__INPUT_PLACEHOLDER_EMAIL: 'E-mail cím',
  TXT_SIGN_IN_FORM__INPUT_LABEL_PASSWORD: 'Jelszó',
  TXT_SIGN_IN_FORM__INPUT_PLACEHOLDER_PASSWORD: 'Jelszó',
  TXT_SIGN_IN_FORM__INPUT_LABEL_REMEMBER: 'Emlékezzen rám',
  TXT_SIGN_IN_FORM__BUTTON_LABEL_SIGN_IN: 'Bejelentkezés',
  TXT_SIGN_IN_FORM__BUTTON_LABEL_FORGOTTEN_PASSWORD: 'Elfelejtette a jelszavát?',
  TXT_SIGN_IN_FORM__ERR_GENERIC: 'Úgy tűnik, valamiért nem tudtuk elfogadni a bejelentkezési kísérleted, próbáld meg később!',
  TXT_SIGN_IN_FORM__ERR_INVALID_EMAIL_OR_PASSWORD: 'Érvénytelen e-mail cím vagy jelszó!',
  TXT_SIGN_IN_FORM__ERR_BLOCKED_ACCOUNT: 'Ez a fiók le van tiltva!',
  TXT_SIGN_IN_FORM__FEEDBACK_PASSWORD_UPDATED: 'A jelszó sikeresen módosítva, most már az új jelszavaddal tudsz bejelentkezni!',
  TXT_SIGN_IN_FORM__FEEDBACK_ACCOUNT_CREATED: 'A fiókod elkészült, most már be tudsz jelentkezni!',
  TXT_SIGN_IN_FORM__FEEDBACK_ACCOUNT_DELETED: 'A fiókod sikeresen törölted!',

  /* Forgotten password form */
  TXT_FORGOTTEN_PASSWORD_FORM__HEADING: 'Elfelejtett jelszó',
  TXT_FORGOTTEN_PASSWORD_FORM__INPUT_LABEL_EMAIL: 'E-mail cím',
  TXT_FORGOTTEN_PASSWORD_FORM__INPUT_PLACEHOLDER_EMAIL: 'E-mail cím',
  TXT_FORGOTTEN_PASSWORD_FORM__SUCCESS_MESSAGE: 'Elküldtünk egy e-mailt a további teendőkkel!',
  TXT_FORGOTTEN_PASSWORD_FORM__ERR_GENERIC: 'Úgy tűnik, valami nem sikerült, próbáld meg később!',
  TXT_FORGOTTEN_PASSWORD_FORM__BUTTON_LABEL_SEND: 'Küldés',
  TXT_FORGOTTEN_PASSWORD_FORM__BUTTON_LABEL_BACK_TO_SIGN_IN: 'Vissza a bejelentkezéshez',

  /* Reset password form */
  TXT_RESET_PASSWORD_FORM__HEADING: 'Jelszó visszaállítása',
  TXT_RESET_PASSWORD_FORM__INPUT_LABEL_EMAIL: 'E-mail cím',
  TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_EMAIL: 'E-mail cím',
  TXT_RESET_PASSWORD_FORM__INPUT_LABEL_NEW_PASSWORD: 'Új jelszó',
  TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_NEW_PASSWORD: 'Új jelszó',
  TXT_RESET_PASSWORD_FORM__TOOLTIP_PASSWORD: 'Jelszó formai követelményei: minimum 8 karakter, tartalmazzon legalább egy nagybetűt, egy kisbetűt és egy számot.',
  TXT_RESET_PASSWORD_FORM__INPUT_LABEL_RETYPE_PASSWORD: 'Új jelszó megerősítése',
  TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD: 'Új jelszó',
  TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_SEND: 'Elküld',
  TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_GO_TO_SIGN_IN: 'Bejelentkezés',
  TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_FORGOTTEN_PASSWORD: 'Elfelejtett jelszó',
  TXT_RESET_PASSWORD_FORM__SUCCESS_MESSAGE: 'A jelszó sikeresen be lett állítva, most már be tudsz jelentkezni az új jelszavaddal!',
  TXT_RESET_PASSWORD_FORM__ERR_GENERIC: 'Úgy tűnik, valami nem sikerült, próbáld meg később!',
  TXT_RESET_PASSWORD_FORM__ERR_INVALID_EMAIL: 'E-mail cím helytelen!',
  TXT_RESET_PASSWORD_FORM__ERR_INVALID_REQUEST: 'Ez a kérés érvénytelen, ellenőrizd a megnyitott linket!',
  TXT_RESET_PASSWORD_FORM__ERR_INVALID_PASSWORD: 'A jelszó nem felel meg a követelményeknek!',
  TXT_RESET_PASSWORD_FORM__ERR_NO_USER: 'Úgy tűnik, elírtad az e-mail címed!',
  TXT_RESET_PASSWORD_FORM__ERR_WRONG_OR_EXPIRED_REQUEST: 'Ez a kérés érvénytelen vagy lejárt, ellenőrizd a megnyitott linket vagy kérj új visszaállítást!',

  /* Update password form */
  TXT_UPDATE_PASSWORD_FORM__HEADING: 'Jelszó megváltoztatása',
  TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_CURRENT_PASSWORD: 'Jelenlegi jelszó',
  TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_CURRENT_PASSWORD: 'Jelenlegi jelszó',
  TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_NEW_PASSWORD: 'Új jelszó',
  TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_NEW_PASSWORD: 'Új jelszó',
  TXT_UPDATE_PASSWORD_FORM__TOOLTIP_PASSWORD: 'Jelszó formai követelményei: minimum 8 karakter, tartalmazzon legalább egy nagybetűt, egy kisbetűt és egy számot.',
  TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_RETYPE_PASSWORD: 'Új jelszó megerősítése',
  TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD: 'Új jelszó',
  TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_SEND: 'Megváltoztat',
  TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_MODAL_OK: 'Rendben, folytatom',
  TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_MODAL_CANCEL: 'Mégse',
  TXT_UPDATE_PASSWORD_FORM__ERR_GENERIC: 'Úgy tűnik, valami nem sikerült, próbáld meg később!',
  TXT_UPDATE_PASSWORD_FORM__ERR_INVALID_OLD_PASSWORD: 'A jelenlegi jelszó nem felel meg a követelményeknek, talán elírtad!',
  TXT_UPDATE_PASSWORD_FORM__ERR_INVALID_NEW_PASSWORD: 'Az új jelszó nem felel meg a követelményeknek!',
  TXT_UPDATE_PASSWORD_FORM__ERR_WRONG_PASSWORD: 'Helytelen jelenlegi jelszó!',

  /* Create account form */
  TXT_CREATE_ACCOUNT_FORM__HEADING: 'Fiók létrehozása',
  TXT_CREATE_ACCOUNT_FORM__TOOLTIP_EMAIL: 'Fiók létrehozása ehhez az e-mail címhez:',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_USERNAME: 'Felhasználónév',
  TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_USERNAME: 'felhasznalo123',
  TXT_CREATE_ACCOUNT_FORM__TOOLTIP_USERNAME: 'Egyedi azonosító, amelyet te adsz meg és a rendszerben ezen a néven látszódnak majd a tevékenységeid. Csak kisbetűket és számokat tartalmazhat. (ékezetes betűk és speciális karakterek nem engedélyezettek)',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_PASSWORD: 'Jelszó',
  TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_PASSWORD: 'Jelszó',
  TXT_CREATE_ACCOUNT_FORM__TOOLTIP_PASSWORD: 'Jelszó formai követelményei: minimum 8 karakter, tartalmazzon legalább egy nagybetűt, egy kisbetűt és egy számot.',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_RETYPE_PASSWORD: 'Jelszó megerősítése',
  TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD: 'Jelszó',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_TERMS: 'Elolvastam és elfogadom a felhasználási feltételeket.',
  TXT_CREATE_ACCOUNT_FORM__BUTTON_LABEL_SEND: 'Létrehoz',
  TXT_CREATE_ACCOUNT_FORM__BUTTON_LABEL_GO_TO_SIGN_IN: 'Bejelentkezés',
  TXT_CREATE_ACCOUNT_FORM__ERR_GENERIC: 'Úgy tűnik, valami nem sikerült, próbáld meg később!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_HASH: 'Ez a kérés érvénytelen, ellenőrizd a megnyitott linket!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_EMAIL: 'E-mail cím helytelen!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_USERNAME: 'A felhasználónév nem felel meg a követelményeknek!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_PASSWORD: 'A jelszó nem felel meg a követelményeknek!',
  TXT_CREATE_ACCOUNT_FORM__ERR_USERNAME_EXISTS: 'Ez a felhasználónév már foglalt! Válassz másikat!',

  TXT_DEMO: 'Demo of %1$s',
  TXT_STATUS: 'Állapot',
  TXT_STATUS_OPEN: 'Nyitott',
  TXT_STATUS_PENDING: 'Ellenőrzés alatt',
  TXT_STATUS_CLOSED: 'Lezárt',
  TXT_PRIORITY: 'Prioritás',
  TXT_PRIORITY_LOW: 'Alacsony',
  TXT_PRIORITY_MEDIUM: 'Közepes',
  TXT_PRIORITY_HIGH: 'Magas',
  TXT_PROJECT: 'Projekt',
  TXT_ASSIGNEES: 'Megbízottak',
  TXT_NO_ASSIGNEES: 'Jelenleg nincs megbízott',
  TXT_ASSIGN_ADD: 'Hozzáadás',
  TXT_ASSIGN_REMOVE: 'Eltávolít',
  TXT_PARTICIPANTS: 'Résztvevők',
  TXT_ADD_TO_QUICK_OPEN: 'Hozzáadás a gyors eléréshez',
  TXT_ACTIVITIES: 'Tevékenységek',
  TXT_ACTIVITIES_CHANGED_STATUS: 'megváltoztatta az állapotot erre:',
  TXT_ACTIVITIES_CHANGED_PRIORITY: 'megváltoztatta a prioritást erre:',
  TXT_ACTIVITIES_ASSIGNED: 'hozzárendelte a feladathoz őt:',
  TXT_ACTIVITIES_REMOVED_ASSIGNMENT: 'eltávolítta a feladat megbízottjai közül őt:',
  TXT_ACTIVITIES_COMMENTED: 'hozzászólt',
  TXT_ACTIVITIES_CREATED_TASK: 'létrehozta ezt a feladatot',
  TXT_ACTIVITIES_CREATED_TICKET: 'létrehozta ezt a hibajegyet',
  TXT_ACTIVITIES_CHANGED_ESTIMATED_WORK: 'megváltoztatta a becsült munkaidő mértékét erre:',
  TXT_ESTIMATED_WORK: 'Becsült munkaidő',
  TXT_NO_ESTIMATED_WORK: 'Nincs becsült érték megadva',
  TXT_MAN_HOURS: '%1$s munkaóra'
}

export default hu
