import React from 'react'

import { CONST_ROLE_GROUPS } from '../../../../constants/Role.Constants'

import { Button } from 'reactstrap'

const ManageGroup = ({ update, group }) => {
  return <div>
    actual group: {group}
    <hr />
    {CONST_ROLE_GROUPS
      .map(g =>
        <Button
          onClick={() => update(g.id)}
          disabled={g.id === group}
        >
          Change user's group to: {g.label}
        </Button>)
    }
  </div>
}

export default ManageGroup
