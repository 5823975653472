import React from 'react'
import { connect } from 'react-redux'

import { updateProjectStatus } from '../../../../actions/middleware/Projects.Actions'

import {
  Button
} from 'reactstrap'

const UpdateStatus = ({ project: { status }, update }) => {
  if (status === 0) {
    // hidden
    return (
      <>
        <Button onClick={() => update(1)}>
          Launch project
        </Button>
      </>
    )
  } else if (status === 1) {
    // open
    return (
        <>
          <Button onClick={() => update(0)}>
            Revert status to hidden
          </Button>
          <Button onClick={() => update(2)}>
            Activate tickets
          </Button>
          <Button onClick={() => update(3)}>
            Close project and move to archive
          </Button>
        </>
    )
  } else if (status === 2) {
    // maintained
    return (
        <>
          <Button onClick={() => update(1)}>
            Deactivate tickets
          </Button>
          <Button onClick={() => update(3)}>
            Close project and move to archive
          </Button>
        </>
    )
  } else if (status === 3) {
    // archived
    return (
        <>
          <Button onClick={() => update(1)}>
            Re-open project without tickets
          </Button>
          <Button onClick={() => update(2)}>
            Re-open project with tickets
          </Button>
        </>
    )
  } else return null
}

const mapStateToProps = ({ projects, form }, ownProps) => ({
  project: projects.items.find(u => u.uuid === ownProps.project),
  submitting: form.submitting
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  update: status => dispatch(updateProjectStatus(ownProps.project, status))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateStatus)
