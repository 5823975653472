import {
  addGroupMember
} from '../../../actions/reducer/Groups.Actions'

const addMember = (group, member) => (dispatch, _Backend) => {
  return _Backend.call('addGroupMember', group, member)
    .then(() => {
      dispatch(addGroupMember(group, member))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => console.log('addGroupMember finally'))
}

export default addMember
