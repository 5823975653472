import { createUserObject } from '../../../objects/create'

import {
  addUser,
  updateUser,
  setUserlistSyncedAt,
  setUserlistSyncing
} from '../../../actions/reducer/Users.Actions'

const syncUserlist = (stateEntities) => (dispatch, _Backend) => {
  dispatch(setUserlistSyncing(true))
  return _Backend.call('users')
    .then(entities => {
      for (let entity of entities) {
        let stateIndex = stateEntities.findIndex(item => item.uuid === entity.uuid)
        entity.syncedAt = stateIndex > -1 ? Date.now() : null
        let item = createUserObject(entity)
        dispatch(stateIndex > -1
          ? updateUser(item)
          : addUser(item)
        )
      }
      dispatch(setUserlistSyncedAt(Date.now()))

      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setUserlistSyncing(false)))
}

export default syncUserlist
