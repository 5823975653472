export const syncGrouplist = () => ({
  type: 'GROUPS',
  payload: {
    operation: 'SYNC_LIST'
  }
})

export const syncGroup = uuid => ({
  type: 'GROUPS',
  payload: {
    operation: 'SYNC',
    uuid
  }
})

export const createGroup = form => ({
  type: 'GROUPS',
  payload: {
    operation: 'CREATE',
    form
  }
})

export const updateGroup = (group, form) => ({
  type: 'GROUPS',
  payload: {
    operation: 'UPDATE',
    group,
    form
  }
})

export const deleteGroup = group => ({
  type: 'GROUPS',
  payload: {
    operation: 'DELETE',
    group
  }
})

export const addMember = (group, member) => ({
  type: 'GROUPS',
  payload: {
    operation: 'MEMBER_ADD',
    group,
    member
  }
})

export const removeMember = (group, member) => ({
  type: 'GROUPS',
  payload: {
    operation: 'MEMBER_REMOVE',
    group,
    member
  }
})
