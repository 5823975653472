import React from 'react'

import { Switch, Route } from 'react-router-dom'

import Profile from './profile/Index'
import Settings from './settings/Index'

import NoMatch from '../noMatch/NoMatch'
import { Container } from 'reactstrap'

const Account = props => {
  return (
    <Container>
      <Switch>
        <Route path={`${props.match.path}/profile`} component={Profile} />
        <Route path={`${props.match.path}/settings`} component={Settings} />
        <Route component={NoMatch} />
      </Switch>
    </Container>
  )
}

export default Account
