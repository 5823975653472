export const syncUserlist = () => ({
  type: 'USERS',
  payload: {
    operation: 'SYNC_LIST'
  }
})

export const syncUser = uuid => ({
  type: 'USERS',
  payload: {
    operation: 'SYNC',
    uuid
  }
})

export const inviteUser = form => ({
  type: 'USERS',
  payload: {
    operation: 'INVITE',
    form
  }
})

export const updateRoleGroup = (user, role) => ({
  type: 'USERS',
  payload: {
    operation: 'UPDATE_ROLE_GROUP',
    user,
    role
  }
})

export const updateRoleLevel = (user, level) => ({
  type: 'USERS',
  payload: {
    operation: 'UPDATE_ROLE_LEVEL',
    user,
    level
  }
})
