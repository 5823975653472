import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  createAccount,
  resolveEmail
} from '../../../../actions/middleware/Auth.Actions'

import {
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Alert,
  FormText
} from 'reactstrap'

import {
  isPassword,
  isUsername
} from '../../../common/Utils'

import Button from '../../../common/Button'
import Translated from '../../../common/Translated'
import translate from '../../../../utils/translations/Translate'

class CreateAccount extends Component {
  constructor () {
    super()

    this.state = {
      username: '',
      password: '',
      retypePassword: '',
      termsAccepted: false,
      isValidUsername: false,
      isValidPassword: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeUsername = this.handleChangeUsername.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeRetype = this.handleChangeRetype.bind(this)
    this.handleChangeTerms = this.handleChangeTerms.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { username, password } = this.state
    this.props.createAccount({ username, password })
  }

  handleChangeUsername (e) {
    let username = e.target.value
    this.setState({
      username,
      isValidUsername: isUsername(username)
    })
  }

  handleChangePassword (e) {
    let password = e.target.value
    this.setState({
      password,
      isValidPassword: isPassword(password),
      isValidConfirmPassword: password === this.state.confirmPassword
    })
  }

  handleChangeRetype (e) {
    let retypePassword = e.target.value
    this.setState({
      retypePassword
    })
  }

  handleChangeTerms (e) {
    let termsAccepted = e.target.checked
    this.setState({
      termsAccepted
    })
  }

  componentDidMount () {
    this.props.resolveEmail(this.props.hash)
  }

  render () {
    const {
      password,
      retypePassword,
      isValidUsername,
      isValidPassword,
      termsAccepted
    } = this.state
    const { language } = this.props
    const canSubmit = isValidUsername && isValidPassword && password === retypePassword && termsAccepted
    return (
      <Form onSubmit={this.handleSubmit}
        className='border bg-white rounded my-4 p-3 shadow-sm'>
        <h3 className='text-center'>
          <Translated txtid='TXT_CREATE_ACCOUNT_FORM__HEADING' />
        </h3>
        {this.props.form.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.form.errors
              .map((el, i) =>
                <span key={i}><Translated txtid={el} />{' '}</span>
              )
            }
          </Alert>
        }
        {this.props.form.resolvedEmail && <>
          <FormText className='text-center my-3'>
            <Translated txtid='TXT_CREATE_ACCOUNT_FORM__TOOLTIP_EMAIL' /><br />
            <b>{this.props.form.resolvedEmail}</b>
          </FormText>
          <FormGroup>
            <Label for='username'>
              <Translated txtid='TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_USERNAME' />
            </Label>
            <Input
              type='text'
              name='username'
              id='username'
              tabIndex={1}
              placeholder={translate(language, 'TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_USERNAME')}
              invalid={!!this.state.username && !isValidUsername}
              onChange={this.handleChangeUsername}
              disabled={this.props.form.submitting}
              required />
            <FormText>
              <Translated txtid='TXT_CREATE_ACCOUNT_FORM__TOOLTIP_USERNAME' />
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='password'>
              <Translated txtid='TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_PASSWORD' />
            </Label>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password'
              id='password'
              tabIndex={2}
              placeholder={translate(language, 'TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_PASSWORD')}
              invalid={!!this.state.password && !isValidPassword}
              onChange={this.handleChangePassword}
              disabled={this.props.form.submitting}
              required />
            <FormText>
              <Translated txtid='TXT_CREATE_ACCOUNT_FORM__TOOLTIP_PASSWORD' />
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='password-v'>
              <Translated txtid='TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_RETYPE_PASSWORD' />
            </Label>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password-v'
              id='password-v'
              tabIndex={3}
              placeholder={translate(language, 'TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD')}
              invalid={password !== retypePassword}
              onChange={this.handleChangeRetype}
              disabled={this.props.form.submitting}
              required />
          </FormGroup>
          <FormGroup>
            <CustomInput
              onChange={this.handleChangeTerms}
              type='checkbox'
              id='terms-accepted'
              tabIndex={0}
              disabled={this.props.form.submitting}
              label={<Translated txtid='TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_TERMS' />} />
          </FormGroup>
          <div className='d-flex w-100 justify-content-center'>
            <Button
              className={this.props.form.submitting && 'submitting'}
              color='add'
              type='submit'
              tabIndex={4}
              disabled={!canSubmit || this.props.form.submitting}
            >
              <Translated txtid='TXT_CREATE_ACCOUNT_FORM__BUTTON_LABEL_SEND' />
            </Button>
          </div>
          </>}
      </Form>
    )
  }
}

const mapStateToProps = ({ app: { language } }, ownProps) => ({
  language
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  createAccount: form => dispatch(createAccount({
    FormId: ownProps.form.id,
    FormFields: form
  })),
  resolveEmail: secret => dispatch(resolveEmail({
    FormId: ownProps.form.id,
    FormFields: { secret }
  }))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount)
