import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updatePhase } from '../../../../actions/middleware/Projects.Actions'
import { resetForm } from '../../../../actions/reducer/Form.Actions'

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

class UpdatePhase extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      isValidName: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { name } = this.state
    this.props.updatePhase(name)
  }

  componentDidMount () {
    if (this.props.phase) {
      this.setState({ name: this.props.phase.name, isValidName: true })
    }
    // TODO: handle outdated data?
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.phase && this.props.phase) {
      this.setState({ name: this.props.phase.name, isValidName: true })
    }
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  render () {
    const { isValidName } = this.state
    const canSubmit = isValidName
    return (
      <Col md={10} lg={6}>
        <h5>Update phase</h5>
        <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
          {this.props.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.errors.map(el => el).join(' ')}
          </Alert>}
          <FormGroup>
            <Label for='phase-name'>Phase name</Label>
            <Input
              type='text'
              maxLength={128}
              name='phase-name'
              id='phase-name'
              tabIndex={1}
              value={this.state.name}
              placeholder='Phase name'
              disabled={this.props.submitting}
              invalid={!!this.state.name && !isValidName}
              onChange={this.handleChangeName}
              required />
            <FormText color='muted'>
                  128/{this.state.name.length}
            </FormText>
          </FormGroup>
          <Button color='primary'
            type='submit'
            tabIndex={3}
            disabled={!canSubmit || this.props.submitting}
          >
            Update phase
          </Button>
          TODO: cancel button, back to phases
        </Form>
      </Col>
    )
  }
}

const mapStateToProps = ({ form, phases }, ownProps) => {
  const { projectUuid, phaseUuid } = ownProps.match.params
  return {
    submitting: form.submitting,
    errors: form.errors,
    phase: phases
      .find(p => p.project === projectUuid && p.uuid === phaseUuid)
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { projectUuid, phaseUuid } = ownProps.match.params
  return {
    updatePhase: name => dispatch(updatePhase(
      projectUuid,
      phaseUuid,
      name)),
    resetForm: () => dispatch(resetForm())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePhase)
