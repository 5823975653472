import React, { Component } from 'react'
import { connect } from 'react-redux'

import NavTabs from './NavTabs'

import { syncProject } from '../../../actions/middleware/Projects.Actions'

import { Switch, Route, Link } from 'react-router-dom'
import ProtectedRoute from '../../common/ProtectedRoute'

import { CONST_PROJECT_STATUSES } from '../../../constants/Constants'

import {
  Container,
  Row,
  Col,
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Badge
} from 'reactstrap'

import Summary from './summary/Index'
import Settings from './settings/Index'
import Tasks from './tasks/Index'
import Tickets from './tickets/Index'
import Phases from './phases/Index'
import Files from './files/Index'

import NoMatch from '../noMatch/NoMatch'

class Project extends Component {
  constructor () {
    super()
    this._breadcrumbs = this._breadcrumbs.bind(this)
    this._statusBadge = this._statusBadge.bind(this)
  }

  _breadcrumbs () {
    const urlBase = '/project/' + this.props.project.uuid
    const currentPath = this.props.location.pathname

    const subMenu = () => {
      if (currentPath.includes(urlBase + '/tasks')) {
        return 'Tasks'
      }
      if (currentPath.includes(urlBase + '/tickets')) {
        return 'Tickets'
      }
      if (currentPath.includes(urlBase + '/phases')) {
        return 'Phases'
      }
      if (currentPath.includes(urlBase + '/files')) {
        return 'Files'
      }
      if (currentPath.includes(urlBase + '/notes')) {
        return 'Notes'
      }
      if (currentPath.includes(urlBase + '/settings')) {
        return 'Settings'
      }
    }

    return (
      <Breadcrumb className='bg-light rounded'>
        <BreadcrumbItem>
          <Link to={'/projects'}>Projects</Link>
        </BreadcrumbItem>
        {currentPath === urlBase
          ? <BreadcrumbItem active>{this.props.project.name}</BreadcrumbItem>
          : (
            <>
              <BreadcrumbItem>
                <Link to={urlBase}>{this.props.project.name}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{subMenu()}</BreadcrumbItem>
            </>
          )}
      </Breadcrumb>
    )
  }

  _statusBadge (status) {
    const { label } = CONST_PROJECT_STATUSES.find(({ id }) => id === status)
    return (
      <Badge className={`mr-2 _projectstatus-${status}`}>
        {label}
      </Badge>
    )
  }

  componentDidMount () {
    if (!this.props.project ||
      (this.props.project.syncedAt + (10 * 1000) < Date.now() && !this.props.project.syncing)) {
      this.props.syncProject()
    }
  }

  render () {
    let shouldRender = this.props.project && !this.props.project.syncing
    return shouldRender
      ? (
        this.props.project.error === 'NO_MATCH'
          ? <NoMatch />
          : <Container>
            <Row className='mt-3'>
              <Col>
                {this._breadcrumbs()}
              </Col>
            </Row>
            <Row className='mb-3'>
              <Col>
                <h4>{this.props.project.name}</h4>
                {this._statusBadge(this.props.project.status)}
                <small className='text-muted'>ID {this.props.project.uuid}</small>
              </Col>
            </Row>
            <Row>
              <Col>
                <NavTabs uuid={this.props.project.uuid} />
              </Col>
            </Row>
            <Switch>
              <Route exact path={`${this.props.match.path}/`} component={Summary} />
              <Route path={`${this.props.match.path}/tasks`} component={Tasks} />
              <Route path={`${this.props.match.path}/tickets`} component={Tickets} />
              <Route path={`${this.props.match.path}/phases`} component={Phases} />
              <Route path={`${this.props.match.path}/files`} component={Files} />
              {this.props.project.creator === this.props.currentUser
                ? <Route
                  path={`${this.props.match.path}/settings`}
                  component={Settings} />
                : <ProtectedRoute
                  protectionOptions={{
                    group: { value: 2, exact: false },
                    level: { value: 3, exact: false }
                  }}
                  path={`${this.props.match.path}/settings`}
                  component={Settings} />
              }
              <Route component={NoMatch} />
            </Switch>
          </Container>
      ) : <Spinner />
  }
}

const mapStateToProps = ({ projects, user: { uuid } }, ownProps) => {
  return {
    currentUser: uuid,
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid)
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncProject: () => dispatch(syncProject(ownProps.match.params.projectUuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Project)
