import React, { Component } from 'react'
import { connect } from 'react-redux'

import { inviteUser } from '../../../../../actions/middleware/Users.Actions'

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert
} from 'reactstrap'

import { isEmail } from '../../../../common/Utils'

class Invite extends Component {
  constructor () {
    super()

    this.state = {
      email: '',
      message: '',
      isValidEmail: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangeMessage = this.handleChangeMessage.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { email, message } = this.state
    const { form: { id } } = this.props
    this.props.inviteUser({ FormId: id, FormFields: { email, message } })
  }

  handleChangeEmail (e) {
    let email = e.target.value
    this.setState({
      email,
      isValidEmail: isEmail(email)
    })
  }

  handleChangeMessage (e) {
    let message = e.target.value
    this.setState({
      message
    })
  }

  componentDidMount () {
    // console.log('mounted wrapped form', this.props)
  }

  render () {
    const { isValidEmail } = this.state
    return (
      <Form
        onSubmit={this.handleSubmit}
        acceptCharset='utf-8'
        encType='multipart/form-data'
        className='mb-4'>
        {this.props.form.errors.length > 0 &&
          <Alert
            color='danger'
            className='text-center'>
            {this.props.form.errors.map(el => el).join(' ')}
          </Alert>}
        {this.props.form.message &&
          <Alert
            color='success'
            className='text-center'>
            {this.props.form.message}
          </Alert>}
        <FormGroup>
          <Label for='email'>Email address</Label>
          <Input
            type='email'
            name='email'
            id='email'
            tabIndex={1}
            placeholder='Email address'
            disabled={this.props.form.submitting}
            invalid={!!this.state.email && !isValidEmail}
            onChange={this.handleChangeEmail}
            required />
        </FormGroup>
        <FormGroup>
          <Label for='message'>Message</Label>
          <Input
            type='textarea'
            name='message'
            id='message'
            tabIndex={1}
            placeholder='message'
            disabled={this.props.form.submitting}
            onChange={this.handleChangeMessage} />
        </FormGroup>
        <Button color='primary'
          type='submit'
          tabIndex={3}
          disabled={!isValidEmail || this.props.form.submitting}
        >
            Send invitation
        </Button>
      </Form>
    )
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  inviteUser: (form) => dispatch(inviteUser(form))
})

export default connect(
  () => ({}),
  mapDispatchToProps
)(Invite)
