import {
  setFormSubmitting,
  setFormErrors,
  setFormMessage
} from '../../../actions/reducer/Forms.Actions'

const inviteUser = ({ FormId: id, FormFields: { email, message } }) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(id, true))
  return _Backend.call('inviteUser', email, message)
    .then(() => {
      dispatch(setFormMessage(id, 'Sikeresen meghívtad ' + email + '-t!'))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormErrors(id, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(id, false)))
}

export default inviteUser
