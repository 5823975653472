import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncParticipants } from '../../../../actions/middleware/Projects.Actions'

import { Row, Col } from 'reactstrap'

import UserShortcut from '../../../common/UserShortcut'
import GroupShortcut from '../../../common/GroupShortcut'

class Participants extends Component {
  componentDidMount () {
    if (!this.props.syncing && this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncParticipants()
    }
  }

  render () {
    return (
      <Row>
        <Col>
          <h6>Participants</h6>
          {this.props.accesses
            .map(({ entity, entityType }) =>
              <Row key={entity}
                className='mb-3'>
                <Col>
                  {entityType === 0
                    ? <UserShortcut
                      uuid={entity}
                      display={{ thumb: true, username: true, name: true, size: 'large' }}
                    />
                    : <GroupShortcut uuid={entity} />
                  }
                </Col>
              </Row>
            )}
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ accesses }, ownProps) => {
  return {
    accesses: accesses
      .filter(x => x.project === ownProps.uuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncParticipants: () => dispatch(syncParticipants(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Participants)
