import React, { Component } from 'react'
import { connect } from 'react-redux'

import { resetForm } from '../../../../actions/reducer/Form.Actions'

import {
  syncProject,
  updateProjectProperties
} from '../../../../actions/middleware/Projects.Actions'

import NoMatch from '../../noMatch/NoMatch'

import {
  Alert,
  Row,
  Col,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button
} from 'reactstrap'

// TODO: error, chaining syncs no project uuid while render link to phase

class UpdateProjectForm extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      description: '',
      isValidName: false,
      isValidDescription: false
    }
    this.canSubmitForm = this.canSubmitForm.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
  }

  canSubmitForm () {
    return this.state.isValidName &&
      this.state.isValidDescription &&
      (this.state.name !== this.props.project.name ||
      this.state.description !== this.props.project.description)
  }

  handleSubmit (e) {
    e.preventDefault()
    let {
      name,
      description
    } = this.state
    this.props.updateProjectProperties({
      name,
      description
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangeDescription (e) {
    let description = e.target.value
    this.setState({
      description,
      isValidDescription: !!description
    })
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  componentDidMount () {
    this.setState({
      name: this.props.project.name,
      description: this.props.project.description,
      isValidName: true,
      isValidDescription: true
    })
  }

  componentDidUpdate (prevProps, prevState) {

  }

  render () {
    const existInState = !!this.props.project
    const { isValidName, isValidDescription } = this.state
    return existInState
      ? (
        this.props.project.error === 'NO_MATCH'
          ? <NoMatch />
          : (!this.props.project.syncing &&
            <Row>
              <Col md={3}>
                General project data
              </Col>
              <Col md={9}>
                <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
                  {this.props.errors.length > 0 &&
                  <Alert color='danger' className='text-center'>
                    {this.props.errors.map(el => el).join(' ')}
                  </Alert>}
                  <FormGroup>
                    <Label for='project-name'>Project name</Label>
                    <Input
                      type='text'
                      maxLength={128}
                      defaultValue={this.props.project.name}
                      name='project-name'
                      id='project-name'
                      tabIndex={1}
                      placeholder='Task name'
                      disabled={this.props.submitting}
                      invalid={!!this.state.name && !isValidName}
                      onChange={this.handleChangeName}
                      required />
                    <FormText color='muted'>
                  128/{this.state.name.length}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for='project-description'>Description</Label>
                    <Input
                      type='textarea'
                      maxLength={10000}
                      name='project-description'
                      defaultValue={this.props.project.description}
                      id='project-description'
                      tabIndex={2}
                      placeholder='description'
                      invalid={!!this.state.description && !isValidDescription}
                      onChange={this.handleChangeDescription}
                      disabled={this.props.submitting}
                      required />
                    <FormText color='muted'>
                      10000/{this.state.description.length}
                    </FormText>
                  </FormGroup>
                  <Button color='primary'
                    type='submit'
                    tabIndex={3}
                    disabled={!this.canSubmitForm() || this.props.submitting}
                  >
                    Update project
                  </Button>
                </Form>
              </Col>
            </Row>
          )
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ projects, form }, ownProps) => {
  const project = projects.items.find(u => u.uuid === ownProps.uuid)
  return {
    project,
    submitting: form.submitting,
    errors: form.errors
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncProject: () => dispatch(syncProject(ownProps.uuid)),
  updateProjectProperties: form => dispatch(updateProjectProperties(ownProps.uuid, form)),
  resetForm: () => dispatch(resetForm())
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UpdateProjectForm)
