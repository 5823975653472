import Create from './Create'
import SyncProjectTasks from './SyncProjectTasks'

const tasks = API => {
  return {
    create: (...params) => dispatch => Create(...params)(dispatch, API),
    syncProjectTasks: (...params) => dispatch => SyncProjectTasks(...params)(dispatch, API)
  }
}

export default tasks
