import React, { Component } from 'react'
import { connect } from 'react-redux'

// import { Link } from 'react-router-dom'

import FormStateWrapper from '../../../common/FormStateWrapper'

import { Row, Col } from 'reactstrap'
import Upload from './components/Upload'
import List from './components/List'

class Files extends Component {
  render () {
    return (
      <Row>
        <Col>
          <FormStateWrapper
            project={this.props.match.params.projectUuid}
            component={Upload} />
          <List project={this.props.match.params.projectUuid} />
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ projects, users, groups, accesses }, ownProps) => {
  return {
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Files)
