export const createTicket = (project, form) => ({
  type: 'TICKETS',
  payload: {
    operation: 'CREATE',
    project,
    form
  }
})

export const syncTicket = uuid => ({
  type: 'TICKETS',
  payload: {
    operation: 'SYNC',
    uuid
  }
})

export const syncTicketActivities = uuid => ({
  type: 'TICKETS',
  payload: {
    operation: 'SYNC_ACTIVITIES',
    uuid
  }
})

export const syncTicketFiles = uuid => ({
  type: 'TICKETS',
  payload: {
    operation: 'SYNC_ACTIVITIES',
    uuid
  }
})

export const assignUserToTicket = (ticket, user) => ({
  type: 'TICKETS',
  payload: {
    operation: 'ASSIGN',
    ticket,
    user
  }
})

export const unassignUserFromTicket = (ticket, user) => ({
  type: 'TICKETS',
  payload: {
    operation: 'UNASSIGN',
    ticket,
    user
  }
})

export const updateTicketStatus = (ticket, status) => ({
  type: 'TICKETS',
  payload: {
    operation: 'UPDATE_STATUS',
    ticket,
    status
  }
})

export const updateTicketProperties = (ticket, form) => ({
  type: 'TICKETS',
  payload: {
    operation: 'UPDATE_PROPERTIES',
    ticket,
    form
  }
})

export const commentTicket = (ticket, form) => ({
  type: 'TICKETS',
  payload: {
    operation: 'COMMENT',
    ticket,
    form
  }
})
