import req from 'request'
import EventEmitter from 'events'
const request = req.defaults({
  json: true,
  strictSSL: false
})

class BackendError extends Error {
  constructor (name, obj = {}) {
    if (obj instanceof Error) {
      super(obj)
      this.stack = obj.stack
      this.properties = { originalName: obj.name }
    } else {
      super(obj.message || '')
      this.properties = obj
    }
    this.name = name
  }

  get stackList () {
    return this.stack.toString().split('\n').slice(1).map(line => {
      return line.replace(/^\s+at /, '')
    })
  }
}

const deepEqual = (x, y) => {
  if (x === y) {
    return true
  } else if ((typeof x === 'object' && x != null) && (typeof y === 'object' && y != null)) {
    if (Object.keys(x).length !== Object.keys(y).length) { return false }

    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) { return false }
      } else { return false }
    }

    return true
  } else {
    return false
  }
}

const arrayEqual = (x, y) => {
  if (x.length !== y.length) {
    return false
  } else {
    let equalValues = true
    for (let index = 0; index < x.length; index++) {
      if (!deepEqual(x[index], y[index])) {
        equalValues = false
        break
      }
    }
    return equalValues
  }
}

const balanceDelay = 20 // wait 20 ms before execute request

class Backend extends EventEmitter {
  constructor (url) {
    super()
    this._baseURL = url
    this._queue = []
    this.setMaxListeners(0)
    this._credentials = {
      session: null,
      access: null
    }
  }

  abort () {
    this.emit('abort')
  }

  call (func, ...params) {
    const paramArr = [ ...params ]
    const pendingReq = this._queue
      .find(req =>
        req.func === func &&
        arrayEqual(req.params, paramArr))
    if (pendingReq) return pendingReq.promise
    else return this[func](...params)
  }

  _createPromiseWrapper (method, url, authorize, opts, removeFromQueue) {
    return new Promise(async (resolve, reject) => {
      let started = false
      if (authorize) {
        // try-catch?
        opts.qs = (await this.auth)
      }
      let balanced = setTimeout(() => {
        started = true

        const _req = request[method](
          this._endpointURL(url),
          opts,
          (err, res, json) => {
            removeFromQueue()
            if (err) return reject(new BackendError('ServerError', err))
            if (json.status === 'success') {
              return resolve(json.data)
            } else if (json.status === 'unauthorized') {
              return reject(new BackendError('Unauthorized'))
            } else if (json.status === 'failure') {
              return reject(new BackendError('RequestFailed',
                json.data ? json.data.errors : {}))
            } else {
              return reject(new BackendError('UnexpectedError'))
            }
          })

        this.on('abort', () => _req.abort())
      }, balanceDelay)
      this.on('abort', () => {
        !started && clearTimeout(balanced)
        removeFromQueue()
        return reject(new BackendError('Aborted'))
      })
    })
  }

  _endpointURL (path) {
    return this._baseURL + path
  }

  set session ({ expiration, token, uuid }) {
    this._credentials.session = { expiration, token, uuid }
  }

  get session () {
    return this._credentials.session
  }

  set access ({ expiration, token, uuid }) {
    this._credentials.access = { expiration, token, uuid }
  }

  get access () {
    return this._credentials.access
  }

  get _authQs () {
    return {
      key: this.access.uuid,
      token: this.access.token
    }
  }

  get auth () {
    return new Promise((resolve, reject) => {
      // FYI javascript now() is in milliseconds, api response gives you the time in seconds
      if (!this.access || this.access.expiration <= Date.now()) {
        if (this._credentials.session) {
          const { uuid, token } = this._credentials.session
          request.post(
            this._endpointURL('/auth/request-access'),
            { form: { uuid, token } },
            (err, res, json) => {
              if (err) return reject(new BackendError('ServerError', err))
              if (json.status === 'success') {
                this.access = json.data
                return resolve(this._authQs)
              } else if (json.status === 'unauthorized') {
                this._credentials = { session: null, access: null }
                return reject(new BackendError('Unauthorized'))
              } else if (json.status === 'failure') {
                return reject(new BackendError('RequestFailed', json.data.errors || {}))
              } else {
                return reject(new BackendError('UnexpectedError'))
              }
            })
        } else {
          return reject(new BackendError('Unauthorized', 'Unauthorized'))
        }
      } else {
        return resolve(this._authQs)
      }
    })
  }

  requestAccess (uuid, token) {
    return new Promise((resolve, reject) => {
      request.post(
        this._endpointURL('/auth/request-access'),
        { form: { uuid, token } },
        (err, res, json) => {
          if (err) return reject(new BackendError('ServerError', err))
          if (json.status === 'success') {
            this.access = json.data
            return resolve(json.data)
          } else if (json.status === 'unauthorized') {
            return reject(new BackendError('Unauthorized'))
          } else if (json.status === 'failure') {
            return reject(new BackendError('RequestFailed', json.data.errors || {}))
          } else {
            return reject(new BackendError('UnexpectedError'))
          }
        })
    })
  }

  signOut () {
    const func = this.signOut.name
    const params = [ ]
    const promise = this._createPromiseWrapper(
      'get',
      '/auth/sign-out',
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
      .finally(() => {
        this._credentials = {
          session: null,
          access: null
        }
        return Promise.resolve()
      })
  }

  signIn (form) {
    const func = this.signIn.name
    const params = [ form ]
    const promise = this._createPromiseWrapper(
      'post',
      '/auth/sign-in',
      false,
      { form },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  signUp (email) {
    const func = this.signUp.name
    const params = [ email ]
    const promise = this._createPromiseWrapper(
      'post',
      '/auth/sign-up',
      false,
      { form: { email } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  resolveEmail (hash) {
    const func = this.resolveEmail.name
    const params = [ hash ]
    const promise = this._createPromiseWrapper(
      'get',
      '/auth/resolve-email',
      false,
      { qs: { hash } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  createAccount ({ hash, username, password }) {
    const func = this.createAccount.name
    const params = [ { hash, username, password } ]
    const promise = this._createPromiseWrapper(
      'post',
      '/auth/create-account',
      false,
      { form: { hash, username, password } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  forgottenPassword (email) {
    const func = this.forgottenPassword.name
    const params = [ email ]
    const promise = this._createPromiseWrapper(
      'post',
      '/auth/forgotten-password',
      false,
      { form: { email } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  resetPassword ({ request: req, email, password }) {
    const func = this.resetPassword.name
    const params = [ { request: req, email, password } ]
    const promise = this._createPromiseWrapper(
      'post',
      '/auth/reset-password',
      false,
      { form: { request: req, email, password } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updatePassword ({ currentPassword, newPassword }) {
    const func = this.updatePassword.name
    const params = [ { currentPassword, newPassword } ]
    const promise = this._createPromiseWrapper(
      'put',
      '/account/update-password',
      true,
      { body: { currentPassword, newPassword } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  currentUserData () {
    const func = this.currentUserData.name
    const params = [ ]
    const promise = this._createPromiseWrapper(
      'get',
      '/account/me',
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  createProject ({ name, phase, description }) {
    const func = this.createProject.name
    const params = [ { name, phase, description } ]
    const promise = this._createPromiseWrapper(
      'post',
      '/projects/create',
      true,
      { form: { name, phase, description } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getProject (projectUuid) {
    const func = this.getProject.name
    const params = [ projectUuid ]
    const promise = this._createPromiseWrapper(
      'get',
      '/project/' + projectUuid,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateProjectStatus (project, status) {
    const func = this.updateProjectStatus.name
    const params = [ project, status ]
    const promise = this._createPromiseWrapper(
      'put',
      `/project/${project}/update-status`,
      true,
      { body: { status } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateProjectProperties (project, propObj = {}) {
    const func = this.updateProjectProperties.name
    const params = [ project, propObj ]
    const promise = this._createPromiseWrapper(
      'put',
      `/project/${project}/update-properties`,
      true,
      { body: propObj },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  listParticipants (projectUuid) {
    const func = this.listParticipants.name
    const params = [ projectUuid ]
    const promise = this._createPromiseWrapper(
      'get',
      `/project/${projectUuid}/participants`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  grantAccess (projectUuid, entityUuid) {
    const func = this.grantAccess.name
    const params = [ projectUuid, entityUuid ]
    const promise = this._createPromiseWrapper(
      'post',
      `/project/${projectUuid}/grant-access/${entityUuid}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  revokeAccess (projectUuid, entityUuid) {
    const func = this.revokeAccess.name
    const params = [ projectUuid, entityUuid ]
    const promise = this._createPromiseWrapper(
      'delete',
      `/project/${projectUuid}/revoke-access/${entityUuid}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  listPhases (projectUuid) {
    const func = this.listPhases.name
    const params = [ projectUuid ]
    const promise = this._createPromiseWrapper(
      'get',
      `/project/${projectUuid}/phases`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  addPhase (projectUuid, name) {
    const func = this.addPhase.name
    const params = [ projectUuid, name ]
    const promise = this._createPromiseWrapper(
      'post',
      `/project/${projectUuid}/add-phase`,
      true,
      { form: { name } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updatePhase (projectUuid, phaseUuid, name) {
    const func = this.updatePhase.name
    const params = [ projectUuid, phaseUuid, name ]
    const promise = this._createPromiseWrapper(
      'put',
      `/project/${projectUuid}/update-phase/${phaseUuid}`,
      true,
      { body: { name } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getProjectList (from = Math.floor(Date.now() / 1000)) {
    const func = this.getProjectList.name
    const params = [ from ]
    const promise = this._createPromiseWrapper(
      'get',
      `/projects/list/${from}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  users () {
    const func = this.users.name
    const params = [ ]
    const promise = this._createPromiseWrapper(
      'get',
      `/users`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  inviteUser (email, message = '') {
    const func = this.inviteUser.name
    const params = [ email, message ]
    const promise = this._createPromiseWrapper(
      'post',
      `/users/invite`,
      true,
      { form: { email } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getUser (uuid) {
    const func = this.getUser.name
    const params = [ uuid ]
    const promise = this._createPromiseWrapper(
      'get',
      '/user/' + uuid,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  groups () {
    const func = this.groups.name
    const params = [ ]
    const promise = this._createPromiseWrapper(
      'get',
      `/groups`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getGroup (uuid) {
    const func = this.getGroup.name
    const params = [ uuid ]
    const promise = this._createPromiseWrapper(
      'get',
      `/group/${uuid}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  async createGroup ({ name, thumb }) {
    const func = this.createGroup.name
    const params = [ { name, thumb } ]

    if (thumb instanceof window.File) {
      const fr = new window.FileReader()
      fr.onload = async (event) => {
        const opts = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          multipart: {
            chunked: false,
            data: [
              {
                'Content-Disposition': `form-data; name="name"`,
                body: name
              },
              {
                'Content-Disposition': `form-data; name="thumb"; filename="${thumb.name}"`,
                body: event.target.result
              }
            ]
          }
        }

        const promise = this._createPromiseWrapper(
          'post',
          `/groups/create`,
          true,
          opts,
          () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
        )
        this._queue.push({
          func,
          params,
          promise
        })
        return promise
      }
      await fr.readAsArrayBuffer(thumb)
    } else {
      const promise = this._createPromiseWrapper(
        'post',
        `/groups/create`,
        true,
        { form: { name } },
        () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
      )
      this._queue.push({
        func,
        params,
        promise
      })
      return promise
    }
  }

  async updateGroup (group, { name, thumb }) {
    const func = this.updateGroup.name
    const params = [ group, { name, thumb } ]

    if (thumb instanceof window.File) {
      const fr = new window.FileReader()
      fr.onload = async (event) => {
        const opts = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          multipart: {
            chunked: false,
            data: [
              {
                'Content-Disposition': `form-data; name="name"`,
                body: name
              },
              {
                'Content-Disposition': `form-data; name="thumb"; filename="${thumb.name}"`,
                body: event.target.result
              }
            ]
          }
        }

        const promise = this._createPromiseWrapper(
          'post',
          `/group/${group}/update`,
          true,
          opts,
          () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
        )
        this._queue.push({
          func,
          params,
          promise
        })
        return promise
      }
      await fr.readAsArrayBuffer(thumb)
    } else {
      const promise = this._createPromiseWrapper(
        'post',
        `/group/${group}/update`,
        true,
        { form: { name } },
        () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
      )
      this._queue.push({
        func,
        params,
        promise
      })
      return promise
    }
  }

  deleteGroup (group) {
    const func = this.deleteGroup.name
    const params = [ group ]
    const promise = this._createPromiseWrapper(
      'delete',
      `/group/${group}/delete`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  addGroupMember (group, member) {
    const func = this.addGroupMember.name
    const params = [ group, member ]
    const promise = this._createPromiseWrapper(
      'put',
      `/group/${group}/add-member/${member}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  removeGroupMember (group, member) {
    const func = this.removeGroupMember.name
    const params = [ group, member ]
    const promise = this._createPromiseWrapper(
      'delete',
      `/group/${group}/remove-member/${member}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  createTask (project, form) {
    const func = this.createTask.name
    const params = [ project, form ]
    const promise = this._createPromiseWrapper(
      'post',
      `/tasks/create/${project}`,
      true,
      { form },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getProjectTasks (project) {
    const func = this.getProjectTasks.name
    const params = [ project ]
    const promise = this._createPromiseWrapper(
      'get',
      `/tasks/list/${project}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getTask (uuid) {
    const func = this.getTask.name
    const params = [ uuid ]
    const promise = this._createPromiseWrapper(
      'get',
      `/task/${uuid}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  assignUser (task, user) {
    const func = this.assignUser.name
    const params = [ task, user ]
    const promise = this._createPromiseWrapper(
      'post',
      `/task/${task}/assign/${user}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  unassignUser (task, user) {
    const func = this.unassignUser.name
    const params = [ task, user ]
    const promise = this._createPromiseWrapper(
      'delete',
      `/task/${task}/unassign/${user}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateTaskStatus (task, status) {
    const func = this.updateTaskStatus.name
    const params = [ task, status ]
    const promise = this._createPromiseWrapper(
      'put',
      `/task/${task}/update-status`,
      true,
      { body: { status } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getTaskActivities (task) {
    const func = this.getTaskActivities.name
    const params = [ task ]
    const promise = this._createPromiseWrapper(
      'get',
      `/task/${task}/activities`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateTaskProperties (task, propObj = {}) {
    const func = this.updateTaskProperties.name
    const params = [ task, propObj ]
    const promise = this._createPromiseWrapper(
      'put',
      `/task/${task}/update-properties`,
      true,
      { body: propObj },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  async updateProfile ({ name, phone, thumb }) {
    const func = this.updateProfile.name
    const params = [ { name, phone, thumb } ]

    if (thumb instanceof window.File) {
      const fr = new window.FileReader()
      fr.onload = async (event) => {
        const opts = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          multipart: {
            chunked: false,
            data: [
              {
                'Content-Disposition': `form-data; name="name"`,
                body: name
              },
              {
                'Content-Disposition': `form-data; name="phone"`,
                body: phone
              },
              {
                'Content-Disposition': `form-data; name="thumb"; filename="${thumb.name}"`,
                body: event.target.result
              }
            ]
          }
        }

        const promise = this._createPromiseWrapper(
          'post',
          `/account/update-profile`,
          true,
          opts,
          () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
        )
        this._queue.push({
          func,
          params,
          promise
        })
        return promise
      }
      await fr.readAsArrayBuffer(thumb)
    } else {
      const promise = this._createPromiseWrapper(
        'post',
        `/account/update-profile`,
        true,
        { form: { name, phone } },
        () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
      )
      this._queue.push({
        func,
        params,
        promise
      })
      return promise
    }
  }

  commentTask (task, body) {
    const func = this.commentTask.name
    const params = [ task, body ]
    const promise = this._createPromiseWrapper(
      'post',
      `/task/${task}/comment`,
      true,
      { form: { body } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getAllActivities () {
    const func = this.getAllActivities.name
    const params = [ ]
    const promise = this._createPromiseWrapper(
      'get',
      `/projects/activities`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  createTicket (project, form) {
    const func = this.createTicket.name
    const params = [ project, form ]
    const promise = this._createPromiseWrapper(
      'post',
      `/tickets/create/${project}`,
      true,
      { form },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getTicket (uuid) {
    const func = this.getTicket.name
    const params = [ uuid ]
    const promise = this._createPromiseWrapper(
      'get',
      `/ticket/${uuid}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getTicketActivities (ticket) {
    const func = this.getTicketActivities.name
    const params = [ ticket ]
    const promise = this._createPromiseWrapper(
      'get',
      `/ticket/${ticket}/activities`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getProjectTickets (project) {
    const func = this.getProjectTickets.name
    const params = [ project ]
    const promise = this._createPromiseWrapper(
      'get',
      `/tickets/list/${project}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  assignUserToTicket (ticket, user) {
    const func = this.assignUserToTicket.name
    const params = [ ticket, user ]
    const promise = this._createPromiseWrapper(
      'post',
      `/ticket/${ticket}/assign/${user}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  unassignUserFromTicket (ticket, user) {
    const func = this.unassignUserFromTicket.name
    const params = [ ticket, user ]
    const promise = this._createPromiseWrapper(
      'delete',
      `/ticket/${ticket}/unassign/${user}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  commentTicket (ticket, body) {
    const func = this.commentTicket.name
    const params = [ ticket, body ]
    const promise = this._createPromiseWrapper(
      'post',
      `/ticket/${ticket}/comment`,
      true,
      { form: { body } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateTicketProperties (ticket, propObj = {}) {
    const func = this.updateTicketProperties.name
    const params = [ ticket, propObj ]
    const promise = this._createPromiseWrapper(
      'put',
      `/ticket/${ticket}/update-properties`,
      true,
      { body: propObj },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateTicketStatus (ticket, status) {
    const func = this.updateTicketStatus.name
    const params = [ ticket, status ]
    const promise = this._createPromiseWrapper(
      'put',
      `/ticket/${ticket}/update-status`,
      true,
      { body: { status } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  async uploadFile (project, { file, task, ticket }) {
    const func = this.uploadFile.name
    const params = [ project, { file, task, ticket } ]
    if (file instanceof window.File) {
      const fr = new window.FileReader()
      fr.onload = async (event) => {
        const data = [
          {
            'Content-Disposition': `form-data; name="file"; filename="${file.name}"`,
            body: event.target.result
          }
        ]
        if (task) {
          data.push({
            'Content-Disposition': `form-data; name="task_uuid"`,
            body: task
          })
        }
        if (ticket) {
          data.push({
            'Content-Disposition': `form-data; name="ticket_uuid"`,
            body: ticket
          })
        }

        const opts = {
          headers: {
            'content-type': 'multipart/form-data'
          },
          multipart: {
            chunked: false,
            data
          }
        }

        const promise = this._createPromiseWrapper(
          'post',
          `/files/upload/${project}`,
          true,
          opts,
          () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
        )
        this._queue.push({
          func,
          params,
          promise
        })
        return promise
      }
      await fr.readAsArrayBuffer(file)
    } else {
      return Promise.reject(new BackendError('UnexpectedError'))
    }
  }

  getFileList (project) {
    const func = this.getFileList.name
    const params = [ project ]
    const promise = this._createPromiseWrapper(
      'get',
      `/files/list/${project}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getFileDescriptor (file) {
    const func = this.getFileDescriptor.name
    const params = [ file ]
    const promise = this._createPromiseWrapper(
      'get',
      `/files/descriptor/${file}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  deleteFile (file) {
    const func = this.deleteFile.name
    const params = [ file ]
    const promise = this._createPromiseWrapper(
      'delete',
      `/files/remove/${file}`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getTaskFiles (task) {
    const func = this.getTaskFiles.name
    const params = [ task ]
    const promise = this._createPromiseWrapper(
      'get',
      `/task/${task}/files`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  getTicketFiles (ticket) {
    const func = this.getTicketFiles.name
    const params = [ ticket ]
    const promise = this._createPromiseWrapper(
      'get',
      `/ticket/${ticket}/files`,
      true,
      {},
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateUserRole (uuid, role) {
    const func = this.updateUserRole.name
    const params = [ uuid, role ]
    const promise = this._createPromiseWrapper(
      'put',
      `/user/${uuid}/update-role`,
      true,
      { body: { role } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }

  updateUserLevel (uuid, level) {
    const func = this.updateUserLevel.name
    const params = [ uuid, level ]
    const promise = this._createPromiseWrapper(
      'put',
      `/user/${uuid}/update-level`,
      true,
      { body: { level } },
      () => { this._queue = this._queue.filter(req => req.func !== func && !arrayEqual(req.params, params)) }
    )
    this._queue.push({
      func,
      params,
      promise
    })
    return promise
  }
}

export default Backend
