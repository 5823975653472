
import Sync from './Sync'
import Assign from './Assign'
import Unassign from './Unassign'
import UpdateStatus from './UpdateStatus'
import UpdateProperties from './UpdateProperties'
import SyncActivities from './SyncActivities'
import SyncFiles from './SyncFiles'
import Comment from './Comment'

const task = API => {
  return {
    sync: (...params) => dispatch => Sync(...params)(dispatch, API),
    assign: (...params) => dispatch => Assign(...params)(dispatch, API),
    unassign: (...params) => dispatch => Unassign(...params)(dispatch, API),
    updateStatus: (...params) => dispatch => UpdateStatus(...params)(dispatch, API),
    updateProperties: (...params) => dispatch => UpdateProperties(...params)(dispatch, API),
    comment: (...params) => dispatch => Comment(...params)(dispatch, API),
    syncActivities: (...params) => dispatch => SyncActivities(...params)(dispatch, API),
    syncFiles: (...params) => dispatch => SyncFiles(...params)(dispatch, API)
  }
}

export default task
