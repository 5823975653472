import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { syncTask, syncTaskActivities } from '../../../actions/middleware/Tasks.Actions'
import { syncProject, syncPhases, syncParticipants } from '../../../actions/middleware/Projects.Actions'

import {
  CONST_TASK_STATUSES,
  CONST_TASK_PRIORITIES
} from '../../../constants/Task.Constants'

import UserShortcut from '../../common/UserShortcut'
import ProtectedComponent from '../../common/ProtectedComponent'
import Button from '../../common/Button'

import NoMatch from '../noMatch/NoMatch'

import {
  Row,
  Col,
  Container,
  Badge,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'

import ReactMarkdown from 'react-markdown'
import linkify from 'markdown-linkify'

import Assignees from './components/Assignees'
import Activities from './components/Activities'
import FileList from './components/FileList'
import UpdateStatus from './components/UpdateStatus'
import Tabs from './components/Tabs'

// TODO: error, chaining syncs no project uuid while render link to phase

class Task extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)
    this._priorityBadge = this._priorityBadge.bind(this)
  }

  componentDidMount () {
    if (!this.props.task ||
      (this.props.task.syncedAt + (10 * 1000) < Date.now() && !this.props.task.syncing)) {
      this.props.syncTask()
    } else if (!this.props.project || !this.props.phase) {
      // TODO: better solution for sync chain
      const { project } = this.props.task
      this.props.syncProject(project)
      this.props.syncPhases(project)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // TODO: better solution for sync chain
    if (prevProps.task && prevProps.task.syncing && !this.props.task.syncing) {
      const { project } = this.props.task
      this.props.syncProject(project)
      this.props.syncPhases(project)
      this.props.syncParticipants(project)
    }
  }

  _statusBadge (status) {
    const { label } = CONST_TASK_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _taskstatus-${status}`}>
        {label}
      </Badge>
    )
  }

  _priorityBadge (priority) {
    const { label } = CONST_TASK_PRIORITIES.find(pr => pr.id === priority)
    return (
      <Badge className={`mr-2 _taskpriority-${priority}`}>
        {label}
      </Badge>
    )
  }

  render () {
    let shouldRender = !!this.props.task && !!this.props.project && !!this.props.phase
    return shouldRender
      ? (
        this.props.task.error === 'NO_MATCH'
          ? <NoMatch />
          : <Container>
            <Row className='mt-3'>
              <Col>
                <Breadcrumb className='bg-light rounded'>
                  <BreadcrumbItem><Link to={'/projects'}>Projects</Link></BreadcrumbItem>
                  {(this.props.project) && <>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid}>{this.props.project.name}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid + '/tasks'}>Tasks</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.props.task.name}</BreadcrumbItem>
                </>}
                </Breadcrumb>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col>
                <h4>{this.props.task.name}</h4>
                <Row className='align-items-center'>
                  <Col md={6} sm={12}>
                    {this._statusBadge(this.props.task.status)}
                    <small className='text-muted'>ID {this.props.task.uuid}</small>
                  </Col>
                  <Col md={6} sm={12} className='d-flex justify-content-end'>
                    <Button
                      className='ml-3'
                      size='normal'
                      color='modify'
                      tag={Link}
                      to={'/task/' + this.props.task.uuid + '/update'}
                    >
                      Update task
                    </Button>
                    <Button
                      size='normal'
                      className='ml-3'
                      color='add'
                      tag={Link}
                      to={'/project/' + this.props.project.uuid + '/tasks/add'}
                    >
                      Create new task
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Row>
                  <Col sm={4} className='mb-3'>
                    <small className='text-muted'>Priority</small>
                  </Col>
                  <Col className='mb-3'>
                    <div>
                      {this._priorityBadge(this.props.task.priority)}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} className='mb-3'>
                    <small className='text-muted'>Estimated work</small>
                  </Col>
                  <Col className='mb-3'>
                    <div>
                      {this.props.task.estimatedWork} hours
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4} className='mb-3'>
                    <small className='text-muted'>Phase</small>
                  </Col>
                  <Col className='mb-3'>
                    <div>
                      {(this.props.project && this.props.phase) && <Link to={'/project/' + this.props.project.uuid + '/phases/' + this.props.phase.uuid}>{this.props.phase.name}</Link>}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <Assignees
                      task={this.props.task.uuid}
                      assigned={this.props.task.assignees}
                      accesses={this.props.accesses} />
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <small className='text-muted'>Created by</small>
                    <div>
                      {this.props.task.creator && <UserShortcut uuid={this.props.task.creator}
                        display={{ thumb: true, username: true, size: 'small' }} />}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <small className='text-muted'>Files</small>
                    <FileList
                      uuid={this.props.task.uuid}
                      {...this.props.task.files}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={8}>
                <Row>
                  <Col className='bg-light border py-3 mb-3'>
                    <ReactMarkdown
                      source={linkify(this.props.task.description)} />
                  </Col>
                </Row>
                <Row>
                  <Col className='border-left mb-3'>
                    <Activities
                      uuid={this.props.task.uuid}
                      {...this.props.task.activities}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className='bg-light border py-3 mb-3'>
                    {this.props.project && <Tabs
                      uuid={this.props.task.uuid}
                      project={this.props.project.uuid} />}
                  </Col>
                </Row>
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 2, exact: false },
                    level: { value: 0, exact: false }
                  }}>
                  <Row>
                    <Col className='mb-3'>
                      <UpdateStatus
                        task={this.props.task.uuid}
                        status={this.props.task.status} />
                    </Col>
                  </Row>
                </ProtectedComponent>
              </Col>
            </Row>
          </Container>
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ tasks, projects, phases, users, groups, accesses }, ownProps) => {
  const task = tasks.find(u => u.uuid === ownProps.match.params.taskUuid)
  return {
    task,
    project: task
      ? projects.items.find(u => u.uuid === task.project)
      : undefined,
    phase: task
      ? phases.find(u => u.uuid === task.phase)
      : undefined,
    accesses: task
      ? accesses.filter(x => x.project === task.project)
      : [],
    users: task
      ? users.items
        .filter(u => accesses
          .filter(x => x.project === task.project)
          .findIndex(a => a.entity === u.uuid) > -1 &&
          u.role.group > 1)
      : [],
    groups: task
      ? groups.items
        .filter(g => accesses
          .filter(x => x.project === task.project)
          .findIndex(a => a.entity === g.uuid) > -1)
      : []
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTask: () => dispatch(syncTask(ownProps.match.params.taskUuid)),
  syncTaskActivities: () => dispatch(syncTaskActivities(ownProps.match.params.taskUuid)),
  syncProject: uuid => dispatch(syncProject(uuid)),
  syncPhases: uuid => dispatch(syncPhases(uuid)),
  syncParticipants: uuid => dispatch(syncParticipants(uuid))
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Task)
