import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { createProject } from '../../../actions/middleware/Projects.Actions'
import { resetForm } from '../../../actions/reducer/Form.Actions'

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

import Button from '../../common/Button'
import Breadcrumbs from '../../common/Breadcrumbs'

class CreateProject extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      phase: '',
      description: '',
      isValidName: false,
      isValidPhase: false,
      isValidDescription: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangePhase = this.handleChangePhase.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { name, description, phase } = this.state
    this.props.createProject({ name, description, phase })
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  handleChangeName (e) {
    let name = e.target.value.trim()
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangePhase (e) {
    let phase = e.target.value.trim()
    this.setState({
      phase,
      isValidPhase: !!phase
    })
  }

  handleChangeDescription (e) {
    let description = e.target.value
    this.setState({
      description,
      isValidDescription: !!description
    })
  }

  render () {
    const { isValidName, isValidPhase, isValidDescription } = this.state
    const canSubmit = isValidName && isValidDescription && isValidPhase
    return (
      <Container>
        <Row>
          <Col>
            <Breadcrumbs items={[
              { label: 'Projects', link: '/projects' },
              { label: 'Create new project', link: '/projects/create' }
            ]} />
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col md={10} lg={6}>
            <Form
              onSubmit={this.handleSubmit}
              className='border rounded p-3 my-4'>
              <h3>Create new project</h3>
              {this.props.errors.length > 0 &&
              <Alert color='danger' className='text-center'>
                {this.props.errors.map(el => el).join(' ')}
              </Alert>}
              <FormGroup>
                <Label for='project-name'>Project name <small className='text-muted'>(required)</small></Label>
                <Input
                  type='text'
                  maxLength={128}
                  name='project-name'
                  id='project-name'
                  tabIndex={1}
                  placeholder='Project name'
                  disabled={this.props.submitting}
                  invalid={!!this.state.name && !isValidName}
                  onChange={this.handleChangeName}
                  required />
                <FormText color='muted'>
                  128/{this.state.name.length}
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for='project-description'>Description <small className='text-muted'>(required)</small></Label>
                <Input
                  type='textarea'
                  maxLength={10000}
                  name='project-description'
                  id='project-description'
                  tabIndex={2}
                  placeholder='description'
                  invalid={!!this.state.description && !isValidDescription}
                  onChange={this.handleChangeDescription}
                  disabled={this.props.submitting}
                  required />
                <FormText color='muted'>
                  10000/{this.state.description.length}
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for='project-phase'>First phase name <small className='text-muted'>(required)</small></Label>
                <Input
                  type='text'
                  maxLength={128}
                  name='project-phase'
                  id='project-phase'
                  tabIndex={1}
                  placeholder='Phase name'
                  disabled={this.props.submitting}
                  invalid={!!this.state.phase && !isValidPhase}
                  onChange={this.handleChangePhase}
                  required />
                <FormText color='muted'>
                  128/{this.state.phase.length}
                </FormText>
              </FormGroup>
              <Button
                color='add'
                type='submit'
                tabIndex={3}
                disabled={!canSubmit || this.props.submitting}
              >
                Create
              </Button>
            </Form>
            <Button
              tag={Link}
              to='/projects'
            >
              Back to projects
            </Button>
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = ({ form, user }, ownProps) => ({
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  createProject: form => dispatch(createProject(form)),
  resetForm: () => dispatch(resetForm())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProject)
