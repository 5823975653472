
import React from 'react'

const Button = props => {
  let {
    className,
    size,
    tag,
    color,
    onClick,
    ...attributes
  } = props

  let Tag = tag || 'button'
  return (
    <Tag
      onClick={props.onClick || (() => {})}
      className={'_button ' + (size || 'normal') + ' ' + (color || 'default') + ' ' + (className || '')}
      {...attributes}
    >
      {props.children}
      <span />
    </Tag>
  )
}

export default Button
