import {
  removeGroup
} from '../../../actions/reducer/Groups.Actions'

const deleteGroup = (group) => (dispatch, _Backend) => {
  return _Backend.call('deleteGroup', group)
    .then(() => {
      dispatch(removeGroup(group))
      // redirect to groups
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => console.log('deleteGroup finally'))
}

export default deleteGroup
