import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncTask } from '../../../../../actions/middleware/Tasks.Actions'

import {
  CONST_TASK_STATUSES,
  CONST_TASK_PRIORITIES
} from '../../../../../constants/Task.Constants'

import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Badge
} from 'reactstrap'

import UserShortcut from '../../../../common/UserShortcut'

class Task extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)
    this._priorityBadge = this._priorityBadge.bind(this)
  }

  _statusBadge (status) {
    const { label } = CONST_TASK_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _taskstatus-${status}`}>
        {label}
      </Badge>
    )
  }

  _priorityBadge (priority) {
    const { label } = CONST_TASK_PRIORITIES.find(pr => pr.id === priority)
    return (
      <Badge className={`mr-2 _taskpriority-${priority}`}>
        {label}
      </Badge>
    )
  }

  componentDidMount () {
    this.props.syncTask()
    // sync history if needed
    if (!this.props.data ||
        (this.props.data.syncedAt + (10 * 1000) < Date.now() && !this.props.data.syncing)) {
      // this.props.syncTask()
    }
  }

  render () {
    return (
      <Row>
        <Col className='task'>
          <div className='pt-3 mb-3 border-top'>
            <Link
              to={'/task/' + this.props.data.uuid}
            >
              {this.props.data.name}
            </Link>
            <div className='d-flex w-100 align-items-center'>
              {this._statusBadge(this.props.data.status)}
              {this._priorityBadge(this.props.data.priority)}
              <small className='mr-2 text-muted'>ID {this.props.data.uuid}</small>
              <small>
                {this.props.data.assignees.length > 0
                  ? <>
                  Assignees:
                    {this.props.data.assignees.map(as => <UserShortcut
                      display={{ thumb: true, username: false, size: 'small' }}
                      uuid={as}
                    />)}
                  </>
                  : <i>Not assigned</i>}
              </small>
            </div>
            <Row>
              <Col>
                <small>
                  created at {new Date(this.props.data.createdAt).toLocaleString()} by <UserShortcut
                    display={{ thumb: true, username: true, size: 'small' }} uuid={this.props.data.creator} />, recently updated at {new Date(this.props.data.updatedAt).toLocaleString()}
                </small>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTask: () => dispatch(syncTask(ownProps.data.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Task)
