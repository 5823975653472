import React, { Component } from 'react'
import { connect } from 'react-redux'

import { resetPassword } from '../../../../actions/middleware/Auth.Actions'

import {
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  FormText
} from 'reactstrap'

import { isEmail, isPassword } from '../../../common/Utils'
import translate from '../../../../utils/translations/Translate'
import Button from '../../../common/Button'

import Translated from '../../../common/Translated'

class ResetPassword extends Component {
  constructor () {
    super()

    this.state = {
      email: '',
      newPassword: '',
      retypePassword: '',
      isValidPassword: false,
      isValidEmail: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeRetype = this.handleChangeRetype.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { email, newPassword } = this.state
    let { request } = this.props
    this.props.resetPassword({
      FormId: this.props.form.id,
      FormFields: { request, email, password: newPassword }
    })
  }

  handleChangeEmail (e) {
    let email = e.target.value
    this.setState({
      email,
      isValidEmail: isEmail(email)
    })
  }

  handleChangePassword (e) {
    let newPassword = e.target.value
    this.setState({
      newPassword,
      isValidPassword: isPassword(newPassword)
    })
  }

  handleChangeRetype (e) {
    let retypePassword = e.target.value
    this.setState({
      retypePassword
    })
  }

  render () {
    const { newPassword, retypePassword, isValidEmail, isValidPassword } = this.state
    const { language } = this.props
    const canSubmit = isValidEmail && isValidPassword && (newPassword === retypePassword)
    return (
      <Form
        onSubmit={this.handleSubmit}
        className='border bg-white rounded my-4 p-3 shadow-sm'>
        <h3 className='text-center'><Translated txtid='TXT_RESET_PASSWORD_FORM__HEADING' /></h3>
        {this.props.form.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.form.errors
              .map((el, i) =>
                <span key={i}><Translated txtid={el} />{' '}</span>
              )
            }
          </Alert>}
        {this.props.form.message &&
          <Alert color='success' className='text-center'>
            <Translated txtid={this.props.form.message} />
          </Alert>}
        {!this.props.form.message &&
        <>
          <FormGroup>
            <Label for='email'>
              <Translated txtid='TXT_RESET_PASSWORD_FORM__INPUT_LABEL_EMAIL' />
            </Label>
            <Input
              type='email'
              name='email'
              id='email'
              tabIndex={1}
              placeholder={translate(language, 'TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_EMAIL')}
              disabled={this.props.form.submitting}
              invalid={!!this.state.email && !isValidEmail}
              onChange={this.handleChangeEmail}
              required />
          </FormGroup>
          <FormGroup>
            <Label for='password'><Translated txtid='TXT_RESET_PASSWORD_FORM__INPUT_LABEL_NEW_PASSWORD' /></Label>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password'
              id='password'
              tabIndex={2}
              placeholder={translate(language, 'TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_NEW_PASSWORD')}
              invalid={!!this.state.newPassword && !isValidPassword}
              onChange={this.handleChangePassword}
              disabled={this.props.form.submitting}
              required />
            <FormText>
              <Translated txtid='TXT_RESET_PASSWORD_FORM__TOOLTIP_PASSWORD' />
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='password-v'><Translated txtid='TXT_RESET_PASSWORD_FORM__INPUT_LABEL_RETYPE_PASSWORD' /></Label>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password-v'
              id='password-v'
              tabIndex={3}
              placeholder={translate(language, 'TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD')}
              invalid={newPassword !== retypePassword}
              onChange={this.handleChangeRetype}
              disabled={this.props.form.submitting}
              required />
          </FormGroup>
          <div className='d-flex w-100 justify-content-center'>
            <Button
              className={this.props.form.submitting && 'submitting'}
              color='add'
              type='submit'
              tabIndex={4}
              disabled={!canSubmit || this.props.form.submitting}
            >
              <Translated txtid='TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_SEND' />
            </Button>
          </div>
        </>}
      </Form>
    )
  }
}

const mapStateToProps = ({ app: { language } }) => ({
  language
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  resetPassword: form => dispatch(resetPassword(form))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
