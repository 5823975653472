export const signIn = form => ({
  type: 'AUTH',
  payload: {
    operation: 'SIGN_IN',
    form
  }
})

export const signOut = () => ({
  type: 'AUTH',
  payload: {
    operation: 'SIGN_OUT'
  }
})

export const validateSession = () => ({
  type: 'AUTH',
  payload: {
    operation: 'VALIDATE_SESSION'
  }
})

export const signUp = email => ({
  type: 'AUTH',
  payload: {
    operation: 'SIGN_UP',
    email
  }
})

export const createAccount = form => ({
  type: 'AUTH',
  payload: {
    operation: 'CREATE_ACCOUNT',
    form
  }
})

export const resolveEmail = form => ({
  type: 'AUTH',
  payload: {
    operation: 'RESOLVE_EMAIL',
    form
  }
})

export const forgottenPassword = form => ({
  type: 'AUTH',
  payload: {
    operation: 'FORGOTTEN_PASSWORD',
    form
  }
})

export const resetPassword = form => ({
  type: 'AUTH',
  payload: {
    operation: 'RESET_PASSWORD',
    form
  }
})
