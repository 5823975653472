import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncUserlist } from '../../../actions/middleware/Users.Actions'
import { addMember, removeMember } from '../../../actions/middleware/Groups.Actions'

import { Button, Row, Col } from 'reactstrap'

class Manage extends Component {
  componentDidMount () {
    // reconsider use this.props.syncGrouplist()
    // reconsider use this.props.syncUserlist()
  }

  render () {
    return (
      <>
        <Row>
          <Col>
            <h4>Members</h4>
            add or remove member
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.props.syncUserlist}>Force sync user list</Button>
            <h5>Users in group</h5>
            <ul>
              {this.props.users
                .filter(user => this.props.members.indexOf(user.uuid) > -1)
                .map(user => <li key={user.uuid}>
                  {user.username}
                  <Button onClick={() => this.props.removeMember(user.uuid)}>Remove from group</Button>
                </li>)}
            </ul>
            <h5>Users not in group</h5>
            <ul>
              {this.props.users
                .filter(user => this.props.members.indexOf(user.uuid) === -1)
                .map(user => <li key={user.uuid}>
                  {user.username}
                  <Button onClick={() => this.props.addMember(user.uuid)}>Add to group</Button>
                </li>)}
            </ul>
          </Col>
        </Row>
      </>
    )
  }
}

// filter to non existing
const mapStateToProps = ({ users, form }, ownProps) => ({
  users: users
    .items,
  submitting: form.submitting
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncUserlist: () => dispatch(syncUserlist()),
  addMember: member => dispatch(addMember(ownProps.id, member)),
  removeMember: member => dispatch(removeMember(ownProps.id, member))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manage)
