import {
  setFormSubmitting,
  setFormErrors,
  resetForm,
  setFormMessage
} from '../../../actions/reducer/Forms.Actions'

const forgottenPassword = ({ FormId, FormFields: { email } }) => (dispatch, _Backend) => {
  dispatch(resetForm(FormId))
  dispatch(setFormSubmitting(FormId, true))

  return _Backend.call('forgottenPassword', email)
    .then(() => {
      dispatch(setFormMessage(FormId, 'TXT_FORGOTTEN_PASSWORD_FORM__SUCCESS_MESSAGE'))
      return Promise.resolve()
    })
    .catch(err => {
      dispatch(setFormErrors(FormId, ['TXT_FORGOTTEN_PASSWORD_FORM__ERR_GENERIC']))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default forgottenPassword
