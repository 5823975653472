const initialState = []

// elems: { project: '', entity: '', permanent: 0 }

const AccessReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ACCESS_ADD':
      return state.some(el => el === action.payload)
        ? state
        : [...state, action.payload]
    case 'ACCESS_REMOVE':
      return state.filter(elem => elem.project !== action.payload.project || elem.entity !== action.payload.entity)
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default AccessReducer
