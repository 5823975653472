import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { syncTicket } from '../../actions/middleware/Tickets.Actions'

import { CONST_LIFETIME_TICKET } from '../../constants/Lifetime.Constants'

class TicketShortcut extends PureComponent {
  componentDidMount () {
    const { ticket } = this.props
    // case 1: no task
    // case 2: not syncing task, task is outdated but not syncing
    if (
      (!ticket) ||
      (ticket.syncedAt + CONST_LIFETIME_TICKET < Date.now() && !ticket.syncing)
    ) {
      this.props.syncTicket()
    }
  }

  render () {
    const { ticket } = this.props
    return ticket
      ? ticket.error === 'NO_MATCH'
        ? null
        : <Link to={'/ticket/' + ticket.uuid}>{ticket.name}</Link>
      : <span className='link-placeholder' />
  }
}

const mapStateToProps = ({ tickets }, ownProps) => ({
  ticket: tickets.find(u => u.uuid === ownProps.uuid)
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTicket: () => dispatch(syncTicket(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketShortcut)
