
import {
  removeAccess
} from '../../../actions/reducer/Access.Actions'

const revokeAccess = (project, entity) => (dispatch, _Backend) => {
  return _Backend.call('revokeAccess', project, entity)
    .then((result) => {
      dispatch(removeAccess({ project, entity }))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default revokeAccess
