import {
  setProjectTicketsSyncing
} from '../../../actions/reducer/Projects.Actions'

import {
  addTicket,
  updateTicket
} from '../../../actions/reducer/Ticket.Actions'
import { createTicketObject } from '../../../objects/create'

const syncProjectTickets = (projectUuid, getState) => (dispatch, _Backend) => {
  // update project tasks syncing flag
  dispatch(setProjectTicketsSyncing(projectUuid, true))
  return _Backend.call('getProjectTickets', projectUuid)
    .then(tickets => {
      const stateTickets = getState().tickets.filter(p => p.project === projectUuid)

      for (let ticket of tickets) {
        let stateIndex = stateTickets.findIndex(sTkt => sTkt.uuid === ticket.uuid)
        let item = createTicketObject(ticket)
        dispatch(stateIndex > -1
          ? updateTicket(item)
          : addTicket(item)
        )
      }
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setProjectTicketsSyncing(projectUuid, false)))
}

export default syncProjectTickets
