import React from 'react'
import {
  Switch,
  Route
} from 'react-router-dom'

import ProtectedRoute from '../common/ProtectedRoute'

import Dashboard from './dashboard/Index'
import Account from './account/Index'
import Auth from './auth/Index'
import Projects from './projects/Index'
import Project from './project/Index'
import User from './user/Index'
import Group from './group/Index'
import Task from './task/Index'
import Ticket from './ticket/Index'
import Community from './community/Index'

import NoMatch from './noMatch/NoMatch'

const Routes = ({ location }) => {
  return (
    <Switch>
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/'
        exact
        component={Dashboard} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/account'
        component={Account} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/projects'
        component={Projects} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/project/:projectUuid'
        component={Project} />
      <Route path='/auth' component={Auth} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/community'
        component={Community} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/user/:userUuid'
        component={User} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/group/:groupUuid'
        component={Group} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/task/:taskUuid'
        component={Task} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 1, exact: false },
          level: { value: 0, exact: false }
        }}
        path='/ticket/:ticketUuid'
        component={Ticket} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default Routes
