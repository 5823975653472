import { createProjectObject } from '../../../objects/create'

import {
  addProjectItem,
  updateProjectItem,
  setProjectSyncing
} from '../../../actions/reducer/Projects.Actions'

const getProject = (uuid, index) => (dispatch, _Backend) => {
  if (index > -1) {
    dispatch(setProjectSyncing(uuid, true))
  } else {
    dispatch(addProjectItem(createProjectObject({
      uuid,
      syncing: true
    })))
  }
  return _Backend.call('getProject', uuid)
    .then((project) => {
      let item = createProjectObject(project)

      dispatch(updateProjectItem(item))
      return Promise.resolve()
    })
    .catch(err => {
      const errors = err.properties || []

      const project = createProjectObject({
        uuid,
        error: errors.length === 0 ? 'FAILED_TO_FETCH' : errors[0]
      })

      index === -1
        ? dispatch(addProjectItem(project))
        : dispatch(updateProjectItem(project))

      return Promise.reject(err)
    })
    .finally(() => dispatch(setProjectSyncing(uuid, false)))
}

export default getProject
