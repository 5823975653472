import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import { push } from 'connected-react-router'

import {
  CONST_TASK_STATUSES,
  CONST_TASK_PRIORITIES
} from '../../../../../constants/Task.Constants'

import {
  Form,
  FormGroup,
  Input,
  Label
} from 'reactstrap'

// import Button from '../../../common/Button'

const removeEmptyProps = (obj) => {
  Object.keys(obj).forEach(key => (obj[key] === '') && delete obj[key])
  return obj
}

class Filters extends Component {
  constructor () {
    super()
    this.state = {
      query: '',
      status: ''
    }

    this.delayedSearch = null

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
    this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this)
    this.handlePriorityFilterChange = this.handlePriorityFilterChange.bind(this)
  }

  componentWillUnmount () {
    clearTimeout(this.delayedSearch)
  }

  handleQueryChange (e) {
    clearTimeout(this.delayedSearch)
    const query = e.target.value
    const { status, priority } = this.state
    this.setState({ query })
    const searchParams = removeEmptyProps({ status, priority, query })
    this.delayedSearch = setTimeout(
      () => this.props.search(searchParams),
      300 // prevent spamming
    )
  }

  handleStatusFilterChange (e) {
    clearTimeout(this.delayedSearch)
    const status = e.target.value
    const { query, priority } = this.state
    this.setState({ status })
    const searchParams = removeEmptyProps({ status, priority, query })
    this.delayedSearch = setTimeout(
      () => this.props.search(searchParams),
      300 // prevent spamming
    )
  }

  handlePriorityFilterChange (e) {
    clearTimeout(this.delayedSearch)
    const priority = e.target.value
    const { query, status } = this.state
    this.setState({ priority })
    const searchParams = removeEmptyProps({ status, priority, query })
    this.delayedSearch = setTimeout(
      () => this.props.search(searchParams),
      300 // prevent spamming
    )
  }

  handleSubmit (e) {
    e.preventDefault()
    const { query, status, priority } = this.state
    clearTimeout(this.delayedSearch)
    const searchParams = removeEmptyProps({ query, status, priority })
    this.delayedSearch = setTimeout(
      () => this.props.search(searchParams),
      300
    )
  }

  render () {
    const searchParams = qs.parse(this.props.location.search)
    return (
      <Form onSubmit={this.handleSubmit} inline>
        <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
          <Input
            type='text'
            required
            defaultValue={searchParams.query || ''}
            onChange={this.handleQueryChange}
            name='search-query'
            placeholder='Feladat keresése...'
          />
        </FormGroup>
        <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
          <Label for='search-task-status'>Státusz</Label>
          <Input
            id='search-task-status'
            type='select'
            required
            defaultValue={searchParams.status || ''}
            onChange={this.handleStatusFilterChange}
            name='search-status'>
            <option value=''>All</option>
            {
              CONST_TASK_STATUSES.map(st =>
                <option key={st.id} value={st.id}>{st.label}</option>)
            }
          </Input>
        </FormGroup>
        <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
          <Label for='search-task-priority'>Prioritás</Label>
          <Input
            id='search-task-priority'
            type='select'
            required
            defaultValue={searchParams.priority || ''}
            onChange={this.handlePriorityFilterChange}
            name='search-priority'>
            <option value=''>All</option>
            {
              CONST_TASK_PRIORITIES.map(pri =>
                <option key={pri.id} value={pri.id}>{pri.label}</option>)
            }
          </Input>
        </FormGroup>
      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
  search: (paramsObj) => dispatch(push(
    {
      pathname: '/project/' + ownProps.uuid + '/tasks', // no re-assign, always reset page number
      search: qs.stringify(paramsObj)
    }
  ))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Filters))
