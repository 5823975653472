import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { resetForm } from '../../../actions/reducer/Form.Actions'

import { syncTicket, updateTicketProperties } from '../../../actions/middleware/Tickets.Actions'
import { syncProject } from '../../../actions/middleware/Projects.Actions'

import {
  CONST_TICKET_STATUSES
} from '../../../constants/Ticket.Constants'

import NoMatch from '../noMatch/NoMatch'

import {
  Alert,
  Row,
  Col,
  Container,
  Badge,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Button,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'

// TODO: error, chaining syncs no project uuid while render link to phase

class UpdateTask extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)

    this.state = {
      name: '',
      description: '',
      isValidName: false,
      isValidDescription: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let {
      name,
      description
    } = this.state
    this.props.updateTicketProperties({
      name,
      description
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangeDescription (e) {
    let description = e.target.value
    this.setState({
      description,
      isValidDescription: !!description
    })
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  componentDidMount () {
    if (!this.props.ticket ||
      (this.props.ticket.syncedAt + (10 * 1000) < Date.now() && !this.props.ticket.syncing)) {
      this.props.syncTicket()
    } else if (!this.props.project) {
      // TODO: better solution for sync chain
      const { project } = this.props.ticket
      this.props.syncProject(project)

      this.setState({
        name: this.props.ticket.name,
        description: this.props.ticket.description,
        isValidName: true,
        isValidDescription: true
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // TODO: better solution for sync chain
    if (prevProps.ticket && prevProps.ticket.syncing && !this.props.ticket.syncing) {
      this.setState({
        name: this.props.ticket.name,
        description: this.props.ticket.description,
        isValidName: true,
        isValidDescription: true
      })
      const { project } = this.props.ticket
      this.props.syncProject(project)
    }
  }

  _statusBadge (status) {
    const { label } = CONST_TICKET_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _ticketstatus-${status}`}>
        {label.generic} - {label.detailed}
      </Badge>
    )
  }

  render () {
    const existInState = !!this.props.ticket
    const { isValidName, isValidDescription } = this.state
    const canSubmit = true // isValidName && isValidDescription && !this.props.phasesSyncing
    return existInState
      ? (
        this.props.ticket.error === 'NO_MATCH'
          ? <NoMatch />
          : (!this.props.ticket.syncing && <Container>
            <Row className='mt-3'>
              <Col>
                <Breadcrumb className='bg-light rounded'>
                  <BreadcrumbItem><Link to={'/projects'}>Projects</Link></BreadcrumbItem>
                  {(this.props.project) && <>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid}>{this.props.project.name}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid + '/tickets'}>Tickets</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/ticket/' + this.props.ticket.uuid}>{this.props.ticket.name}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Update ticket</BreadcrumbItem>
                </>}
                </Breadcrumb>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col md={{ size: 6, offset: 3 }}>
                <h4>Update properties of ticket: {this.props.ticket.name}</h4>
                {this._statusBadge(this.props.ticket.status)}<small className='text-muted'>ID {this.props.ticket.uuid}</small>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
                  {this.props.errors.length > 0 &&
                  <Alert color='danger' className='text-center'>
                    {this.props.errors.map(el => el).join(' ')}
                  </Alert>}
                  <FormGroup>
                    <Label for='task-name'>Task name</Label>
                    <Input
                      type='text'
                      maxLength={128}
                      defaultValue={this.props.ticket.name}
                      name='task-name'
                      id='task-name'
                      tabIndex={1}
                      placeholder='Task name'
                      disabled={this.props.submitting}
                      invalid={!!this.state.name && !isValidName}
                      onChange={this.handleChangeName}
                      required />
                    <FormText color='muted'>
                  128/{this.state.name.length}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for='task-description'>Description</Label>
                    <Input
                      type='textarea'
                      maxLength={10000}
                      name='task-description'
                      defaultValue={this.props.ticket.description}
                      id='task-description'
                      tabIndex={2}
                      placeholder='description'
                      invalid={!!this.state.description && !isValidDescription}
                      onChange={this.handleChangeDescription}
                      disabled={this.props.submitting}
                      required />
                    <FormText color='muted'>
                      10000/{this.state.description.length}
                    </FormText>
                  </FormGroup>
                  <Button color='primary'
                    type='submit'
                    tabIndex={3}
                    disabled={!canSubmit || this.props.submitting}
                  >
                    Update ticket
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>)
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ tickets, projects, form }, ownProps) => {
  const ticket = tickets.find(u => u.uuid === ownProps.match.params.ticketUuid)
  const project = ticket
    ? projects.items.find(u => u.uuid === ticket.project)
    : undefined
  return {
    ticket,
    project,
    submitting: form.submitting,
    errors: form.errors
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTicket: () => dispatch(syncTicket(ownProps.match.params.ticketUuid)),
  syncProject: uuid => dispatch(syncProject(uuid)),
  updateTicketProperties: form => dispatch(updateTicketProperties(ownProps.match.params.ticketUuid, form)),
  resetForm: () => dispatch(resetForm())
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UpdateTask)
