import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import UserShortcut from '../../../common/UserShortcut'

import ReactMarkdown from 'react-markdown'
import linkify from 'markdown-linkify'

import {
  Row,
  Col,
  Alert
} from 'reactstrap'

import Participants from './Participants'

class Summary extends Component {
  render () {
    return (
      <Row>
        <Col>
          {this.props.project.status === 0 && <Alert className='mt-3' color='primary'>
            This project is hidden, that means its only visible for admins. In this status you can prepare your project for launch. If you are ready, go to <Link className='alert-link' to={`/project/${this.props.match.params.projectUuid}/settings`}>Settings</Link> tab and set status to open.
          </Alert>}
          <Row>
            <Col md={8}>
              <Row className='my-3 align-items-center'>
                <Col className='mb-2'>
                  <small className='d-block'>
                    Updated at: <br />{new Date(this.props.project.updatedAt).toLocaleString()}
                  </small>
                </Col>
                <Col className='mb-2'>
                  <small className='d-block'>
                    Created at: <br />{new Date(this.props.project.createdAt).toLocaleString()}
                  </small>
                </Col>
                <Col className='mb-2'>
                  <small className='d-block'>Created by:</small>
                  <UserShortcut
                    uuid={this.props.project.creator}
                    display={{ thumb: true, username: true, size: 'small' }}
                  />
                </Col>
              </Row>
              <ReactMarkdown
                className='bg-light rounded p-3'
                source={linkify(this.props.project.description)} />
            </Col>
            <Col md={4}>
              <Participants
                uuid={this.props.project.uuid}
                {...this.props.project.participants} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ projects, users, groups, accesses }, ownProps) => {
  return {
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary)
