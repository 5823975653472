import React, { Component } from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import { Redirect, Link } from 'react-router-dom'

import { signUp } from '../../../actions/middleware/Auth.Actions'
import { resetForm } from '../../../actions/reducer/Form.Actions'

import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  Alert
} from 'reactstrap'

import { isEmail } from '../../common/Utils'

class SignUp extends Component {
  constructor () {
    super()

    this.state = {
      email: '',
      isValidEmail: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    this.props.signUp(this.state.email)
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  handleChangeEmail (e) {
    let email = e.target.value
    this.setState({
      email,
      isValidEmail: isEmail(email)
    })
  }

  render () {
    if (this.props.isAuthenticated && !this.props.submitting) {
      return <Redirect to='/' />
    } else {
      const { isValidEmail } = this.state
      const canSubmit = isValidEmail
      const { feedback } = qs.parse(this.props.location.search)
      return (
        <Container>
          <Row className='justify-content-center'>
            <Col md={10} lg={6}>
              <h1>Sign up</h1>

              {
                feedback === 'sent'
                  ? <>
                    <Alert color='success' className='text-center'>
                        Your request has been successfully sent, please check your email and follow the instructions to complete your registration!
                    </Alert>
                    </>
                  : <>
                    <Form onSubmit={this.handleSubmit} className='mb-4'>
                      <FormText>
                        We will send you an email to create a new account.
                        In this email, there is a link to complete your registration.
                      </FormText>
                      {this.props.errors.length > 0 &&
                        <Alert color='danger' className='text-center'>
                          {this.props.errors.map(el => el).join(' ')}
                        </Alert>}
                      <FormGroup>
                        <Label for='email'>Email address</Label>
                        <Input
                          type='email'
                          name='email'
                          id='email'
                          tabIndex={1}
                          placeholder='Email address'
                          disabled={this.props.submitting}
                          invalid={!!this.state.email && !isValidEmail}
                          onChange={this.handleChangeEmail}
                          required />
                      </FormGroup>
                      <Button color='primary'
                        type='submit'
                        tabIndex={2}
                        disabled={!canSubmit || this.props.submitting}
                      >
                        Sign up
                      </Button>
                    </Form>
                    <Button color='link' tag={Link} to='/auth/sign-in'>
                      Already have account? Sign in
                    </Button>
                  </>
              }
            </Col>
          </Row>
        </Container>
      )
    }
  }
}

const mapStateToProps = ({ form, user }, ownProps) => ({
  submitting: form.submitting,
  errors: form.errors,
  isAuthenticated: !!user.uuid
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  signUp: email => dispatch(signUp(email)),
  resetForm: () => dispatch(resetForm())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)
