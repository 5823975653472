import { createGroupObject } from '../../../objects/create'

import {
  setGroupsSyncedAt,
  setGroupsSyncing,
  addGroup,
  updateGroup
} from '../../../actions/reducer/Groups.Actions'

const syncList = (stateGroups) => (dispatch, _Backend) => {
  dispatch(setGroupsSyncing(true))
  return _Backend.call('groups')
    .then(groups => {
      for (let group of groups) {
        let stateIndex = stateGroups.findIndex(item => item.uuid === group.uuid)
        group.syncedAt = stateIndex > -1 ? Date.now() : null
        let item = createGroupObject(group)
        dispatch(stateIndex > -1
          ? updateGroup(item)
          : addGroup(item)
        )
      }

      dispatch(setGroupsSyncedAt(Date.now()))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setGroupsSyncing(false)))
}

export default syncList
