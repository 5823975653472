import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncGroup, deleteGroup } from '../../../actions/middleware/Groups.Actions'

import { Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import NoMatch from '../noMatch/NoMatch'
import Delete from './Delete'
import Manage from './Manage'

import UserShortcut from '../../common/UserShortcut'
import ProtectedComponent from '../../common/ProtectedComponent'

class Group extends Component {
  componentDidMount () {
    if (!this.props.group ||
      (this.props.group.syncedAt + (10 * 1000) < Date.now() && !this.props.group.syncing)) {
      this.props.syncGroup()
    }
  }

  render () {
    let existInState = !!this.props.group
    return existInState
      ? (
        this.props.group.error === 'NO_MATCH'
          ? <NoMatch />
          : <Container>
            <Row>
              <Col sm={2}>
                {this.props.group.thumb.n &&
                <img src={this.props.group.thumb.n} alt='thumb' className='w-100 rounded' />}
              </Col>
              <Col sm={10}>
                <h3>
                  {this.props.group.name}
                </h3>
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 2, exact: false },
                    level: { value: 3, exact: false }
                  }}>
                  <Delete
                    buttonLabel='Delete group'
                    actionHandler={this.props.deleteGroup}
                  />
                  <Link to={`/group/${this.props.group.uuid}/update`}>Update</Link>
                </ProtectedComponent>
                <h4>Members</h4>
                {
                  this.props.group.members.map(m => <UserShortcut
                    display={{ thumb: true, username: false, size: 'small' }} uuid={m} />)
                }
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 2, exact: false },
                    level: { value: 3, exact: false }
                  }}>
                  <Manage id={this.props.match.params.groupUuid}
                    members={this.props.group.members} />
                </ProtectedComponent>

              </Col>
            </Row>
          </Container>
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ groups }, ownProps) => {
  return {
    group: groups.items.find(g => g.uuid === ownProps.match.params.groupUuid)
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncGroup: () => dispatch(syncGroup(ownProps.match.params.groupUuid)),
  deleteGroup: () => dispatch(deleteGroup(ownProps.match.params.groupUuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Group)
