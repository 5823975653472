import React, { Component } from 'react'
import { connect } from 'react-redux'

import { signIn } from '../../../../actions/middleware/Auth.Actions'

import translate from '../../../../utils/translations/Translate'

import {
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Alert
} from 'reactstrap'

import { isEmail, isPassword } from '../../../common/Utils'
import Button from '../../../common/Button'
import Translated from '../../../common/Translated'

class SignIn extends Component {
  constructor () {
    super()

    this.state = {
      email: '',
      password: '',
      remember: true,
      isValidEmail: false,
      isValidPassword: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeRemember = this.handleChangeRemember.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { email, password, remember } = this.state
    this.props.signIn({
      FormId: this.props.form.id,
      FormFields: {
        email,
        password,
        remember
      }
    })
  }

  handleChangeEmail (e) {
    let email = e.target.value
    this.setState({
      email,
      isValidEmail: isEmail(email)
    })
  }

  handleChangePassword (e) {
    let password = e.target.value
    this.setState({
      password,
      isValidPassword: isPassword(password)
    })
  }

  handleChangeRemember (e) {
    let remember = e.target.checked
    this.setState({
      remember
    })
  }

  render () {
    const { isValidEmail, isValidPassword } = this.state
    const canSubmit = isValidEmail && isValidPassword
    const { language } = this.props
    return (
      <Form
        onSubmit={this.handleSubmit}
        className='border bg-white rounded my-4 p-3 shadow-sm'>
        <h3 className='text-center'><Translated txtid='TXT_SIGN_IN_FORM__HEADING' /></h3>
        {this.props.form.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.form.errors
              .map((el, i) =>
                <span key={i}><Translated txtid={el} />{' '}</span>
              )
            }
          </Alert>}
        <FormGroup>
          <Label for='email'><Translated txtid='TXT_SIGN_IN_FORM__INPUT_LABEL_EMAIL' /></Label>
          <Input
            type='email'
            name='email'
            id='email'
            tabIndex={1}
            placeholder={translate(language, 'TXT_SIGN_IN_FORM__INPUT_PLACEHOLDER_EMAIL')}
            disabled={this.props.form.submitting}
            invalid={!!this.state.email && !isValidEmail}
            onChange={this.handleChangeEmail}
            required />
        </FormGroup>
        <FormGroup>
          <Label for='password'><Translated txtid='TXT_SIGN_IN_FORM__INPUT_LABEL_PASSWORD' /></Label>
          <Input
            type={this.state.passwordVisible ? 'text' : 'password'}
            name='password'
            id='password'
            tabIndex={2}
            placeholder={translate(language, 'TXT_SIGN_IN_FORM__INPUT_PLACEHOLDER_PASSWORD')}
            invalid={!!this.state.password && !isValidPassword}
            onChange={this.handleChangePassword}
            disabled={this.props.form.submitting}
            required />
        </FormGroup>
        <FormGroup row>
          <Col>
            <CustomInput onChange={this.handleChangeRemember}
              type='checkbox'
              id='remember'
              tabIndex={3}
              disabled={this.props.form.submitting}
              defaultChecked
              label={<Translated txtid='TXT_SIGN_IN_FORM__INPUT_LABEL_REMEMBER' />} />
          </Col>
        </FormGroup>
        <div className='d-flex w-100 justify-content-center'>
          <Button
            className={this.props.form.submitting && 'submitting'}
            color='add'
            type='submit'
            tabIndex={4}
            disabled={!canSubmit || this.props.form.submitting}
          >
            <Translated txtid='TXT_SIGN_IN_FORM__BUTTON_LABEL_SIGN_IN' />
          </Button>
        </div>
      </Form>
    )
  }
}

const mapStateToProps = ({ app: { language } }) => ({
  language
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  signIn: form => dispatch(signIn(form))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
