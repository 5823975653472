import ValidateSession from './ValidateSession'
import SignIn from './SignIn'
import SignOut from './SignOut'
import SignUp from './SignUp'
import ResolveEmail from './ResolveEmail'
import CreateAccount from './CreateAccount'
import ForgottenPassword from './ForgottenPassword'
import ResetPassword from './ResetPassword'

const auth = API => {
  return {
    validateSession: (...params) => dispatch => ValidateSession(...params)(dispatch, API),
    signIn: (...params) => dispatch => SignIn(...params)(dispatch, API),
    signOut: () => dispatch => SignOut()(dispatch, API),
    signUp: (email) => dispatch => SignUp(email)(dispatch, API),
    resolveEmail: (...params) => dispatch => ResolveEmail(...params)(dispatch, API),
    createAccount: (form, email) => dispatch => CreateAccount(form, email)(dispatch, API),
    forgottenPassword: form => dispatch => ForgottenPassword(form)(dispatch, API),
    resetPassword: form => dispatch => ResetPassword(form)(dispatch, API)
  }
}

export default auth
