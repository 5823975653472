import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { syncUser } from '../../../actions/middleware/Users.Actions'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import ProtectedComponent from '../../common/ProtectedComponent'

import NoMatch from '../noMatch/NoMatch'

class User extends Component {
  componentDidMount () {
    if (!this.props.user ||
      (this.props.user.syncedAt + (10 * 1000) < Date.now() && !this.props.user.syncing)) {
      this.props.syncUser()
    }
  }

  render () {
    let existInState = !!this.props.user
    return existInState
      ? (
        this.props.user.error === 'NO_MATCH'
          ? <NoMatch />
          : <Container>
            <Row>
              <Col sm={2}>
                {this.props.user.profile.thumb.n &&
                <img src={this.props.user.profile.thumb.n} alt='thumb' className='w-100 rounded' />}
              </Col>
              <Col>
                <h5>{this.props.user.username}
                  <small>(role: {this.props.user.role.group}.{this.props.user.role.level})</small>
                </h5>
          Name: {this.props.user.profile.name || <i>Not set</i>}
          Phone: {this.props.user.profile.phone || <i>Not set</i>}
            TODO: position, workplace, contact email
                  update role
                  update level
                {
                  // JSON.stringify(this.props.user)
                }
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 2, exact: false },
                    level: { value: 3, exact: false }
                  }}>
                  <Link to={'/user/' + this.props.user.uuid + '/manage'}>Manage</Link>
                </ProtectedComponent>

                <b>IDE MÉG A LEGUTÓBBI AKTIVITÁSOK IS KELLENEK</b>
              </Col>
            </Row>
          </Container>
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ users }, ownProps) => {
  return {
    user: users.items.find(u => u.uuid === ownProps.match.params.userUuid)
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncUser: () => dispatch(syncUser(ownProps.match.params.userUuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User)
