import {
  createProjectObject,
  createPhaseObject
} from '../../../objects/create'

import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  addProjectItem
} from '../../../actions/reducer/Projects.Actions'
import {
  addPhase
} from '../../../actions/reducer/Phases.Actions'

import { push } from 'connected-react-router'

const create = (formData, userUuid) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(true))

  return _Backend.call('createProject', formData)
    .then(({ uuid, phase }) => {
      const project = createProjectObject({
        uuid,
        name: formData.name,
        description: formData.description,
        creator: userUuid,
        status: 0
      })
      dispatch(addProjectItem(project))
      const firstPhase = createPhaseObject({
        uuid: phase.uuid,
        project: uuid,
        name: formData.phase,
        creator: userUuid
      })
      dispatch(addPhase(firstPhase))
      dispatch(push('/project/' + uuid))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default create
