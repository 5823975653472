import { createFileObject } from '../../../objects/create'

import {
  addFile
} from '../../../actions/reducer/Files.Actions'

const syncFile = (uuid) => (dispatch, _Backend) => {
  return _Backend.call('getFileDescriptor', uuid)
    .then(fileDescriptor => {
      dispatch(
        addFile(createFileObject(fileDescriptor))
      )
      return Promise.resolve()
    })
    .catch(err => {
      const errors = err.properties || []

      const item = createFileObject({
        uuid,
        error: errors.length === 0 ? 'FAILED_TO_FETCH' : errors[0]
      })
      dispatch(addFile(item))
      return Promise.reject(err)
    })
}

export default syncFile
