import { connect } from 'react-redux'

import { signOut } from '../../actions/middleware/Auth.Actions'

import React, { Component } from 'react'
import { NavLink as Link, withRouter } from 'react-router-dom'
import {
  Col,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'

class NavBar extends Component {
  constructor (props) {
    super(props)

    this.placeholder = React.createRef()
    this.navbar = React.createRef()
    this.posY = 0

    this.state = {
      isOpen: false,
      showBackButton: false,
      fixed: false,
      hidden: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    return (
      this.props.isAuthenticated &&
      <Navbar
        light
        expand='md'
        className='border-bottom'
      >
        <Col
          xs={6}
          sm={6}
          md={4}
          lg={4}
          xl={4}
        >
          <Link to='/'>
              Dashboard
          </Link>
        </Col>
        <Col
          xs={6}
          sm={6}
          md={4}
          lg={4}
          xl={4}
        />
        <Col
          xs={6}
          sm={6}
          md={4}
          lg={4}
          xl={4}
        >
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className='ml-auto' navbar>
              {!this.props.isAuthenticated
                ? <>
                  <NavItem>
                    <NavLink tag={Link} to='/auth/sign-in'>
                      Sign in
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to='/auth/sign-up'>
                      Sign up
                    </NavLink>
                  </NavItem>
                </>
                : <>
                  <NavItem>
                    <NavLink tag={Link} to='/projects'>
                      Projects
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to='/community'>
                      Community
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav caret>
                      Account
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem disabled>
                        <div>
                          Signed in: <br />
                          <b>{this.props.username}</b><br />
                          {this.props.account}
                        </div>
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem>
                        <NavLink tag={Link} to='/account/profile'>
                          Profile
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem className='d-none'>
                        <NavLink tag={Link} to='/account/billing'>
                          Billing
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink tag={Link} to='/account/settings'>
                          Settings
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink onClick={this.props.signOut}>
                          Sign out
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              }
            </Nav>
          </Collapse>
        </Col>
      </Navbar>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  isAuthenticated: !!user.uuid,
  account: user.email,
  username: user.username
})
const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(signOut())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NavBar))
