import React, { Component } from 'react'
import { connect } from 'react-redux'

import { createGroup } from '../../../../../actions/middleware/Groups.Actions'

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

import Preview from '../../../../common/PreviewThumbnail'

import { compressImage } from '../../../../common/Utils'

class CreateGroup extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      thumb: null,
      isValidName: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeThumb = this.handleChangeThumb.bind(this)
    this.handleRejectThumb = this.handleRejectThumb.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { name, thumb } = this.state
    if (thumb) thumb = thumb.file
    this.props.createGroup({
      FormId: this.props.form.id,
      FormFields: { name, thumb }
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  async handleChangeThumb (e) {
    let _thumb = await compressImage(e, { width: 256, height: 256 })
    const thumb = {
      file: _thumb,
      name: _thumb.name,
      preview: URL.createObjectURL(_thumb),
      size: _thumb.size
    }

    this.setState({
      thumb
    })
  }

  handleRejectThumb (e) {
    this.setState({
      thumb: null
    })
  }

  render () {
    const { isValidName } = this.state
    const canSubmit = isValidName
    return (
      <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
        {this.props.form.errors.length > 0 &&
        <Alert color='danger' className='text-center'>
          {this.props.form.errors.map(el => el).join(' ')}
        </Alert>}
        <FormGroup>
          <Label for='project-name'>Group name</Label>
          <Input
            type='text'
            maxLength={128}
            name='group-name'
            id='group-name'
            tabIndex={1}
            placeholder='Group name'
            disabled={this.props.form.submitting}
            invalid={!!this.state.name && !isValidName}
            onChange={this.handleChangeName}
            required />
          <FormText color='muted'>
                  128/{this.state.name.length}
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for='group-thumb'>Upload thumbnail for group</Label>
          {this.state.thumb
            ? <Preview
              handleReject={this.handleRejectThumb}
              details={this.state.thumb} />
            : <Input
              type='file'
              name='group-thumb'
              id='group-thumb'
              onChange={this.handleChangeThumb} />
          }
          <FormText color='muted'>
                  Thumbnail will be cropped to square and resized.
          </FormText>
        </FormGroup>
        <Button color='primary'
          type='submit'
          tabIndex={3}
          disabled={!canSubmit || this.props.form.submitting}
        >
        Create
        </Button>
      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
  createGroup: form => dispatch(createGroup(form))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGroup)
