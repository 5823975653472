// import functors
import { users } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const UsersMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'USERS') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'SYNC_LIST':
      chain(
        users.syncList(getState().users.items)
      )(dispatch)
      break
    case 'SYNC':
      chain(
        users.syncUser(
          action.payload.uuid,
          getState().users.items
            .findIndex(item => item.uuid === action.payload.uuid)
        )
      )(dispatch)
      break
    case 'INVITE':
      chain(
        users.invite(
          action.payload.form
        )
      )(dispatch)
      break
    case 'UPDATE_ROLE_GROUP':
      chain(
        [
          users.updateRoleGroup(
            action.payload.user,
            action.payload.role
          ),
          users.syncUser(action.payload.user)
        ]
      )(dispatch)
      break
    case 'UPDATE_ROLE_LEVEL':
      chain(
        [
          users.updateRoleLevel(
            action.payload.user,
            action.payload.level
          ),
          users.syncUser(action.payload.user)
        ]
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default UsersMiddleware
