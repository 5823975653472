
import {
  addAccess
} from '../../../actions/reducer/Access.Actions'

const grantAccess = (project, entity) => (dispatch, _Backend) => {
  return _Backend.call('grantAccess', project, entity)
    .then((result) => {
      dispatch(addAccess({ project, entity, permanent: 0 }))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => console.log('fetch project finally'))
}

export default grantAccess
