export const setAppInitialized = isInitialized => ({
  type: 'APP_SET_INITIALIZED',
  payload: isInitialized
})

export const setAppLanguage = flag => ({
  type: 'APP_SET_LANGUAGE',
  payload: flag
})

export const setAppDashboardSyncing = isSyncing => ({
  type: 'APP_SET_DASHBOARD_SYNCING',
  payload: isSyncing
})
