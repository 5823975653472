import {
  setFormSubmitting,
  resetForm,
  setFormErrors
} from '../../../actions/reducer/Forms.Actions'

const comment = (ticket, { FormId, FormFields: { comment } }) => (dispatch, _Backend) => {
  dispatch(resetForm(FormId))
  dispatch(setFormSubmitting(FormId, true))

  return _Backend.call('commentTicket', ticket, comment)
    .then(() => {
      Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['TXT_COMMENT_TICKET_FORM__ERR_GENERIC']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          for (const error of errorArr) {
            errors.push('TXT_COMMENT_TICKET_FORM__' + error)
          }
          return errors
        }
      }
      dispatch(setFormErrors(FormId, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default comment
