// import functors
import { auth } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const AuthMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'AUTH') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'VALIDATE_SESSION':
      chain(
        auth.validateSession()
      )(dispatch)
      break
    case 'SIGN_IN':
      chain(
        auth.signIn(
          action.payload.form,
          getState().router.location.search
        )
      )(dispatch)
      break
    case 'SIGN_OUT':
      chain(
        auth.signOut()
      )(dispatch)
      break
    case 'SIGN_UP':
      chain(
        auth.signUp(
          action.payload.email
        )
      )(dispatch)
      break
    case 'RESOLVE_EMAIL':
      chain(
        auth.resolveEmail(
          action.payload.form
        )
      )(dispatch)
      break
    case 'CREATE_ACCOUNT':
      chain(
        auth.createAccount(
          action.payload.form,
          getState().router.location.search
        )
      )(dispatch)
      break
    case 'FORGOTTEN_PASSWORD':
      chain(
        auth.forgottenPassword(
          action.payload.form
        )
      )(dispatch)
      break
    case 'RESET_PASSWORD':
      chain(
        auth.resetPassword(
          action.payload.form
        )
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default AuthMiddleware
