import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { syncGrouplist } from '../../../../actions/middleware/Groups.Actions'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Breadcrumbs from '../../../common/Breadcrumbs'
import GroupShortcut from '../../../common/GroupShortcut'

class Groups extends Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount () {
    if (!this.props.syncedAt || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncGrouplist()
    }
  }

  render () {
    return (
      <Container>
        <Row>
          <Col>
            <Breadcrumbs items={[
              { label: 'Community', link: '/community' },
              { label: 'Groups', link: '/community/groups' }
            ]} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Groups</h3>
            <Link to='/community/groups/create'>Create new group</Link>
          </Col>
        </Row>
        <Row>
          {this.props.groups.map(g =>
            <Col
              key={g.uuid}
              className='d-flex'
              lg={3}
              md={4}
              sm={6}>
              <div className='align-self-stretch w-100 border rounded mb-4 p-2 shadow-sm'>
                <GroupShortcut uuid={g.uuid} />
              </div>
            </Col>)}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = ({ groups }, ownProps) => {
  return {
    syncedAt: groups.syncedAt,
    groups: groups.items
  }
}

// syncUserEntities
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncGrouplist: () => dispatch(syncGrouplist())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups)
