import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  updateTicket
} from '../../../actions/reducer/Ticket.Actions'

import { push } from 'connected-react-router'

const updateProperties = (currentTicket, updateProps) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(true))

  // check fields
  if (currentTicket.name === updateProps.name) delete updateProps.name
  if (currentTicket.description === updateProps.description) delete updateProps.description

  return _Backend.call('updateTicketProperties', currentTicket.uuid, updateProps)
    .then(() => {
      // update state props
      dispatch(updateTicket(currentTicket.uuid, updateProps))
      dispatch(push('/ticket/' + currentTicket.uuid))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default updateProperties
