import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncProject } from '../../../../actions/middleware/Projects.Actions'

import {
  CONST_PROJECT_STATUSES
} from '../../../../constants/Project.Constants'

import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Badge
} from 'reactstrap'

import UserShortcut from '../../../common/UserShortcut'
import { CONST_LIFETIME_PROJECT } from '../../../../constants/Lifetime.Constants'

class Project extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)
  }

  _statusBadge (status) {
    const { label } = CONST_PROJECT_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _projectstatus-${status}`}>
        {label}
      </Badge>
    )
  }

  componentDidMount () {
    // sync history if needed
    if (!this.props.data ||
        (this.props.data.syncedAt + CONST_LIFETIME_PROJECT < Date.now() && !this.props.data.syncing)) {
      this.props.syncProject()
    }
  }

  render () {
    return (
      <Row>
        <Col className='project mb-3'>
          <div className='pt-3 border-top'>
            <Link
              to={'/project/' + this.props.data.uuid}
            >
              {this.props.data.name}
            </Link>
            <div className='d-flex w-100 align-items-center'>
              {this._statusBadge(this.props.data.status)}
              <small className='mr-2 text-muted'>ID {this.props.data.uuid}</small>
            </div>
            <Row>
              <Col>
                <small>
                  created at {new Date(this.props.data.createdAt).toLocaleString()} by{' '}
                  <UserShortcut
                    display={{ thumb: true, username: true, size: 'small' }}
                    uuid={this.props.data.creator}
                  />, recently updated at {new Date(this.props.data.updatedAt).toLocaleString()}
                </small>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ accesses }, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncProject: () => dispatch(syncProject(ownProps.data.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Project)
