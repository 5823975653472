import React from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import {
  Redirect,
  Link
} from 'react-router-dom'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Button from '../../../common/Button'
import FormStateWrapper from '../../../common/FormStateWrapper'
import SwitchLanguage from '../../../common/SwitchLanguage'
import CreateAccount from './CreateAccount'

import Translated from '../../../common/Translated'
import NoMatch from '../../noMatch/NoMatch'

const CreateAccountPage = ({ isAuthenticated, location: { search } }) => {
  const { request } = qs.parse(search)
  if (!request) {
    return <NoMatch />
  }
  if (isAuthenticated) {
    return <Redirect to='/' />
  } else {
    return (
      <Container fluid className='bg-light'>
        <Container>
          <Row className='justify-content-center align-items-center mh-100vh'>
            <Col sm={12} md={6} lg={5}>
              <FormStateWrapper
                hash={request}
                component={CreateAccount}
              />
              <div className='d-flex w-100 justify-content-center'>
                <Button
                  tag={Link}
                  size='small'
                  to='/auth/sign-in'>
                  <Translated txtid='TXT_CREATE_ACCOUNT_FORM__BUTTON_LABEL_GO_TO_SIGN_IN' />
                </Button>
              </div>
              <div className='d-flex w-100 my-3 justify-content-center'>
                <SwitchLanguage />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  isAuthenticated: !!user.uuid
})
const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccountPage)
