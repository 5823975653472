export const syncFiles = uuid => ({
  type: 'FILES',
  payload: {
    operation: 'SYNC_LIST',
    project: uuid
  }
})

export const getSignature = uuid => ({
  type: 'FILES',
  payload: {
    operation: 'GET_SIGNATURE',
    uuid
  }
})

export const syncFileDescriptor = uuid => ({
  type: 'FILES',
  payload: {
    operation: 'SYNC',
    uuid
  }
})

export const uploadFile = (project, form) => ({
  type: 'FILES',
  payload: {
    operation: 'UPLOAD',
    project,
    form
  }
})

export const deleteFile = uuid => ({
  type: 'FILES',
  payload: {
    operation: 'DELETE',
    uuid
  }
})
