export const addPhase = p => ({
  type: 'PHASES_ADD',
  payload: p
})

export const updatePhase = (uuid, props) => ({
  type: 'PHASES_UPDATE',
  payload: { uuid, props }
})

export const removePhase = uuid => ({
  type: 'PHASES_REMOVE',
  payload: uuid
})
