
import Project from './Project'
import UpdateStatus from './UpdateStatus'
import UpdateProperties from './UpdateProperties'

import Participants from './Participants'
import GrantAccess from './GrantAccess'
import RevokeAccess from './RevokeAccess'

import Phases from './Phases'
import AddPhase from './AddPhase'
import UpdatePhase from './UpdatePhase'

const project = API => {
  return {
    getProject: (...params) => dispatch => Project(...params)(dispatch, API),
    updateStatus: (...params) => dispatch => UpdateStatus(...params)(dispatch, API),
    updateProperties: (...params) => dispatch => UpdateProperties(...params)(dispatch, API),
    participants: (...params) => dispatch => Participants(...params)(dispatch, API),
    grantAccess: (...params) => dispatch => GrantAccess(...params)(dispatch, API),
    revokeAccess: (...params) => dispatch => RevokeAccess(...params)(dispatch, API),
    phases: (...params) => dispatch => Phases(...params)(dispatch, API),
    addPhase: (...params) => dispatch => AddPhase(...params)(dispatch, API),
    updatePhase: (...params) => dispatch => UpdatePhase(...params)(dispatch, API)
  }
}

export default project
