const initialState = {
  submitting: false,
  step: 0,
  errors: []
}

const FormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FORM_SET_SUBMITTING':
      return Object.assign({}, state, {
        submitting: action.payload
      })
    case 'FORM_SET_ERRORS':
      return Object.assign({}, state, {
        errors: Object.assign([], action.payload)
      })
    case 'FORM_SET_STEP':
      return Object.assign({}, state, {
        step: action.payload
      })
    case 'FORM_RESET':
      return Object.assign({}, {
        submitting: false,
        step: 0,
        errors: []
      })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default FormReducer
