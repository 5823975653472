import {
  removeGroupMember
} from '../../../actions/reducer/Groups.Actions'

const removeMember = (group, member) => (dispatch, _Backend) => {
  return _Backend.call('removeGroupMember', group, member)
    .then(() => {
      dispatch(removeGroupMember(group, member))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => console.log('removeGroupMember finally'))
}

export default removeMember
