import React from 'react'

import { Switch, Route } from 'react-router-dom'

import Task from './Task'
import Update from './Update'

import NoMatch from '../noMatch/NoMatch'

const TaskRouter = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={`${path}/`} component={Task} />
      <Route exact path={`${path}/update`} component={Update} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default TaskRouter
