export const addProjectItem = projectObject => ({
  type: 'PROJECTS_ADD_ITEM',
  payload: projectObject
})

export const updateProjectItem = projectObject => ({
  type: 'PROJECTS_UPDATE_ITEM',
  payload: projectObject
})

export const removeProjectItem = uuid => ({
  type: 'PROJECTS_REMOVE_ITEM',
  payload: uuid
})

export const setProjectListSyncedAt = syncedAt => ({
  type: 'PROJECTS_SET_SYNCED_AT',
  payload: syncedAt
})

export const setProjectListSyncing = isSyncing => ({
  type: 'PROJECTS_SET_SYNCING',
  payload: isSyncing
})

export const setProjectSyncing = (uuid, syncing) => ({
  type: 'PROJECTS_SET_ITEM_SYNCING',
  payload: { uuid, syncing }
})

export const setProjectTasksSyncing = (uuid, syncing) => ({
  type: 'PROJECTS_SET_ITEM_TASKS_SYNCING',
  payload: { uuid, syncing }
})

export const setProjectTicketsSyncing = (uuid, syncing) => ({
  type: 'PROJECTS_SET_ITEM_TICKETS_SYNCING',
  payload: { uuid, syncing }
})

export const setProjectFilesSyncing = (uuid, syncing) => ({
  type: 'PROJECTS_SET_ITEM_FILES_SYNCING',
  payload: { uuid, syncing }
})
