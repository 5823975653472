const defaultObject = {
  uuid: '',
  project: '',
  name: '',
  creator: '',
  createdAt: Date.now(),
  error: '',
  syncedAt: Date.now(),
  syncing: false
}

export const createPhaseObject = p => {
  return Object.assign(
    {},
    defaultObject,
    p
  )
}
