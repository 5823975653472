import {
  createTaskObject
} from '../../../objects/create'

import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  addTask
} from '../../../actions/reducer/Task.Actions'

import { push } from 'connected-react-router'

const create = (project, formData, userUuid) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(true))

  return _Backend.call('createTask', project, formData)
    .then(({ uuid }) => {
      const task = createTaskObject({
        uuid,
        ...formData
      })
      dispatch(addTask(task))
      dispatch(push('/task/' + uuid))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default create
