import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { syncUser } from '../../actions/middleware/Users.Actions'

import { CONST_LIFETIME_USER } from '../../constants/Lifetime.Constants'

class UserShortcut extends PureComponent {
  constructor () {
    super()
    this.thumbnailClassname = this.thumbnailClassname.bind(this)
  }

  thumbnailClassname () {
    const { user } = this.props
    return user ? ` _usertype-${user.role.group}-${user.role.level}` : ''
  }

  componentDidMount () {
    const { isCurrentUser, user, listSyncing } = this.props
    // case 1: not syncing list, no user
    // case 2: not syncing list, user is outdated but not syncing
    if (!isCurrentUser &&
      (
        (!listSyncing && !user) ||
        (!listSyncing && user.syncedAt + CONST_LIFETIME_USER < Date.now() && !user.syncing)
      )
    ) {
      this.props.syncUser()
    }
  }

  render () {
    const { user, isCurrentUser } = this.props
    const linkTo = isCurrentUser ? '/account/profile' : (user && '/user/' + user.uuid)
    const WrapperTag = this.props.disabled ? 'span' : Link
    return user
      ? (
        user.error === 'NO_MATCH'
          ? null
          : <WrapperTag
            className={'_usershortcut d-inline-block ' +
              (this.props.display.size || 'normal') + ' ' +
              this.props.className}
            to={linkTo}>
            <div className='d-flex align-items-center'>
              {this.props.display.thumb && <div
                className={
                  'thumb d-flex mx-1 align-items-center justify-content-center' +
                 this.thumbnailClassname()
                }>
                {user.profile.thumb.s
                  ? <img src={user.profile.thumb.s} alt='thumb' className={'rounded w-100'} />
                  : <div
                    className='thumb-placeholder'>
                    {user.username.charAt(0).toUpperCase()}
                  </div>}
              </div>}
              {this.props.display.username && <span>
                {user.username}{isCurrentUser && ' (You)'}
                {(this.props.display.name && user.profile.name) &&
                <span className='d-block w-100 text-muted'>{user.profile.name}</span>}
              </span>}
              {this.props.actionButton && this.props.actionButton}
            </div>
          </WrapperTag>
      )
      : null
  }
}

UserShortcut.propTypes = {
  uuid: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  display: PropTypes.shape({
    thumb: PropTypes.bool.isRequired,
    username: PropTypes.bool.isRequired,
    tooltip: PropTypes.bool.isRequired,
    size: PropTypes.oneOf(['small', 'normal', 'large']).isRequired
  }).isRequired
}

UserShortcut.defaultProps = {
  disabled: false,
  display: {}
}

const mapStateToProps = ({ user, users }, ownProps) => {
  const isCurrentUser = ownProps.uuid === user.uuid
  return {
    isCurrentUser,
    user: isCurrentUser
      ? user
      : users.items.find(u => u.uuid === ownProps.uuid),
    listSyncing: users.syncing
  }
}
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncUser: () => dispatch(syncUser(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserShortcut)
