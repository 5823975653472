// import functors
import { tickets, ticket } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const TicketsMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'TICKETS') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'CREATE':
      chain(
        tickets.create(
          action.payload.project,
          action.payload.form,
          getState().user.uuid
        )
      )(dispatch)
      break
    case 'SYNC':
      chain(
        ticket.sync(
          action.payload.uuid,
          getState()
            .tickets
            .findIndex(item => item.uuid === action.payload.uuid)
        )
      )(dispatch)
      break
    case 'ASSIGN':
      chain([
        ticket.assign(
          action.payload.ticket,
          action.payload.user
        ),
        ticket.syncActivities(
          action.payload.ticket
        )
      ])(dispatch)
      break
    case 'UNASSIGN':
      chain([
        ticket.unassign(
          action.payload.ticket,
          action.payload.user
        ),
        ticket.syncActivities(
          action.payload.ticket
        )
      ])(dispatch)
      break
    case 'UPDATE_STATUS':
      chain(
        [
          ticket.updateStatus(
            action.payload.ticket,
            action.payload.status
          ),
          ticket.syncActivities(
            action.payload.ticket
          )
        ]
      )(dispatch)
      break
    case 'UPDATE_PROPERTIES':
      chain(
        [
          ticket.updateProperties(
            getState()
              .tickets
              .find(item => item.uuid === action.payload.ticket),
            action.payload.form
          ),
          ticket.syncActivities(
            action.payload.ticket
          )
        ]
      )(dispatch)
      break
    case 'COMMENT':
      chain(
        [
          ticket.comment(
            action.payload.ticket,
            action.payload.form
          ),
          ticket.syncActivities(
            action.payload.ticket
          )
        ]
      )(dispatch)
      break
    case 'SYNC_ACTIVITIES':
      chain(
        ticket.syncActivities(
          action.payload.uuid
        )
      )(dispatch)
      break
    case 'SYNC_FILES':
      chain(
        ticket.syncFiles(
          action.payload.uuid
        )
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default TicketsMiddleware
