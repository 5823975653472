import { createTicketObject } from '../../../objects/create'

import {
  setTicketSyncing,
  addTicket,
  updateTicket
} from '../../../actions/reducer/Ticket.Actions'

const sync = (uuid, index) => (dispatch, _Backend) => {
  // TODO: set syncing!
  // TODO: create at this point if no index!
  if (index > -1) {
    dispatch(setTicketSyncing(uuid, true))
  } else {
    dispatch(addTicket(createTicketObject({
      uuid,
      syncing: true
    })))
  }
  return _Backend.call('getTicket', uuid)
    .then((ticket) => {
      let item = createTicketObject(ticket)
      dispatch(updateTicket(uuid, item))
      return Promise.resolve()
    })
    .catch(err => {
      const errors = err.properties || []

      const item = createTicketObject({
        uuid,
        error: errors.length === 0 ? 'FAILED_TO_FETCH' : errors[0]
      })
      dispatch(updateTicket(uuid, item))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setTicketSyncing(uuid, false)))
}

export default sync
