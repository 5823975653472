import { createProjectObject } from '../../../objects/create'

import {
  addProjectItem,
  updateProjectItem,
  setProjectListSyncedAt,
  setProjectListSyncing
} from '../../../actions/reducer/Projects.Actions'

const getProjectList = (getState) => (dispatch, _Backend) => {
  // set app loading?
  const syncedAt = getState().projects.syncedAt
  dispatch(setProjectListSyncing(true))
  return _Backend.call('getProjectList', syncedAt || 0)
    .then(projects => {
      for (let project of projects) {
        let stateIndex = getState().projects.items.findIndex(item => item.uuid === project.uuid)
        let item = createProjectObject(project)
        dispatch(stateIndex > -1
          ? updateProjectItem(item)
          : addProjectItem(item)
        )
      }
      dispatch(setProjectListSyncedAt(Date.now()))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setProjectListSyncing(false)))
}

export default getProjectList
