const initialState = {
  syncedAt: null,
  syncing: false,
  error: false,
  items: []
}

const UsersReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USERS_ADD_ITEM':
      return state.items.some(user => user.uuid === action.payload.uuid)
        ? state
        : Object.assign(
          {},
          state,
          { items: [...state.items, action.payload] })
    case 'USERS_UPDATE_ITEM':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? {
              ...item,
              ...action.payload
            }
            : item
        ) })
    case 'USERS_REMOVE_ITEM':
      return Object.assign(
        {},
        state,
        { items: state.items.filter(item => item.uuid !== action.payload) }
      )
    case 'USERS_SET_USER_SYNCING':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? {
              ...item,
              syncing: action.payload.syncing
            }
            : item
        ) })
    case 'USERS_SET_SYNCED_AT':
      return Object.assign({}, state, { syncedAt: action.payload })
    case 'USERS_SET_SYNCING':
      return Object.assign({}, state, { syncing: action.payload })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default UsersReducer
