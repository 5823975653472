import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import {
  getSignature,
  deleteFile
} from '../../actions/middleware/Files.Actions'

import Button from './Button'
import TaskShortcut from './TaskShortcut'
import TicketShortcut from './TicketShortcut'

class FileListItem extends PureComponent {
  constructor () {
    super()

    this.state = { loading: false }

    this.handleDownload = this.handleDownload.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.download = this.download.bind(this)
  }

  handleDownload (e) {
    e.preventDefault()
    if (!this.props.signature && this.props.expiration <= Date.now()) {
      this.setState({ loading: true })
      this.props.getSignature()
    } else {
      this.download(this.props.baseURL + '?' + this.props.signature)
    }
  }

  download (signedURL) {
    const element = document.createElement('a')
    element.setAttribute('href', signedURL)
    element.setAttribute('target', '_blank')
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  handleDelete (e) {
    e.preventDefault()
    this.props.deleteFile()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.signature !== this.props.signature && this.state.loading) {
      this.setState({ loading: false })
      this.download(this.props.baseURL + '?' + this.props.signature)
    }
  }

  render () {
    const { currentUser } = this.props
    return (
      <div className='_filelist-item p-2 mb-2 bg-light border rounded shadow-sm'>
        <div style={{ wordWrap: 'break-word' }}>
          <ion-icon name='document-outline' />
          <i>{this.props.filename}</i>
        </div>
        {
          this.props.withLink && <>
            {this.props.task && <div> uploaded to <TaskShortcut uuid={this.props.task} /></div>}
            {this.props.ticket && <div> uploaded to <TicketShortcut uuid={this.props.ticket} /></div>}
          </>
        }
        <div className='w-100 d-flex justify-content-end'>
          <Button
            className='ml-3'
            color='default'
            size='small'
            onClick={this.handleDownload}
          >
              Download
          </Button>
          {(
            currentUser.uuid === this.props.createdBy ||
         (currentUser.role.group === 2 && currentUser.role.level > 2)
          ) && <Button
            className='ml-3'
            color='remove'
            size='small'
            onClick={this.handleDelete}
          >
            Delete
          </Button>}
        </div>

      </div>
    )
  }
}

const mapStateToProps = ({ user: { uuid, role } }, ownProps) => ({
  currentUser: { uuid, role }
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  getSignature: () => dispatch(getSignature(ownProps.uuid)),
  deleteFile: () => dispatch(deleteFile(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileListItem)
