export const setUser = user => ({
  type: 'USER_SET',
  payload: user
})

export const resetUser = () => ({
  type: 'USER_RESET'
})

export const updateUserProfile = (profileObj) => ({
  type: 'USER_UPDATE_PROFILE',
  payload: profileObj
})
