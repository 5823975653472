import {
  setFormSubmitting,
  setFormErrors,
  resetForm
} from '../../../actions/reducer/Forms.Actions'

import { push } from 'connected-react-router'

import {
  reset
} from '../../../actions/Global.Actions'

const updatePassword = ({ FormId, FormFields: { currentPassword, newPassword } }) => (dispatch, _Backend) => {
  dispatch(resetForm(FormId))
  dispatch(setFormSubmitting(FormId, true))

  return _Backend.call('updatePassword', { currentPassword, newPassword })
    .then(() => {
      // redirect to sign in
      dispatch(reset())
      dispatch(push('/auth/sign-in?feedback=password-updated'))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['TXT_UPDATE_PASSWORD_FORM__ERR_GENERIC']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          for (const error of errorArr) {
            errors.push('TXT_UPDATE_PASSWORD_FORM__' + error)
          }
          return errors
        }
      }
      dispatch(setFormErrors(FormId, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default updatePassword
