import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  updateProjectItem
} from '../../../actions/reducer/Projects.Actions'

import { push } from 'connected-react-router'

const updateProperties = (currentProject, updateProps) => (dispatch, _Backend) => {
  dispatch(setFormError([]))
  dispatch(setFormSubmitting(true))

  // check fields
  if (currentProject.name === updateProps.name) delete updateProps.name
  if (currentProject.description === updateProps.description) delete updateProps.description

  return _Backend.call('updateProjectProperties', currentProject.uuid, updateProps)
    .then(() => {
      // update state props
      dispatch(updateProjectItem({ uuid: currentProject.uuid, ...updateProps }))
      dispatch(push('/project/' + currentProject.uuid + '/settings'))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default updateProperties
