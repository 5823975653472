const defaultObject = {
  uuid: '',
  username: '',
  email: '',
  status: 1,
  role: {
    group: 0,
    level: 0
  },
  profile: {
    name: '',
    phone: '',
    thumb: {
      s: '',
      n: ''
    },
    company: '', // TODO: db
    position: '' // TODO: db
  },
  billing: {}, // billing data maybe later
  error: '',
  syncedAt: Date.now(),
  syncing: false
}

export const createUserObject = entity => {
  return Object.assign(
    {},
    defaultObject,
    entity
  )
}
