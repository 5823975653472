import { sprintf } from 'sprintf-js'
import { CONST_APP_DEFAULT_TRANSLATION } from '../../constants/App.Constants'

import en from './en'
import hu from './hu'

const _languages = {
  en,
  hu
}
const translate = (lang, txtid, ...bind) => {
  let template = _languages[lang][txtid] || _languages[CONST_APP_DEFAULT_TRANSLATION.flag][txtid]
  if (typeof template === 'function') {
    return template(...bind)
  } else {
    return sprintf(template, ...bind)
  }
}

export default translate
