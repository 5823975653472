
import SyncList from './SyncList'
import SyncGroup from './SyncGroup'
import Create from './Create'
import Update from './Update'
import Delete from './Delete'
import AddMember from './AddMember'
import RemoveMember from './RemoveMember'

const groups = API => {
  return {
    syncList: (...params) => dispatch => SyncList(...params)(dispatch, API),
    syncGroup: (...params) => dispatch => SyncGroup(...params)(dispatch, API),
    create: (...params) => dispatch => Create(...params)(dispatch, API),
    update: (...params) => dispatch => Update(...params)(dispatch, API),
    delete: (...params) => dispatch => Delete(...params)(dispatch, API),
    addMember: (...params) => dispatch => AddMember(...params)(dispatch, API),
    removeMember: (...params) => dispatch => RemoveMember(...params)(dispatch, API)
  }
}

export default groups
