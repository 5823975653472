export const CONST_PROJECT_STATUSES = Object.freeze([
  {
    id: 0,
    label: 'Hidden',
    actions: {
      task: {
        add: true,
        modify: true,
        remove: true,
        assign: false,
        comment: false
      },
      ticket: null
    }
  },
  {
    id: 1,
    label: 'Open',
    actions: {
      task: {
        add: true,
        modify: true,
        remove: true,
        assign: false,
        comment: false
      },
      ticket: null
    }
  },
  {
    id: 2,
    label: 'Maintained',
    actions: {
      task: null,
      ticket: {
        add: false,
        modify: false,
        remove: false,
        assign: false,
        comment: false
      }
    }
  },
  {
    id: 3,
    label: 'Archived',
    actions: {
      task: null,
      ticket: null
    }
  }
])

export const CONST_PROJECT_VIEW_AS_OPTIONS = Object.freeze([
  {
    id: 'TABLE',
    matchParam: 'table',
    label: 'Table'
  },
  {
    id: 'GRID',
    matchParam: 'grid',
    label: 'Grid (blocks)'
  }
])

// TODO: created order, modified order

export const CONST_PROJECT_ORDERS = Object.freeze([
  {
    name: 'NAME_ASC',
    label: 'Name A-Z',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let nameA = a.title.toUpperCase()
        let nameB = b.title.toUpperCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
        return 0
      })
    }
  },
  {
    name: 'NAME_DESC',
    label: 'Name Z-A',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let nameA = a.title.toUpperCase()
        let nameB = b.title.toUpperCase()
        if (nameA > nameB) {
          return -1
        } else if (nameA < nameB) {
          return 1
        }
        return 0
      })
    }
  },
  {
    name: 'ID_ASC',
    label: 'ID ascendant',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let propA = Number(a.id)
        let propB = Number(b.id)
        if (propA < propB) {
          return -1
        } else if (propA > propB) {
          return 1
        }
        return 0
      })
    }
  },
  {
    name: 'ID_DESC',
    label: 'ID decrescent',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let propA = Number(a.id)
        let propB = Number(b.id)
        if (propA > propB) {
          return -1
        } else if (propA < propB) {
          return 1
        }
        return 0
      })
    }
  }
])

export const CONST_PROJECT_NUMBER_IN_PAGE = 24
