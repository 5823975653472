import React from 'react'
import { Link } from 'react-router-dom'

const NoMatch = props => {
  return (
    <>
      <h3>Project System</h3>
      <h2>404 - Not Found</h2>
      <Link to='/'>Back to Dashboard</Link>
    </>
  )
}

export default NoMatch
