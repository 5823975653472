export const CONST_ROLE_GROUPS = Object.freeze([
  {
    id: 1,
    label: 'Customers',
    levels: [
      // { id: 0, label: 'Observer' }, later maybe
      { id: 1, label: 'Customer' }
    ]
  },
  {
    id: 2,
    label: 'Coworkers',
    levels: [
      { id: 1, label: 'Coworker' },
      { id: 2, label: 'Project manager' },
      { id: 3, label: 'Maintainer' },
      { id: 4, label: 'Admin' }
    ]
  }
])
