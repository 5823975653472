import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers/Root'

import AuthMiddleware from './middlewares/AuthMiddleware'
import AccountMiddleware from './middlewares/AccountMiddleware'
import ProjectsMiddleware from './middlewares/ProjectsMiddleware'
import UsersMiddleware from './middlewares/UsersMiddleware'
import GroupsMiddleware from './middlewares/GroupsMiddleware'
import TasksMiddleware from './middlewares/TasksMiddleware'
import TicketsMiddleware from './middlewares/TicketsMiddleware'
import FilesMiddleware from './middlewares/FilesMiddleware'

export const history = createBrowserHistory()

const configureStore = () => {
  const store = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(
        routerMiddleware(history),
        AuthMiddleware,
        AccountMiddleware,
        ProjectsMiddleware,
        UsersMiddleware,
        GroupsMiddleware,
        TasksMiddleware,
        TicketsMiddleware,
        FilesMiddleware
      )
    )
  )

  return store
}

export default configureStore
