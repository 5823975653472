import React, { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap'

const DeleteModal = props => {
  const {
    buttonLabel,
    actionHandler
  } = props

  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <Button color='danger' onClick={toggle}>{buttonLabel}</Button>
      <Modal isOpen={modal} keyboard toggle={toggle}>
        <ModalBody>
          Delete group, are you sure?
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={actionHandler}>Yes, delete</Button>{' '}
          <Button color='secondary' onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default DeleteModal
