const initialState = {
  syncedAt: null,
  syncing: false,
  error: false,
  items: []
}

const ProjectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'PROJECTS_ADD_ITEM':
      return state.items.some(el => el.uuid === action.payload.uuid)
        ? state
        : Object.assign(
          {},
          state,
          { items: [...state.items, action.payload] })
    case 'PROJECTS_UPDATE_ITEM':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? { ...item, ...action.payload }
            : item
        ) })
    case 'PROJECTS_SET_ITEM_SYNCING':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? { ...item, syncing: action.payload.syncing }
            : item
        ) })
    case 'PROJECTS_SET_ITEM_TASKS_SYNCING':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? { ...item,
              tasks: {
                ...item.tasks,
                syncing: action.payload.syncing,
                syncedAt: action.payload.syncing
                  ? item.tasks.syncedAt
                  : Date.now() } }
            : item
        ) })
    case 'PROJECTS_SET_ITEM_TICKETS_SYNCING':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? { ...item,
              tickets: {
                ...item.tickets,
                syncing: action.payload.syncing,
                syncedAt: action.payload.syncing
                  ? item.tickets.syncedAt
                  : Date.now() } }
            : item
        ) })
    case 'PROJECTS_SET_ITEM_FILES_SYNCING':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? { ...item,
              files: {
                ...item.files,
                syncing: action.payload.syncing,
                syncedAt: action.payload.syncing
                  ? item.files.syncedAt
                  : Date.now() } }
            : item
        ) })
    case 'PROJECTS_REMOVE_ITEM':
      return Object.assign(
        {},
        state,
        { items: state.items.filter(item => item.uuid !== action.payload) }
      )
    case 'PROJECTS_SET_SYNCED_AT':
      return Object.assign({}, state, { syncedAt: action.payload })
    case 'PROJECTS_SET_SYNCING':
      return Object.assign({}, state, { syncing: action.payload })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default ProjectsReducer
