import {
  createPhaseObject
} from '../../../objects/create'

import {
  updateProjectItem
} from '../../../actions/reducer/Projects.Actions'

import {
  addPhase,
  updatePhase
} from '../../../actions/reducer/Phases.Actions'

const phases = (projectUuid, statePhases) => (dispatch, _Backend) => {
  // TODO: update project phases syncing flag
  dispatch(updateProjectItem({ uuid: projectUuid, phases: { syncing: true } }))
  return _Backend.call('listPhases', projectUuid)
    .then(({ phases }) => {
      for (let p of phases) {
        let stateIndex = statePhases.findIndex(sp => sp.uuid === p.uuid)
        p.project = projectUuid
        let item = createPhaseObject(p)
        dispatch(stateIndex > -1
          ? updatePhase(item)
          : addPhase(item)
        )
      }
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(updateProjectItem({ uuid: projectUuid, phases: { syncing: false, syncedAt: Date.now() } })))
}

export default phases
