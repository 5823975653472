import { createFormObject } from '../objects/Form.Object'

const initialState = []

const FormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FORMS_INITIALIZE':
      return state.some(el => el.id === action.payload)
        ? state
        : [...state, createFormObject({ id: action.payload })]
    case 'FORMS_DESTROY':
      return state.filter(el => el.id !== action.payload)
    case 'FORMS_SET_SUBMITTING':
      return state.map(el => {
        if (el.id === action.payload.id) {
          return Object.assign(
            {},
            el,
            { submitting: action.payload.submitting }
          )
        } else {
          return el
        }
      })
    case 'FORMS_SET_PROPERTIES':
      return state.map(el => {
        if (el.id === action.payload.id) {
          return Object.assign(
            {},
            el,
            action.payload.props
          )
        } else {
          return el
        }
      })
    case 'FORMS_SET_ERRORS':
      return state.map(el => {
        if (el.id === action.payload.id) {
          return Object.assign(
            {},
            el,
            { errors: action.payload.errors }
          )
        } else {
          return el
        }
      })
    case 'FORMS_SET_MESSAGE':
      return state.map(el => {
        if (el.id === action.payload.id) {
          return Object.assign(
            {},
            el,
            { message: action.payload.message }
          )
        } else {
          return el
        }
      })
    case 'FORMS_RESET':
      return state.map(el => {
        if (el.id === action.payload) {
          return createFormObject({ id: el.id })
        } else {
          return el
        }
      })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default FormsReducer
