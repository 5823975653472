import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncPhases } from '../../../../actions/middleware/Projects.Actions'

import { Link } from 'react-router-dom'

import {
  Row,
  Col
} from 'reactstrap'

const compare = (a, b) => {
  const pA = a.createdAt
  const pB = b.createdAt

  let comparison = 0
  if (pA > pB) {
    comparison = 1
  } else if (pA < pB) {
    comparison = -1
  }
  return comparison
}

class List extends Component {
  render () {
    // TODO: no render if not synced
    return (
      <Row>
        <Col>
          <h3>Phases list</h3>
          <ul>
            {this.props.phases
              .sort(compare)
              .map((p, index) =>
                <li key={p.uuid}>
                  <Link
                    to={this.props.location.pathname + '/' + p.uuid}
                  >
                    {index + 1}.:{p.name}
                  </Link>
                </li>)}
          </ul>

        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ projects, phases }, ownProps) => {
  return {
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid),
    phases: phases
      .filter(p => p.project === ownProps.match.params.projectUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncPhases: () => dispatch(syncPhases(ownProps.match.params.projectUuid))
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { project, phases } = propsFromState
  let syncing = project ? project.phases.syncing : false
  let syncedAt = project ? project.phases.syncedAt : Date.now() - 10

  return Object.assign({},
    ownProps,
    propsFromDispatch,
    {
      syncing,
      syncedAt,
      phases
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(List)
