import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncTicketActivities } from '../../../../actions/middleware/Tickets.Actions'

// import UserShortcut from '../../../common/UserShortcut'

import Activity from '../../../common/Activity'

class Activities extends Component {
  constructor () {
    super()

    this.step = 5

    this.state = {
      limit: 5
    }
  }

  componentDidMount () {
    if (this.props.syncedAt + (10 * 1000) < Date.now() && !this.props.syncing) {
      this.props.syncTicketActivities()
    }
  }

  componentDidUpdate (prevProps, prevState) {

  }

  render () {
    return (<div>
      <h3>Activities</h3>
      {this.props.activities.length > this.state.limit &&
        <>
          <button onClick={() => this.setState({ limit: this.state.limit + this.step })}>Show previous activities</button>
          Showing {this.state.limit} of {this.props.activities.length} activities
        </>
      }
      {this.props.activities
        .sort((a, b) => (a.triggeredAt > b.triggeredAt) ? 1 : -1)
        .map((act, index, arr) => {
          if (arr.length - this.state.limit < index + 1) {
            return <Activity
              key={act.uuid}
              scope='ticket'
              {...act}
            />
          }
          return null
        }
        )
      }
    </div>)
  }
}

const mapStateToProps = ({ activities }, ownProps) => {
  return {
    activities: activities
      .filter(a => a.ticket === ownProps.uuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const ticket = ownProps.uuid
  return {
    syncTicketActivities: () => dispatch(syncTicketActivities(ticket))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activities)
