import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  initializeForm,
  destroyForm
} from '../../actions/reducer/Forms.Actions'

import { generateId } from './Utils'

class FormStateWrapper extends Component {
  constructor () {
    super()
    this.id = generateId()
  }

  componentDidMount () {
    this.props.initializeForm(this.id)
  }

  componentWillUnmount () {
    this.props.destroyForm(this.id)
  }

  render () {
    const {
      forms,
      initializeForm,
      destroyForm,
      component: C,
      ...rest
    } = this.props
    const form = forms.find(u => u.id === this.id)
    return form
      ? <C form={form} {...rest} />
      : null
  }
}

const mapStateToProps = ({ forms }, ownProps) => ({
  forms
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  initializeForm: (id) => dispatch(initializeForm(id)),
  destroyForm: (id) => dispatch(destroyForm(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormStateWrapper)
