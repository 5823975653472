import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Breadcrumbs from '../../../common/Breadcrumbs'
import FormStateWrapper from '../../../common/FormStateWrapper'

import CreateGroup from './components/CreateGroup'

const Create = props => (
  <Container>
    <Row>
      <Col>
        <Breadcrumbs items={[
          { label: 'Community', link: '/community' },
          { label: 'Groups', link: '/community/groups' },
          { label: 'Create group' }
        ]} />
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 6, offset: 3 }}>
        <h3>Create new group</h3>
        <FormStateWrapper
          component={CreateGroup}
        />
      </Col>
    </Row>
  </Container>
)

export default Create
