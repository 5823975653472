
import Sync from './Sync'
import GetSignature from './GetSignature'
import SyncList from './SyncList'
import Upload from './Upload'
import Delete from './Delete'

const files = API => {
  return {
    sync: (...params) => dispatch => Sync(...params)(dispatch, API),
    sign: (...params) => dispatch => GetSignature(...params)(dispatch, API),
    syncList: (...params) => dispatch => SyncList(...params)(dispatch, API),
    upload: (...params) => dispatch => Upload(...params)(dispatch, API),
    delete: (...params) => dispatch => Delete(...params)(dispatch, API)
  }
}

export default files
