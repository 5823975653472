import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncUser, updateRoleGroup, updateRoleLevel } from '../../../actions/middleware/Users.Actions'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import ManageGroup from './components/ManageGroup'
import ManageLevel from './components/ManageLevel'

import NoMatch from '../noMatch/NoMatch'

class Manage extends Component {
  componentDidMount () {
    if (!this.props.user ||
      (this.props.user.syncedAt + (10 * 1000) < Date.now() && !this.props.user.syncing)) {
      this.props.syncUser()
    }
  }

  render () {
    let existInState = !!this.props.user
    return existInState
      ? (
        this.props.user.error === 'NO_MATCH'
          ? <NoMatch />
          : (!this.props.user.syncing && <Container>
            <Row>
              <Col>
                <h5>Manage user: {this.props.user.username}
                  <small>(role: {this.props.user.role.group}.{this.props.user.role.level})</small>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Update role group and level</h6>
                <ManageGroup
                  group={this.props.user.role.group}
                  update={this.props.updateRoleGroup} />
                <ManageLevel
                  role={this.props.user.role}
                  update={this.props.updateRoleLevel} />
              </Col>
            </Row>
            <Row>
              <Col>
                <h6>Block user</h6>
                TODO: block user
              </Col>
            </Row>
          </Container>)
      ) : 'not exist yet in state or syncing, show loading'
  }
}

const mapStateToProps = ({ users }, ownProps) => {
  return {
    user: users.items.find(u => u.uuid === ownProps.match.params.userUuid)
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncUser: () => dispatch(syncUser(ownProps.match.params.userUuid)),
  updateRoleGroup: (role) => dispatch(updateRoleGroup(ownProps.match.params.userUuid, role)),
  updateRoleLevel: (level) => dispatch(updateRoleLevel(ownProps.match.params.userUuid, level))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Manage)
