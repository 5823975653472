import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import {
  Row,
  Col
} from 'reactstrap'

class Profile extends Component {
  componentDidMount () {
    // console.log('profile route')
  }

  render () {
    const { user } = this.props
    return (
      <Row>
        <Col sm={2}>
          {this.props.user.profile.thumb.n &&
          <img src={this.props.user.profile.thumb.n} alt='thumb' className='w-100 rounded' />}
        </Col>
        <Col>
          <h3>Profile</h3>
          <h4>{this.props.user.username}
            <small>(role: {user.role.group}.{user.role.level})</small>
          </h4>
          <Link to='/account/profile/update'>update</Link>
          Name: {user.profile.name || <i>Not set</i>}
          Phone: {user.profile.phone || <i>Not set</i>}
            TODO: position, workplace, contact email

          {
            // JSON.stringify(this.props.user)
          }
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ user }, ownProps) => ({
  user
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
