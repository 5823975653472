
import qs from 'query-string'

import {
  setFormSubmitting,
  setFormErrors
} from '../../../actions/reducer/Forms.Actions'
import { push } from 'connected-react-router'

const createAccount = ({
  FormId,
  FormFields: { username, password }
}, query) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(FormId, true))
  let { request } = qs.parse(query)

  return _Backend.call('createAccount',
    {
      hash: request,
      username,
      password
    }
  )
    .then(() => {
      dispatch(push('/auth/sign-in?feedback=account-created'))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['TXT_CREATE_ACCOUNT_FORM__ERR_GENERIC']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          for (const error of errorArr) {
            errors.push('TXT_CREATE_ACCOUNT_FORM__' + error)
          }
          return errors
        }
      }
      dispatch(setFormErrors(FormId, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default createAccount
