export const setFormSubmitting = isSubmitting => ({
  type: 'FORM_SET_SUBMITTING',
  payload: isSubmitting
})

export const setFormError = error => ({
  type: 'FORM_SET_ERRORS',
  payload: error
})

export const resetForm = () => ({
  type: 'FORM_RESET'
})
