import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncPhases } from '../../../../actions/middleware/Projects.Actions'
import { createTask } from '../../../../actions/middleware/Tasks.Actions'
import { resetForm } from '../../../../actions/reducer/Form.Actions'

// TODO: import only what we need
import { CONST_TASK_PRIORITIES } from '../../../../constants/Constants'

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  FormText,
  Alert,
  Badge
} from 'reactstrap'

class AddTask extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      description: '',
      estimatedWork: 0,
      phase: '',
      priority: 0,
      isValidName: false,
      isValidDescription: false,
      phasesLoaded: false
    }

    this._priorityBadge = this._priorityBadge.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangePriority = this.handleChangePriority.bind(this)
    this.handleChangePhase = this.handleChangePhase.bind(this)
    this.handleChangeEstimatedWork = this.handleChangeEstimatedWork.bind(this)
  }

  _priorityBadge (priority) {
    const { label } = CONST_TASK_PRIORITIES.find(pr => pr.id === priority)
    return (
      <Badge className={`mr-2 _taskpriority-${priority}`}>
        {label}
      </Badge>
    )
  }

  handleSubmit (e) {
    e.preventDefault()
    let {
      name,
      description,
      estimatedWork,
      phase,
      priority
    } = this.state
    this.props.createTask({
      name,
      description,
      estimatedWork,
      phase,
      priority
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangeDescription (e) {
    let description = e.target.value
    this.setState({
      description,
      isValidDescription: !!description
    })
  }

  handleChangePriority (e) {
    let priority = Number(e.target.value)
    this.setState({
      priority
    })
  }

  handleChangePhase (e) {
    let phase = e.target.value
    this.setState({
      phase
    })
  }

  handleChangeEstimatedWork (e) {
    let estimatedWork = Number(e.target.value)
    this.setState({
      estimatedWork
    })
  }

  componentDidMount () {
    if (!this.props.phasesSyncing || this.props.phasesSyncedAt + (10 * 1000) < Date.now()) {
      this.props.syncPhases()
    } else {
      this.setState({ phase: this.props.phases[0].uuid })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // handle newly received phase list
    if (prevProps.phasesSyncing && !this.props.phasesSyncing) {
      this.setState({ phase: this.props.phases[0].uuid })
    }
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  render () {
    const { isValidName, isValidDescription } = this.state
    const canSubmit = isValidName && isValidDescription && !this.props.phasesSyncing
    return (
      <Col md={{ size: 6, offset: 3 }}>
        <h5>Create new task</h5>
        <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
          {this.props.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.errors.map(el => el).join(' ')}
          </Alert>}
          <FormGroup>
            <Label for='phase-name'>Task name</Label>
            <Input
              type='text'
              maxLength={128}
              name='phase-name'
              id='phase-name'
              tabIndex={1}
              placeholder='Task name'
              disabled={this.props.submitting}
              invalid={!!this.state.name && !isValidName}
              onChange={this.handleChangeName}
              required />
            <FormText color='muted'>
                  128/{this.state.name.length}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='project-description'>Description</Label>
            <Input
              type='textarea'
              maxLength={10000}
              name='project-description'
              id='project-description'
              tabIndex={2}
              placeholder='description'
              invalid={!!this.state.description && !isValidDescription}
              onChange={this.handleChangeDescription}
              disabled={this.props.submitting}
              required />
            <FormText color='muted'>
              10000/{this.state.description.length}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='task-priority'>Priority</Label>
            <div>
              {CONST_TASK_PRIORITIES
                .map(pri => <CustomInput
                  key={pri.id}
                  type='radio'
                  value={pri.id}
                  id={'task-priority-' + pri.id}
                  onChange={this.handleChangePriority}
                  defaultChecked={pri.id === 0}
                  name='task-priority'
                  label={this._priorityBadge(pri.id)} />)}
            </div>
            <FormText color='muted'>
              Select priority.
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='estimated-work'>Estimated work (in hours, 0 means not set)</Label>
            <Input
              type='number'
              max={200}
              min={0}
              name='estimated-work'
              id='estimated-work'
              tabIndex={1}
              defaultValue={0}
              placeholder='(hours)'
              disabled={this.props.submitting}
              onChange={this.handleChangeEstimatedWork}
            />
            <FormText color='muted'>
                  128/{this.state.name.length}
            </FormText>
          </FormGroup>
          {!this.props.phasesSyncing && <FormGroup>
            <Label for='project-description'>Phase</Label>
            <div>
              {this.props.phases
                .map(phase => <CustomInput
                  type='radio'
                  key={phase.uuid}
                  id={'phase-' + phase.uuid}
                  value={phase.uuid}
                  defaultChecked={phase.uuid === this.props.phases[0].uuid}
                  name='project-phase'
                  onChange={this.handleChangePhase}
                  label={phase.name} />)}
            </div>
            <FormText color='muted'>
              Select a phase
            </FormText>
          </FormGroup>}
          <Button color='primary'
            type='submit'
            tabIndex={3}
            disabled={!canSubmit || this.props.submitting}
          >
            Create new task
          </Button>
        </Form>
      </Col>
    )
  }
}

const mapStateToProps = ({ form, projects, phases }, ownProps) => ({
  project: projects
    .items.find(pr => pr.uuid === ownProps.match.params.projectUuid),
  phases: phases
    .filter(p => p.project === ownProps.match.params.projectUuid),
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  createTask: form => dispatch(createTask(
    ownProps.match.params.projectUuid,
    form)),
  syncPhases: () => dispatch(syncPhases(ownProps.match.params.projectUuid)),
  resetForm: () => dispatch(resetForm())
})
const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { project, phases, submitting, errors } = propsFromState
  let phasesSyncing = project ? project.phases.syncing : false
  let phasesSyncedAt = project ? project.phases.syncedAt : Date.now() - 10

  return Object.assign({},
    ownProps,
    propsFromDispatch,
    {
      phasesSyncing,
      phasesSyncedAt,
      phases,
      submitting,
      errors
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(AddTask)
