const defaultObject = {
  uuid: '',
  project: '',
  task: '',
  ticket: '',
  triggeredAt: 0,
  triggeredBy: '',
  changelog: {}
}

export const createActivityObject = entity => {
  return Object.assign(
    {},
    defaultObject,
    entity
  )
}
