import { createUserObject } from '../../../objects/create'

import {
  setUserSyncing,
  addUser,
  updateUser
} from '../../../actions/reducer/Users.Actions'

// test
import Backend from '../Backend'
const apiURL = 'https://api.infinite-edu.hu'

const _Backend = new Backend(apiURL)

const syncUser = (uuid, index) => (dispatch, API) => {
  // TODO: set syncing!
  // TODO: create at this point if no index!
  if (index > -1) {
    dispatch(setUserSyncing(uuid, true))
  } else {
    dispatch(addUser(createUserObject({
      uuid,
      syncing: true
    })))
  }

  _Backend.session = API.session
  _Backend.access = API.access

  // return API.getUser(uuid)
  return _Backend.call('getUser', uuid)
    .then((user) => {
      let item = createUserObject(user)
      dispatch(updateUser(item))
      return Promise.resolve()
    })
    .catch(err => {
      const errors = err.properties || []

      const item = createUserObject({
        uuid,
        error: errors.length === 0 ? 'FAILED_TO_FETCH' : errors[0]
      })
      dispatch(updateUser(item))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setUserSyncing(uuid, false)))
}

export default syncUser
