import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updatePassword } from '../../../../../actions/middleware/Account.Actions'

import {
  Form,
  FormGroup,
  Col,
  Label,
  Input,
  Alert,
  FormText,
  Modal, ModalBody, ModalFooter
} from 'reactstrap'

import { isPassword } from '../../../../common/Utils'
import translate from '../../../../../utils/translations/Translate'
import Button from '../../../../common/Button'

import Translated from '../../../../common/Translated'

class UpdatePassword extends Component {
  constructor () {
    super()

    this.state = {
      currentPassword: '',
      newPassword: '',
      retypePassword: '',
      modal: false,
      isValidCurrentPassword: false,
      isValidNewPassword: false
    }
    this.toggle = this.toggle.bind(this)

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this)
    this.handleChangeRetype = this.handleChangeRetype.bind(this)
  }

  toggle (e) {
    e.preventDefault()
    this.setState({ modal: !this.state.modal })
  }

  handleSubmit (e) {
    e.preventDefault()
    let { currentPassword, newPassword } = this.state
    this.props.updatePassword({
      FormId: this.props.form.id,
      FormFields: { currentPassword, newPassword }
    })
    this.setState({ modal: false })
  }

  handleChangePassword (e) {
    let currentPassword = e.target.value
    this.setState({
      currentPassword,
      isValidCurrentPassword: isPassword(currentPassword)
    })
  }

  handleChangeNewPassword (e) {
    let newPassword = e.target.value
    this.setState({
      newPassword,
      isValidNewPassword: isPassword(newPassword)
    })
  }

  handleChangeRetype (e) {
    let retypePassword = e.target.value
    this.setState({
      retypePassword
    })
  }

  render () {
    const { newPassword, retypePassword, isValidCurrentPassword, isValidNewPassword } = this.state
    const { language } = this.props
    const canSubmit = isValidCurrentPassword && isValidNewPassword && (newPassword === retypePassword)
    return (
      <Form
        onSubmit={this.toggle}
        className='bg-white my-4 p-3'>
        <h4 className='mb-3'>
          <Translated txtid='TXT_UPDATE_PASSWORD_FORM__HEADING' />
        </h4>
        {this.props.form.errors.length > 0 &&
          <Alert color='danger'>
            {this.props.form.errors
              .map((el, i) =>
                <span key={i}><Translated txtid={el} />{' '}</span>
              )
            }
          </Alert>}
        <FormGroup row>
          <Label for='password-current' md={4} lg={3}>
            <Translated txtid='TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_CURRENT_PASSWORD' />
          </Label>
          <Col md={8} lg={9}>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password-current'
              id='password-current'
              tabIndex={2}
              placeholder={translate(language, 'TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_CURRENT_PASSWORD')}
              invalid={!!this.state.currentPassword && !isValidCurrentPassword}
              onChange={this.handleChangePassword}
              disabled={this.props.form.submitting}
              required />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='password-new' md={4} lg={3}>
            <Translated txtid='TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_NEW_PASSWORD' />
          </Label>
          <Col md={8} lg={9}>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password-new'
              id='password-new'
              tabIndex={2}
              placeholder={translate(language, 'TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_NEW_PASSWORD')}
              invalid={!!this.state.newPassword && !isValidNewPassword}
              onChange={this.handleChangeNewPassword}
              disabled={this.props.form.submitting}
              required />
            <FormText>
              <Translated txtid='TXT_UPDATE_PASSWORD_FORM__TOOLTIP_PASSWORD' />
            </FormText>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='password-v' md={4} lg={3}>
            <Translated txtid='TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_RETYPE_PASSWORD' />
          </Label>
          <Col md={8} lg={9}>
            <Input
              type={this.state.passwordVisible ? 'text' : 'password'}
              name='password-v'
              id='password-v'
              tabIndex={3}
              placeholder={translate(language, 'TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD')}
              invalid={newPassword !== retypePassword}
              onChange={this.handleChangeRetype}
              disabled={this.props.form.submitting}
              required />
          </Col>
        </FormGroup>
        <div className='d-flex w-100 justify-content-center'>
          <Button
            className={this.props.form.submitting && 'submitting'}
            color='add'
            type='submit'
            tabIndex={4}
            disabled={!canSubmit || this.props.form.submitting}
          >
            <Translated txtid='TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_SEND' />
          </Button>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop={'static'} keyboard>
          <ModalBody>
              Ezzel minden jelenlegi munkafolyamatod meg fog szűnni és újra be kell jelentkezned.
          </ModalBody>
          <ModalFooter className='justify-content-center'>
            <Button
              color='add'
              onClick={this.handleSubmit}>
              <Translated txtid='TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_MODAL_OK' />
            </Button>
            <Button
              color='default'
              onClick={this.toggle}>
              <Translated txtid='TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_MODAL_CANCEL' />
            </Button>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }
}

const mapStateToProps = ({ app: { language } }) => ({
  language
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  updatePassword: form => dispatch(updatePassword(form))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdatePassword)
