import React from 'react'

import {
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'

import { Link } from 'react-router-dom'

const Breadcrumbs = ({ items }) => (
  <Breadcrumb className='bg-light rounded'>
    {
      items.map(({ label, link }, index) =>
        <BreadcrumbItem
          key={index}
          active={index === items.length - 1}>
          {index < items.length - 1
            ? <Link to={link}>{label}</Link>
            : label}
        </BreadcrumbItem>
      )
    }
  </Breadcrumb>
)

Breadcrumbs.defaultProps = {
  items: []
}

export default Breadcrumbs
