import Backend from './Backend'

import Auth from './auth/Auth'
import Account from './account/Index'
import Project from './project/Index'
import Projects from './projects/Index'
import Users from './users/Index'
import Groups from './groups/Index'
import Tasks from './tasks/Index'
import Task from './task/Index'
import Tickets from './tickets/Index'
import Ticket from './ticket/Index'
import Files from './files/Index'

const apiURL = 'https://api.infinite-edu.hu'
const _Backend = new Backend(apiURL)

export const auth = Auth(_Backend)
export const account = Account(_Backend)
export const project = Project(_Backend)
export const projects = Projects(_Backend)
export const users = Users(_Backend)
export const groups = Groups(_Backend)
export const tasks = Tasks(_Backend)
export const task = Task(_Backend)
export const tickets = Tickets(_Backend)
export const ticket = Ticket(_Backend)
export const files = Files(_Backend)
