import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { syncAllActivities } from '../../../actions/middleware/Projects.Actions'

import Activity from '../../common/Activity'
import Button from '../../common/Button'
import Project from './components/Project'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

class Dashboard extends Component {
  componentDidMount () {
    this.props.syncAllActivities()
  }

  render () {
    return (
      <Container>
        <Row>
          <Col>
            <b>Pinned items</b>
          </Col>
        </Row>
        <Row>
          <Col md={4} className='bg-light'>
            <Row>
              <Col>
                <Row>
                  <Col className='d-flex'>
                    <Button
                      tag={Link}
                      size='normal'
                      color='add'
                      className='my-3 mr-3'
                      to='/projects/create'>
                      Create project
                    </Button>
                  </Col>
                </Row>
                <b>Recent projects</b>
                <div className='border-bottom'>
                  {this.props.projects
                    .map(item => <Project key={item.uuid} data={item} />)
                  }
                </div>
                <Row>
                  <Col className='d-flex'>
                    <Button
                      tag={Link}
                      size='small'
                      className='my-3 mr-3'
                      to='/projects'>
                      Go to projects
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <b>Recent tasks</b>
              </Col>
            </Row>
          </Col>
          <Col md={8} className='border-left'>
            <b>Recent activities</b>
            {this.props.activities
              .sort((a, b) => (a.triggeredAt < b.triggeredAt) ? 1 : -1)
              .map(act => <Activity
                key={act.uuid}
                scope={'root'}
                {...act} />)}
          </Col>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = ({ app: { dashboard }, activities, projects }) => ({
  dashboard,
  activities: activities
    .sort((a, b) => (a.triggeredAt < b.triggeredAt) ? 1 : -1)
    .filter((i, index) => (index < 30)),
  projects: projects.items
    .sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : -1)
    .filter((i, index) => (index < 5))
})

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch) => ({
  syncAllActivities: () => dispatch(syncAllActivities())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
