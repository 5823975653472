import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncTickets } from '../../../../actions/middleware/Projects.Actions'

import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Progress
} from 'reactstrap'

import Button from '../../../common/Button'
import Ticket from './components/Ticket'

const compare = (a, b) => {
  const pA = a.updatedAt
  const pB = b.updatedAt

  let comparison = 0
  if (pA < pB) {
    comparison = 1
  } else if (pA > pB) {
    comparison = -1
  }
  return comparison
}

class List extends Component {
  constructor () {
    super()

    this._countTicketByStatus = this._countTicketByStatus.bind(this)
  }

  _countTicketByStatus (status, percent = true) {
    const tickets = this.props.tickets
    return percent
      ? tickets.filter(t => t.status === status).length / tickets.length * 100
      : tickets.filter(t => t.status === status).length
  }

  componentDidMount () {
    if (!this.props.syncing || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncTickets()
    }
  }

  render () {
    return (
      <Row>
        <Col>
          <Row className='my-2'>
            <Col>
              {this.props.tickets.length > 0 &&
              <Progress style={{ height: '0.3rem' }} multi>
                <Progress bar className='_ticketstatus-0 progressvalue' value={
                  this._countTicketByStatus(0) +
                  this._countTicketByStatus(1) +
                  this._countTicketByStatus(2)
                } />
                <Progress bar className='_ticketstatus-4 progressvalue' value={
                  this._countTicketByStatus(3) +
                  this._countTicketByStatus(4)
                } />
              </Progress>}
            </Col>
          </Row>
          <Row className='pb-2'>
            <Col className='d-flex justify-content-end align-items-center'>
              <Button
                size='normal'
                color='default'
                className='mr-3'
                onClick={this.props.syncTickets}
              >
                Force sync
              </Button>
              {this.props.projectStatus === 2 && <Button
                tag={Link}
                size='normal'
                color='add'
                to={`/project/${this.props.match.params.projectUuid}/tickets/add`}
              >
                Submit new ticket
              </Button>}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className='bg-light border-top'>
                filters, search
              </div>
            </Col>
          </Row>
          {this.props.tickets
            .sort(compare)
            .map(ticket => <Ticket key={ticket.uuid} data={ticket} />)}
          <Row>
            <Col>
              pagination
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ projects, tickets }, ownProps) => {
  return {
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid),
    tickets: tickets
      .filter(p => p.project === ownProps.match.params.projectUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTickets: () => dispatch(syncTickets(ownProps.match.params.projectUuid))
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { project, tickets } = propsFromState
  let syncing = project ? project.tasks.syncing : false
  let syncedAt = project ? project.tasks.syncedAt : Date.now() - 10
  let projectStatus = project ? project.status : 0

  return Object.assign({},
    ownProps,
    propsFromDispatch,
    {
      projectStatus,
      syncing,
      syncedAt,
      tickets
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(List)
