import {
  setProjectTasksSyncing
} from '../../../actions/reducer/Projects.Actions'

import {
  addTask,
  updateTask
} from '../../../actions/reducer/Task.Actions'
import { createTaskObject } from '../../../objects/create'

const syncProjectTasks = (projectUuid, getState) => (dispatch, _Backend) => {
  // update project tasks syncing flag
  dispatch(setProjectTasksSyncing(projectUuid, true))
  return _Backend.call('getProjectTasks', projectUuid)
    .then(tasks => {
      const stateTasks = getState().tasks.filter(p => p.project === projectUuid)

      for (let tsk of tasks) {
        let stateIndex = stateTasks.findIndex(sTsk => sTsk.uuid === tsk.uuid)
        tsk.project = projectUuid
        let item = createTaskObject(tsk)
        dispatch(stateIndex > -1
          ? updateTask(tsk.uuid, item)
          : addTask(item)
        )
      }
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setProjectTasksSyncing(projectUuid, false)))
}

export default syncProjectTasks
