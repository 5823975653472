import React from 'react'

import { Link } from 'react-router-dom'

import {
  Pagination,
  PaginationItem,
  PaginationLink
} from 'reactstrap'

const CustomPagination = ({
  basepath,
  inheritedQuery,
  offset,
  current,
  max
}) => {
  const hasPrevious = current > 1
  const hasNext = current < max
  const isLeftRangeTruncated = current > offset + 1
  const isRightRangeTruncated = current < max - offset - 1
  const buttons = []
  if (max < 2) return null
  if (hasPrevious) {
    buttons.push({ label: '', previous: true, page: current - 1 })
  }
  if (max > offset * 2 + 1) {
    if (isLeftRangeTruncated) {
      buttons.push({ label: 1, page: 1 })
      buttons.push({ label: '...' })
    }
    let index = isLeftRangeTruncated
      ? (current - offset + 1 < max - offset * 2 + 1 ? current - offset + 1 : max - offset * 2 + 1)
      : 1
    let until = isRightRangeTruncated
      ? (current + offset > offset * 2 + 1 ? current + offset - 1 : offset * 2 + 1)
      : max
    while (index <= until) {
      buttons.push({ label: index, page: index })
      index++
    }
    if (isRightRangeTruncated) {
      buttons.push({ label: '...' })
      buttons.push({ label: max, page: max })
    }
  } else {
    let index = 1
    while (index < max + 1) {
      buttons.push({ label: index, page: index })
      index++
    }
  }
  if (hasNext) {
    buttons.push({ label: '', next: true, page: current + 1 })
  }
  return (
    <div className='d-flex w-100 my-4 justify-content-center'>
      <Pagination>
        { buttons.map((b, i) =>
          <PaginationItem
            key={i}
            active={b.page === current}
            disabled={!b.page || b.page === current}
          >
            <PaginationLink
              tag={Link}
              previous={b.previous}
              next={b.next}
              to={basepath + b.page + inheritedQuery}>{b.label}</PaginationLink>
          </PaginationItem>
        )}
      </Pagination>
    </div>

  )
}

CustomPagination.defaultProps = {
  basepath: '/',
  inheritedQuery: '',
  offset: 3,
  current: 1,
  max: 1
}

export default CustomPagination
