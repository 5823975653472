import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { syncProject } from '../../actions/middleware/Projects.Actions'

import { CONST_LIFETIME_PROJECT } from '../../constants/Lifetime.Constants'

class ProjectShortcut extends PureComponent {
  componentDidMount () {
    const { project, listSyncing } = this.props
    // case 1: not syncing list, no project
    // case 2: not syncing list, project is outdated but not syncing
    if (
      (!listSyncing && !project) ||
      (!listSyncing && project.syncedAt + CONST_LIFETIME_PROJECT < Date.now() && !project.syncing)
    ) {
      this.props.syncProject()
    }
  }

  render () {
    const { project } = this.props
    return project
      ? project.error === 'NO_MATCH'
        ? null
        : <Link to={'/project/' + project.uuid}>{project.name}</Link>
      : <span className='link-placeholder' />
  }
}

const mapStateToProps = ({ projects }, ownProps) => ({
  project: projects.items.find(u => u.uuid === ownProps.uuid),
  listSyncing: projects.syncing
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncProject: () => dispatch(syncProject(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectShortcut)
