import React from 'react'

import { Switch, Route } from 'react-router-dom'

import SignUp from './SignUp'
import CreateAccount from './create-account/Index'
import SignIn from './sign-in/Index'
import ForgottenPassword from './forgotten-password/Index'
import ResetPassword from './reset-password/Index'

import NoMatch from '../noMatch/NoMatch'

const Auth = props => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}/sign-in`} component={SignIn} />
      <Route exact path={`${props.match.path}/sign-up`} component={SignUp} />
      <Route exact path={`${props.match.path}/create-account`} component={CreateAccount} />
      <Route exact path={`${props.match.path}/forgotten-password`} component={ForgottenPassword} />
      <Route exact path={`${props.match.path}/reset-password`} component={ResetPassword} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default Auth
