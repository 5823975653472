import React from 'react'
import { connect } from 'react-redux'

import { updateTaskStatus } from '../../../../actions/middleware/Tasks.Actions'

import Button from '../../../common/Button'

const UpdateStatus = ({ status, accessLevel, update }) => {
  if (status === 0) {
    // open
    return (
      <Button size='large' onClick={() => update(accessLevel > 1 ? 2 : 1)}>
        Close
      </Button>
    )
  } else if (status === 1) {
    // pending
    return (
      <>
        {accessLevel > 1 && <Button size='large' onClick={() => update(0)}>
          Re-open
        </Button>}
        {accessLevel > 1 && <Button size='large' onClick={() => update(2)}>
          Complete
        </Button>}
        {accessLevel === 1 && <Button size='large' disabled>
          Requested review...
        </Button>}
      </>
    )
  } else if (status === 2) {
    // closed
    return accessLevel > 1 && (
      <Button size='large' onClick={() => update(0)}>
        Re-open
      </Button>
    )
  } else return null
}

const mapStateToProps = ({ user, form }) => ({
  accessLevel: user.role.level,
  submitting: form.submitting
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  update: status => dispatch(updateTaskStatus(ownProps.task, status))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateStatus)
