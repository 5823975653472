import { createGroupObject } from '../../../objects/create'

import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  updateGroup
} from '../../../actions/reducer/Groups.Actions'

import { push } from 'connected-react-router'

const update = (groupUuid, { name, thumb }) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(true))

  return _Backend.call('updateGroup', groupUuid, { name, thumb })
    .then(({ thumb }) => {
      const group = createGroupObject({
        uuid: groupUuid,
        name,
        thumb
      })
      dispatch(updateGroup(group))
      dispatch(push('/group/' + groupUuid))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default update
