import { createActivityObject } from '../../../objects/create'

import {
  setTicketActivitiesSyncing
} from '../../../actions/reducer/Ticket.Actions'

import {
  addActivity
} from '../../../actions/reducer/Activities.Actions'

const syncActivities = (uuid) => (dispatch, _Backend) => {
  dispatch(setTicketActivitiesSyncing(uuid, true))
  return _Backend.call('getTicketActivities', uuid)
    .then(activities => {
      for (const act of activities) {
        dispatch(
          addActivity(
            createActivityObject(act)
          )
        )
      }
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setTicketActivitiesSyncing(uuid, false)))
}

export default syncActivities
