import React, { Component } from 'react'
import { connect } from 'react-redux'

import { updateProfile } from '../../../../actions/middleware/Account.Actions'
import { resetForm } from '../../../../actions/reducer/Form.Actions'

import Preview from '../../../common/PreviewThumbnail'
import { compressImage } from '../../../common/Utils'

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

class UpdateProfileForm extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      phone: '',
      thumb: null
    }

    this.fieldsChanged = this.fieldsChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangePhone = this.handleChangePhone.bind(this)

    this.handleChangeThumb = this.handleChangeThumb.bind(this)
    this.handleRejectThumb = this.handleRejectThumb.bind(this)
  }

  fieldsChanged () {
    const { props: { profile }, state } = this
    return state.thumb || profile.name !== state.name || profile.phone !== state.phone
  }

  async handleChangeThumb (e) {
    // let _thumb = e.target.files[0]
    let _thumb = await compressImage(e, { width: 256, height: 256 })
    const thumb = {
      file: _thumb,
      name: _thumb.name,
      preview: URL.createObjectURL(_thumb),
      size: _thumb.size
    }

    this.setState({
      thumb
    })
  }

  handleRejectThumb (e) {
    this.setState({
      thumb: null
    })
  }

  handleSubmit (e) {
    e.preventDefault()
    let {
      name,
      phone,
      thumb
    } = this.state

    if (thumb) thumb = thumb.file
    this.props.updateProfile({
      name,
      phone,
      thumb
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name
    })
  }

  handleChangePhone (e) {
    let phone = e.target.value
    this.setState({
      phone
    })
  }

  componentDidMount () {
    // console.log('mounted update profile route')
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  render () {
    return (
      <Col md={10} lg={6}>
        <h5>Update your profile</h5>
        <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
          {this.props.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.errors.map(el => el).join(' ')}
          </Alert>}
          <FormGroup>
            <Label for='name'>Your name</Label>
            <Input
              type='text'
              maxLength={128}
              name='name'
              id='name'
              tabIndex={1}
              placeholder='Your name'
              disabled={this.props.submitting}
              onChange={this.handleChangeName} />
            <FormText color='muted'>
                  128/{this.state.name.length}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='phase-name'>Phone number</Label>
            <Input
              type='text'
              maxLength={128}
              name='phone'
              id='phone'
              tabIndex={1}
              placeholder='Phone number'
              disabled={this.props.submitting}
              onChange={this.handleChangePhone} />
            <FormText color='muted'>
                  128/{this.state.phone.length}
            </FormText>
          </FormGroup>
          <FormGroup>
            <Label for='thumb'>Upload thumbnail for group</Label>
            {this.state.thumb
              ? <Preview
                handleReject={this.handleRejectThumb}
                details={this.state.thumb} />
              : <Input
                type='file'
                name='thumb'
                id='thumb'
                onChange={this.handleChangeThumb} />
            }
            <FormText color='muted'>
                  Thumbnail will be cropped to square and resized.
            </FormText>
          </FormGroup>
          <Button color='primary'
            type='submit'
            tabIndex={3}
            disabled={!this.fieldsChanged() || this.props.submitting}
          >
            Update
          </Button>
        </Form>
      </Col>
    )
  }
}

const mapStateToProps = ({ form, user }, ownProps) => ({
  profile: user.profile,
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  updateProfile: form => dispatch(updateProfile(form)),
  resetForm: () => dispatch(resetForm())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateProfileForm)
