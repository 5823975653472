const defaultObject = {
  uuid: '',
  error: '',
  name: '',
  description: '',
  status: 0,
  creator: '',
  createdAt: Date.now(),
  updatedAt: Date.now(),
  syncedAt: Date.now(),
  syncing: false,
  tasks: {
    syncedAt: 0,
    syncing: false
  },
  tickets: {
    syncedAt: 0,
    syncing: false
  },
  participants: {
    syncedAt: 0,
    syncing: false
  },
  phases: {
    syncedAt: 0,
    syncing: false
  },
  files: {
    syncedAt: 0,
    syncing: false
  }
}

export const createProjectObject = project => {
  return Object.assign(
    {},
    defaultObject,
    project
  )
}
