const defaultObject = {
  uuid: '',
  project: '',
  phase: '',
  creator: '',
  name: '',
  description: '',
  estimatedWork: 0,
  assignees: [],
  priority: 0,
  status: 0,
  error: '',
  activities: {
    syncedAt: 0,
    syncing: false
  },
  files: {
    syncedAt: 0,
    syncing: false
  },
  createdAt: Date.now(),
  updatedAt: Date.now(), // last modification date
  syncedAt: Date.now(),
  syncing: false
}

export const createTaskObject = entity => {
  return Object.assign(
    {},
    defaultObject,
    entity
  )
}
