
import {
  updateTask
} from '../../../actions/reducer/Task.Actions'

const updateStatus = (task, status) => (dispatch, _Backend) => {
  return _Backend.call('updateTaskStatus', task, status)
    .then((result) => {
      // TODO: update task!
      dispatch(updateTask(task, { status }))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default updateStatus
