const initialState = {
  syncedAt: null,
  syncing: false,
  error: false,
  items: []
}

const GroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GROUPS_ADD_ITEM':
      return state.items.some(el => el.uuid === action.payload.uuid)
        ? state
        : Object.assign(
          {},
          state,
          { items: [...state.items, action.payload] })
    case 'GROUPS_UPDATE_ITEM':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? {
              ...item,
              ...action.payload
            }
            : item
        ) })
    case 'GROUPS_REMOVE_ITEM':
      return Object.assign(
        {},
        state,
        { items: state.items.filter(item => item.uuid !== action.payload) }
      )
    case 'GROUPS_ADD_MEMBER':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? {
              ...item,
              members: [...item.members, action.payload.member]
            }
            : item
        ) })
    case 'GROUPS_REMOVE_MEMBER':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? {
              ...item,
              members: item.members.filter(member => member !== action.payload.member)
            }
            : item
        ) })
    case 'GROUPS_SET_GROUP_SYNCING':
      return Object.assign(
        {},
        state,
        { items: state.items.map(
          item => item.uuid === action.payload.uuid
            ? {
              ...item,
              syncing: action.payload.syncing
            }
            : item
        ) })
    case 'GROUPS_SET_SYNCED_AT':
      return Object.assign({}, state, { syncedAt: action.payload })
    case 'GROUPS_SET_SYNCING':
      return Object.assign({}, state, { syncing: action.payload })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default GroupsReducer
