import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncTicket } from '../../../../../actions/middleware/Tickets.Actions'

import {
  CONST_TICKET_STATUSES
} from '../../../../../constants/Ticket.Constants'

import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Badge
} from 'reactstrap'

import UserShortcut from '../../../../common/UserShortcut'

class Ticket extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)
  }

  _statusBadge (status) {
    const { label } = CONST_TICKET_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _ticketstatus-${status}`}>
        {label.generic} - {label.detailed}
      </Badge>
    )
  }

  componentDidMount () {
    this.props.syncTicket()
    // sync history if needed
    if (!this.props.data ||
        (this.props.data.syncedAt + (10 * 1000) < Date.now() && !this.props.data.syncing)) {
      // this.props.syncTask()
    }
  }

  render () {
    return (
      <Row>
        <Col className='task'>
          <div className='border-top'>
            <Link
              to={'/ticket/' + this.props.data.uuid}
            >
              {this.props.data.name}
            </Link>
            <div className='d-flex w-100 align-items-center'>
              {this._statusBadge(this.props.data.status)}
              <small className='mr-2 text-muted'>ID {this.props.data.uuid}</small>
              <small>
                {this.props.data.assignees.length > 0
                  ? <>
                  Assignees:
                    {this.props.data.assignees.map(as => <UserShortcut
                      display={{ thumb: true, username: false, size: 'small' }} uuid={as} />)}
                  </>
                  : <i>Not assigned</i>}
              </small>
            </div>
            <Row>
              <Col>
                <small>
                  created at {new Date(this.props.data.createdAt).toLocaleString()} by <UserShortcut
                    display={{ thumb: true, username: false, size: 'small' }} uuid={this.props.data.creator} />, recently updated at {new Date(this.props.data.updatedAt).toLocaleString()}
                </small>
              </Col>
            </Row>
          </div>

        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTicket: () => dispatch(syncTicket(ownProps.data.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ticket)
