// import functors
import { project, projects, tasks, tickets } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const ProjectsMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'PROJECTS') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'CREATE':
      chain(
        projects.create(action.payload.form, getState().user.uuid)
      )(dispatch)
      break
    case 'UPDATE_STATUS':
      chain(
        project.updateStatus(
          action.payload.project,
          action.payload.status
        )
      )(dispatch)
      break
    case 'UPDATE_PROPERTIES':
      chain(
        project.updateProperties(
          getState().projects.items
            .find(item => item.uuid === action.payload.project),
          action.payload.form
        )
      )(dispatch)
      break
    case 'SYNC':
      chain(
        project.getProject(
          action.payload.uuid,
          getState().projects.items
            .findIndex(item => item.uuid === action.payload.uuid)
        )
      )(dispatch)
      break
    case 'SYNC_PARTICIPANTS':
      chain(
        project.participants(
          action.payload.uuid,
          getState().accesses
        )
      )(dispatch)
      break
    case 'GRANT_ACCESS':
      chain(
        project.grantAccess(
          action.payload.project,
          action.payload.entity
        )
      )(dispatch)
      break
    case 'REVOKE_ACCESS':
      chain(
        project.revokeAccess(
          action.payload.project,
          action.payload.entity
        )
      )(dispatch)
      break
    case 'SYNC_LIST':
      chain(
        projects.getProjectList(
          getState
        )
      )(dispatch)
      break
    case 'SYNC_TASKS':
      chain(
        tasks.syncProjectTasks(
          action.payload.uuid,
          getState
        )
      )(dispatch)
      break
    case 'SYNC_TICKETS':
      chain(
        tickets.syncProjectTickets(
          action.payload.uuid,
          getState
        )
      )(dispatch)
      break
    case 'SYNC_PHASES':
      chain(
        project.phases(
          action.payload.uuid,
          getState().phases.filter(p => p.project === action.payload.uuid)
        )
      )(dispatch)
      break
    case 'ADD_PHASE':
      chain(
        project.addPhase(
          action.payload.project,
          action.payload.name,
          getState().user.uuid
        )
      )(dispatch)
      break
    case 'UPDATE_PHASE':
      chain(
        project.updatePhase(
          action.payload.project,
          action.payload.phase,
          action.payload.name
        )
      )(dispatch)
      break
    case 'SYNC_ALL_ACTIVITIES':
      chain(
        projects.activities()
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default ProjectsMiddleware
