import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { history } from './Store'

import 'bootstrap/dist/css/bootstrap.min.css'
import './style/App.scss'

import { validateSession } from './actions/middleware/Auth.Actions'

import ScrollToTop from './view/common/ScrollToTop'
import Routes from './view/routes/Index'
import Navbar from './view/layout/Navbar'
import Footer from './view/layout/Footer'

class App extends Component {
  componentDidMount () {
    this.props.validateSession()
  }

  componentDidUpdate (prevProps) {
    // first appear without cache takes longer loading time
    document.getElementById('page-loader').classList.add('fade-out')
  }

  render () {
    return this.props.initialized && (
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <Navbar />
        <Routes />
        <Footer />
      </ConnectedRouter>
    )
  }
}

const mapStateToProps = ({ app }) => ({
  initialized: app.initialized
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  validateSession: form => dispatch(validateSession())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
