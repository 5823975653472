import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'reactstrap'

const Preview = ({ details, handleReject }) => {
  const { name, preview, size } = details

  return (<div className='w-100'>
    <div className='image-container position-relative'>
      <Button close onClick={handleReject} />
      <img
        src={preview}
        alt={name}
        className='img-thumbnail' />
      <div className='details'>
        <span className='small text-muted'>{name} - {(size / 1024000).toFixed(2)}MB</span>
      </div>
    </div>
  </div>)
}

Preview.propTypes = {
  imagefile: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      name: PropTypes.string,
      preview: PropTypes.string,
      size: PropTypes.number
    })
  )
}

export default Preview
