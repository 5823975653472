import React from 'react'

import { Switch, Route } from 'react-router-dom'

import ProtectedRoute from '../../common/ProtectedRoute'

import UserProfile from './UserProfile'
import Manage from './Manage'

import NoMatch from '../noMatch/NoMatch'

const UserRoutes = ({ match: { path } }) => {
  return (
    <Switch>
      <Route exact path={`${path}/`} component={UserProfile} />
      <ProtectedRoute
        protectionOptions={{
          group: { value: 2, exact: true },
          level: { value: 3, exact: false }
        }}
        exact
        path={`${path}/manage`}
        component={Manage} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default UserRoutes
