import Create from './Create'
import Activities from './Activities'
import GetProjectList from './GetProjectList'

const projects = API => {
  return {
    create: (...params) => dispatch => Create(...params)(dispatch, API),
    activities: (...params) => dispatch => Activities(...params)(dispatch, API),
    getProjectList: (...params) => dispatch => GetProjectList(...params)(dispatch, API)
  }
}

export default projects
