import React from 'react'

import { Switch, Route } from 'react-router-dom'

import NoMatch from '../../noMatch/NoMatch'
import Profile from './Profile'
import Update from './Update'

const ProfileRouter = props => {
  return (
    <Switch>
      <Route exact path={`${props.match.path}`} component={Profile} />
      <Route exact path={`${props.match.path}/update`} component={Update} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default ProfileRouter
