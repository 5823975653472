import React from 'react'
import { connect } from 'react-redux'

import {
  Redirect,
  Link
} from 'react-router-dom'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Button from '../../../common/Button'
import FormStateWrapper from '../../../common/FormStateWrapper'
import SwitchLanguage from '../../../common/SwitchLanguage'
import ForgottenPassword from './ForgottenPassword'

import Translated from '../../../common/Translated'

const ForgottenPasswordPage = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to='/' />
  } else {
    return (
      <Container fluid className='bg-light'>
        <Container>
          <Row className='justify-content-center align-items-center mh-100vh'>
            <Col sm={12} md={6} lg={4}>
              <FormStateWrapper
                component={ForgottenPassword}
              />
              <div className='d-flex w-100 justify-content-center'>
                <Button
                  tag={Link}
                  size='small'
                  to='/auth/sign-in'>
                  <Translated txtid='TXT_FORGOTTEN_PASSWORD_FORM__BUTTON_LABEL_BACK_TO_SIGN_IN' />
                </Button>
              </div>
              <div className='d-flex w-100 my-3 justify-content-center'>
                <SwitchLanguage />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  isAuthenticated: !!user.uuid
})
const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgottenPasswordPage)
