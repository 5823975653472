export const CONST_TICKET_STATUSES = Object.freeze([
  {
    id: 0,
    label: {
      generic: 'Open',
      detailed: 'New'
    }
  },
  {
    id: 1,
    label: {
      generic: 'Open',
      detailed: 'Pending'
    }
  },
  {
    id: 2,
    label: {
      generic: 'Open',
      detailed: 'In progress'
    }
  },
  {
    id: 3,
    label: {
      generic: 'Closed',
      detailed: 'Resolved'
    }
  },
  {
    id: 4,
    label: {
      generic: 'Closed',
      detailed: 'Rejected'
    }
  }
])
