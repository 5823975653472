import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncUserlist } from '../../../actions/middleware/Users.Actions'
import { syncGrouplist } from '../../../actions/middleware/Groups.Actions'

import { Link } from 'react-router-dom'

import ProtectedComponent from '../../common/ProtectedComponent'
import UserShortcut from '../../common/UserShortcut'
import GroupShortcut from '../../common/GroupShortcut'
import Button from '../../common/Button'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

class Community extends Component {
  componentDidMount () {
    const { users, groups } = this.props
    if (users.syncedAt < Date.now() - (10 * 1000) && !users.syncing) {
      this.props.syncUserlist()
    }
    if (groups.syncedAt < Date.now() - (10 * 1000) && !groups.syncing) {
      this.props.syncGrouplist()
    }
  }

  render () {
    return (
      <Container>
        <h2>Community</h2>
        <Row>
          <Col md={8}>
            <Row>
              <Col>
                <h6>Users</h6>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex py-2'>
                {this.props.users.items.length > 6 &&
                <Button
                  tag={Link}
                  size='small'
                  className='mr-3'
                  to='/community/users'>
                  See all ({this.props.users.items.length})
                </Button>}
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 2, exact: true },
                    level: { value: 2, exact: false }
                  }}>
                  <Button
                    tag={Link}
                    color='add'
                    size='small'
                    to='/community/users/invite'>
                    Invite
                  </Button>
                </ProtectedComponent>
              </Col>
            </Row>
            <Row>
              {this.props.users.items.map((u, index) => index < 6 &&
              <Col
                key={u.uuid}
                className='d-flex'
                lg={6}
                md={6}
                sm={6}>
                <div className='align-self-stretch w-100 border rounded mb-4 p-2 shadow-sm'>
                  <UserShortcut uuid={u.uuid}
                    className='w-100'
                    display={{ thumb: true, username: true, name: true, size: 'large' }} />
                </div>
              </Col>)}
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <h6>Groups</h6>
              </Col>
            </Row>
            <Row>
              <Col className='d-flex py-2'>
                {this.props.groups.items.length > 3 &&
                <Button
                  tag={Link}
                  size='small'
                  className='mr-3'
                  to='/community/groups'>
                  See all ({this.props.groups.items.length})
                </Button>}
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 2, exact: true },
                    level: { value: 2, exact: false }
                  }}>
                  <Button
                    tag={Link}
                    size='small'
                    color='add'
                    to='/community/groups/create'>
                    Create group
                  </Button>
                </ProtectedComponent>
              </Col>
            </Row>
            <Row>
              {this.props.groups.items.map((u, index) => index < 3 &&
              <Col
                key={u.uuid}
                className='d-flex'
                sm={12}>
                <div className='align-self-stretch w-100 border rounded mb-4 p-2 shadow-sm'>
                  <GroupShortcut uuid={u.uuid} />
                </div>
              </Col>)}
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

// filter NO ERROR!
const mapStateToProps = ({ users, groups }, ownProps) => ({
  users,
  groups
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncUserlist: () => dispatch(syncUserlist()),
  syncGrouplist: () => dispatch(syncGrouplist())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Community)
