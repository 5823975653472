import React from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'
import { Route, Redirect } from 'react-router-dom'

import NoMatch from '../routes/noMatch/NoMatch'

// TODO: group, level chech and nomatch show

const ProtectedRoute = ({ component: Component, isAuthenticated, userRole, protectionOptions, ...rest }) => {
  return (
    <Route {...rest}
      render={props => {
        if (isAuthenticated) {
          if ((protectionOptions.group.exact && userRole.group !== protectionOptions.group.value) ||
          (!protectionOptions.group.exact && userRole.group < protectionOptions.group.value)) {
            return <NoMatch {...props} />
          }
          if ((protectionOptions.level.exact && userRole.level !== protectionOptions.level.value) ||
          (!protectionOptions.level.exact && userRole.level < protectionOptions.level.value)) {
            return <NoMatch {...props} />
          }
          return <Component {...props} />
        } else {
          const search = qs.stringify({ returnto: props.location.pathname })
          return <Redirect to={{
            pathname: '/auth/sign-in',
            search
          }} />
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  isAuthenticated: !!state.user.uuid,
  userRole: state.user.role
})

export default connect(
  mapStateToProps,
  {}
)(ProtectedRoute)
