import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncTaskActivities } from '../../../../actions/middleware/Tasks.Actions'

// import UserShortcut from '../../../common/UserShortcut'

import Button from '../../../common/Button'
import Activity from '../../../common/Activity'

class Activities extends Component {
  constructor () {
    super()

    this.step = 5

    this.state = {
      limit: 5
    }
  }

  componentDidMount () {
    if (this.props.syncedAt + (10 * 1000) < Date.now() && !this.props.syncing) {
      this.props.syncTaskActivities()
    }
  }

  componentDidUpdate (prevProps, prevState) {

  }

  render () {
    return (<div>
      {this.props.activities.length > this.state.limit &&
        <div className='activities-limiter text-center mb-3'>
          <div className='limiter-divider' />
          <small className='mx-2 text-muted'>
            Showing {this.state.limit} of {this.props.activities.length} activities
          </small>
          <Button
            className='mx-2'
            size='small'
            onClick={() => this.setState({ limit: this.state.limit + this.step })}>
            Show more
          </Button>
        </div>
      }
      {this.props.activities
        .sort((a, b) => (a.triggeredAt > b.triggeredAt) ? 1 : -1)
        .map((act, index, arr) => {
          if (arr.length - this.state.limit < index + 1) {
            return <Activity
              key={act.uuid}
              scope='task'
              {...act}
            />
          }
          return null
        }
        )
      }
    </div>)
  }
}

const mapStateToProps = ({ activities }, ownProps) => {
  return {
    activities: activities
      .filter(a => a.task === ownProps.uuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const task = ownProps.uuid
  return {
    syncTaskActivities: () => dispatch(syncTaskActivities(task))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Activities)
