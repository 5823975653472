import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import Add from './Add'
import List from './List'

import { Switch, Route } from 'react-router-dom'

import NoMatch from '../../noMatch/NoMatch'

// route kell ide import ProtectedComponent from '../../../common/ProtectedComponent'

const Tasks = props => (
  <Row>
    <Col>
      <Switch>
        <Route exact path={`${props.match.path}/add`} component={Add} />
        <Route exact path={`${props.match.path}/`} component={List} />
        <Route path={`${props.match.path}/p/:pageNumber`} component={List} />
        <Route component={NoMatch} />
      </Switch>
    </Col>
  </Row>
)

export default Tasks
