export const CONST_APP_TRANSLATIONS = Object.freeze([
  {
    flag: 'en',
    label: 'English'
  },
  {
    flag: 'hu',
    label: 'Magyar'
  }
])

export const CONST_APP_DEFAULT_TRANSLATION = CONST_APP_TRANSLATIONS[0]
