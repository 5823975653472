import React from 'react'

import { Switch, Route } from 'react-router-dom'
import ProtectedRoute from '../../common/ProtectedRoute'

import Group from './Group'
import Update from './update/Index'

import NoMatch from '../noMatch/NoMatch'

const GroupRoutes = props => {
  return (
    <Switch>
      <ProtectedRoute
        protectionOptions={{
          group: { value: 2, exact: true },
          level: { value: 2, exact: false }
        }}
        exact
        path={`${props.match.path}/update`}
        component={Update} />
      <Route exact path={`${props.match.path}/`} component={Group} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default GroupRoutes
