import React from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import {
  Redirect,
  Link
} from 'react-router-dom'

import {
  Container,
  Row,
  Col,
  Alert
} from 'reactstrap'

import Button from '../../../common/Button'
import Translated from '../../../common/Translated'
import FormStateWrapper from '../../../common/FormStateWrapper'
import SwitchLanguage from '../../../common/SwitchLanguage'
import SignIn from './SignIn'

const SignInPage = ({ isAuthenticated, location }) => {
  const _feedbackMessage = messageFlag => {
    let msg = ''
    if (messageFlag === 'account-deleted') {
      msg = 'TXT_SIGN_IN_FORM__FEEDBACK_ACCOUNT_DELETED'
    } else if (messageFlag === 'password-updated') {
      msg = 'TXT_SIGN_IN_FORM__FEEDBACK_PASSWORD_UPDATED'
    } else if (messageFlag === 'account-created') {
      msg = 'TXT_SIGN_IN_FORM__FEEDBACK_ACCOUNT_CREATED'
    } else {
      return null
    }
    return (<Alert className='mt-4 text-center' color='primary'>
      <Translated txtid={msg} />
    </Alert>)
  }

  if (isAuthenticated) {
    return <Redirect to='/' />
  } else {
    const { feedback } = qs.parse(location.search)
    return (
      <Container fluid className='bg-light'>
        <Container>
          <Row className='justify-content-center align-items-center mh-100vh'>
            <Col sm={12} md={6} lg={4}>
              {_feedbackMessage(feedback)}
              <FormStateWrapper
                component={SignIn}
              />
              <div className='d-flex w-100 justify-content-center'>
                <Button
                  tag={Link}
                  size='small'
                  to='/auth/forgotten-password'>
                  <Translated txtid='TXT_SIGN_IN_FORM__BUTTON_LABEL_FORGOTTEN_PASSWORD' />
                </Button>
              </div>
              <div className='d-flex w-100 my-3 justify-content-center'>
                <SwitchLanguage />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = ({ user }) => ({
  isAuthenticated: !!user.uuid
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInPage)
