export const addFile = fileDescriptor => ({
  type: 'FILES_ADD',
  payload: fileDescriptor
})

export const updateFile = (uuid, props) => ({
  type: 'FILES_UPDATE',
  payload: {
    uuid,
    props
  }
})

export const removeFile = uuid => ({
  type: 'FILES_REMOVE',
  payload: uuid
})
