import {
  removeFile
} from '../../../actions/reducer/Files.Actions'

const deleteFile = (uuid) => (dispatch, _Backend) => {
  return _Backend.call('deleteFile', uuid)
    .then(() => {
      dispatch(
        removeFile(uuid)
      )
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default deleteFile
