import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import UserReducer from './User.Reducer'
import AppReducer from './App.Reducer'
import FormReducer from './Form.Reducer'
import FormsReducer from './Forms.Reducer'
import ProjectsReducer from './Projects.Reducer'
import UsersReducer from './Users.Reducer'
import GroupsReducer from './Groups.Reducer'
import AccessReducer from './Access.Reducer'
import PhasesReducer from './Phases.Reducer'
import TasksReducer from './Tasks.Reducer'
import TicketsReducer from './Tickets.Reducer'
import ActivitiesReducer from './Activities.Reducer'
import FilesReducer from './Files.Reducer'

const createRootReducer = history => combineReducers({
  router: connectRouter(history),
  user: UserReducer,
  app: AppReducer,
  form: FormReducer,
  forms: FormsReducer,
  projects: ProjectsReducer,
  users: UsersReducer,
  groups: GroupsReducer,
  accesses: AccessReducer,
  phases: PhasesReducer,
  tasks: TasksReducer,
  tickets: TicketsReducer,
  activities: ActivitiesReducer,
  files: FilesReducer
})

export default createRootReducer
