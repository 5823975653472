import { createFileObject } from '../../../objects/create'

import {
  addFile
} from '../../../actions/reducer/Files.Actions'

import {
  setProjectFilesSyncing
} from '../../../actions/reducer/Projects.Actions'

const syncFileList = (project) => (dispatch, _Backend) => {
  dispatch(setProjectFilesSyncing(project, true))
  return _Backend.call('getFileList', project)
    .then(descriptors => {
      for (let fileDescriptor of descriptors) {
        dispatch(addFile(
          createFileObject(fileDescriptor)
        ))
      }

      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setProjectFilesSyncing(project, false)))
}

export default syncFileList
