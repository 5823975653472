const defaultObject = {
  id: -1,
  errors: [],
  submitting: false,
  message: '', // for feedbacks
  fields: {} // later will be usable
}

export const createFormObject = p => {
  return Object.assign(
    {},
    defaultObject,
    p
  )
}
