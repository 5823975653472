export const CONST_TASK_STATUSES = Object.freeze([
  {
    id: 0,
    label: 'Open'
  },
  {
    id: 1,
    label: 'Pending'
  },
  {
    id: 2,
    label: 'Closed'
  }
])

export const CONST_TASK_PRIORITIES = Object.freeze([
  {
    id: 0,
    label: 'Low'
  },
  {
    id: 1,
    label: 'Medium'
  },
  {
    id: 2,
    label: 'High'
  }
])

export const CONST_TASKS_VIEW_AS_OPTIONS = Object.freeze([
  {
    id: 'TABLE',
    matchParam: 'table',
    label: 'Table',
    order: {
      enabled: true,
      options: {}
    },
    filter: {
      enabled: true,
      options: {
        name: true,
        phases: true,
        assignees: true,
        priorities: true,
        statuses: true
      }
    }
  },
  {
    id: 'STATUS_COLUMNS',
    matchParam: 'status-columns',
    label: 'Status columns',
    order: {
      enabled: true,
      options: {}
    },
    filter: {
      enabled: true,
      options: {
        name: true,
        phases: true,
        assignees: true,
        priorities: true,
        statuses: false
      }
    }
  },
  {
    id: 'ASSIGNEE_COLUMNS',
    matchParam: 'assignee-columns',
    label: 'Assignee columns',
    order: {
      enabled: true,
      options: {}
    },
    filter: {
      enabled: true,
      options: {
        name: true,
        phases: true,
        assignees: false,
        priorities: true,
        statuses: true
      }
    }
  },
  {
    id: 'PHASE_COLUMNS',
    matchParam: 'phase-columns',
    label: 'Phase columns',
    order: {
      enabled: true,
      options: {}
    },
    filter: {
      enabled: true,
      options: {
        name: true,
        phases: false,
        assignees: true,
        priorities: true,
        statuses: true
      }
    }
  },
  {
    id: 'GANTT_CHART',
    matchParam: 'gantt-chart',
    label: 'Gantt chart',
    order: {
      enabled: false,
      options: {}
    },
    filter: {
      enabled: false,
      options: {}
    }
  }
])

export const CONST_TASK_ORDERS = Object.freeze([
  {
    name: 'NAME_ASC',
    label: 'Name A-Z',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let nameA = a.details.name.toUpperCase()
        let nameB = b.details.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        }
        return 0
      })
    }
  },
  {
    name: 'NAME_DESC',
    label: 'Name Z-A',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let nameA = a.details.name.toUpperCase()
        let nameB = b.details.name.toUpperCase()
        if (nameA > nameB) {
          return -1
        } else if (nameA < nameB) {
          return 1
        }
        return 0
      })
    }
  },
  {
    name: 'ID_ASC',
    label: 'ID ascendant',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let propA = Number(a.id)
        let propB = Number(b.id)
        if (propA < propB) {
          return -1
        } else if (propA > propB) {
          return 1
        }
        return 0
      })
    }
  },
  {
    name: 'ID_DESC',
    label: 'ID decrescent',
    orderFunction: (array) => {
      return array.sort((a, b) => {
        let propA = Number(a.id)
        let propB = Number(b.id)
        if (propA > propB) {
          return -1
        } else if (propA < propB) {
          return 1
        }
        return 0
      })
    }
  }
])
