import UpdateProfile from './UpdateProfile'
import UpdatePassword from './UpdatePassword'

const account = API => {
  return {
    updateProfile: (...params) => dispatch => UpdateProfile(...params)(dispatch, API),
    updatePassword: (...params) => dispatch => UpdatePassword(...params)(dispatch, API)
  }
}

export default account
