
import {
  removeTicketAssignee
} from '../../../actions/reducer/Ticket.Actions'

const unassign = (ticket, user) => (dispatch, _Backend) => {
  return _Backend.call('unassignUserFromTicket', ticket, user)
    .then((result) => {
      // TODO: update task!
      dispatch(removeTicketAssignee(ticket, user))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default unassign
