import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncUserlist } from '../../../../actions/middleware/Users.Actions'
import { syncGrouplist } from '../../../../actions/middleware/Groups.Actions'
import { syncParticipants, grantAccess, revokeAccess } from '../../../../actions/middleware/Projects.Actions'

import { Button, Row, Col } from 'reactstrap'

class Participants extends Component {
  constructor () {
    super()

    this._isPermanent = this._isPermanent.bind(this)
  }

  componentDidMount () {
    if (!this.props.syncedAt || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncParticipants()
    }
    // always resync user and group list because of up to date actions
    this.props.syncUserlist()
    this.props.syncGrouplist()
  }

  _isPermanent (uuid) {
    return !!this.props.accesses.find(acc => acc.entity === uuid).permanent
  }

  render () {
    return (
      <>
        <Row>
          <Col>
            <h4>Manage</h4>
            grant access, revoke access
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={this.props.syncUserlist}>Sync user list</Button>
            <Button onClick={this.props.syncGrouplist}>Sync group list</Button>
            <h5>Users in project</h5>
            <ul>
              {this.props.users
                .filter(u => this.props.accesses.findIndex(a => a.entity === u.uuid) > -1)
                .map(user => <li key={user.uuid}>
                  {user.username}
                  <Button
                    disabled={this._isPermanent(user.uuid)}
                    onClick={() => this.props.revokeAccess(user.uuid)}>
                    Revoke access
                  </Button>
                </li>)}
            </ul>
            <h5>Users not in project</h5>
            <ul>
              {this.props.users
                .filter(u => this.props.accesses.findIndex(a => a.entity === u.uuid) === -1)
                .map(user => <li key={user.uuid}>
                  {user.username}
                  <Button onClick={() => this.props.grantAccess(user.uuid)}>Grant access</Button>
                </li>)}
            </ul>
            <h5>Groups in project</h5>
            <ul>
              {this.props.groups
                .filter(g => this.props.accesses.findIndex(a => a.entity === g.uuid) > -1)
                .map(g => <li key={g.uuid}>
                  {g.name} - {g.members.map(uuid => <div>{uuid} (Member component soon)</div>)}
                  <Button onClick={() => this.props.revokeAccess(g.uuid)}>Revoke access</Button>
                </li>)}
            </ul>
            <h5>Groups not in project</h5>
            <ul>
              {this.props.groups
                .filter(g => this.props.accesses.findIndex(a => a.entity === g.uuid) === -1)
                .map(g => <li key={g.uuid}>
                  {g.name}
                  <Button onClick={() => this.props.grantAccess(g.uuid)}>Grant access</Button>
                </li>)}
            </ul>
          </Col>
        </Row>
      </>
    )
  }
}

// filter to non existing
const mapStateToProps = ({ accesses, users, groups, form }, ownProps) => {
  return {
    accesses: accesses.filter(x => x.project === ownProps.uuid),
    users: users.items,
    groups: groups.items,
    submitting: form.submitting
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const pid = ownProps.uuid
  return {
    syncUserlist: () => dispatch(syncUserlist()),
    syncGrouplist: () => dispatch(syncGrouplist()),
    syncParticipants: () => dispatch(syncParticipants(pid)),
    grantAccess: e => dispatch(grantAccess(pid, e)),
    revokeAccess: e => dispatch(revokeAccess(pid, e))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Participants)
