import { createTaskObject } from '../../../objects/create'

import {
  setTaskSyncing,
  addTask,
  updateTask
} from '../../../actions/reducer/Task.Actions'

const sync = (uuid, stateTask) => (dispatch, _Backend) => {
  // TODO: set syncing!
  // TODO: create at this point if no index!

  if (stateTask) {
    dispatch(setTaskSyncing(uuid, true))
  } else {
    dispatch(addTask(createTaskObject({
      uuid,
      syncing: true
    })))
  }
  return _Backend.call('getTask', uuid)
    .then((user) => {
      let item = createTaskObject(user)
      dispatch(updateTask(uuid, item))
      return Promise.resolve()
    })
    .catch(err => {
      const errors = err.properties || []

      const item = createTaskObject({
        uuid,
        error: errors.length === 0 ? 'FAILED_TO_FETCH' : errors[0]
      })
      dispatch(updateTask(uuid, item))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setTaskSyncing(uuid, false)))
}

export default sync
