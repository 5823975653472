import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Breadcrumbs from '../../../common/Breadcrumbs'
import FormStateWrapper from '../../../common/FormStateWrapper'

import InviteUser from './components/InviteUser'

const Invite = props => (
  <Container>
    <Row>
      <Col>
        <Breadcrumbs items={[
          { label: 'Community', link: '/community' },
          { label: 'Users', link: '/community/users' },
          { label: 'Invite user' }
        ]} />
      </Col>
    </Row>
    <Row>
      <Col md={{ size: 6, offset: 3 }}>
        <h3>Invite user by email address</h3>
        <FormStateWrapper
          component={InviteUser}
        />
      </Col>
    </Row>
  </Container>
)

export default Invite
