import React, { Component } from 'react'
import { connect } from 'react-redux'

import { addPhase } from '../../../../actions/middleware/Projects.Actions'
import { resetForm } from '../../../../actions/reducer/Form.Actions'

import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

class AddPhase extends Component {
  constructor () {
    super()

    this.state = {
      name: '',
      isValidName: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { name } = this.state
    this.props.addPhase(name)
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  render () {
    const { isValidName } = this.state
    const canSubmit = isValidName
    return (
      <Col md={10} lg={6}>
        <h5>Add new project phase</h5>
        <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
          {this.props.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.errors.map(el => el).join(' ')}
          </Alert>}
          <FormGroup>
            <Label for='phase-name'>Phase name</Label>
            <Input
              type='text'
              maxLength={128}
              name='phase-name'
              id='phase-name'
              tabIndex={1}
              placeholder='Phase name'
              disabled={this.props.submitting}
              invalid={!!this.state.name && !isValidName}
              onChange={this.handleChangeName}
              required />
            <FormText color='muted'>
                  128/{this.state.name.length}
            </FormText>
          </FormGroup>
          <Button color='primary'
            type='submit'
            tabIndex={3}
            disabled={!canSubmit || this.props.submitting}
          >
            Add new phase
          </Button>
          TODO: cancel button, back to phases
        </Form>
      </Col>
    )
  }
}

const mapStateToProps = ({ form, user }, ownProps) => ({
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  addPhase: name => dispatch(addPhase(
    ownProps.match.params.projectUuid,
    name)),
  resetForm: () => dispatch(resetForm())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPhase)
