import React from 'react'
import { connect } from 'react-redux'
import Cookies from 'universal-cookie'
import { setAppLanguage } from '../../actions/reducer/App.Actions'

import { CONST_APP_TRANSLATIONS } from '../../constants/App.Constants'

const cookies = new Cookies()
const SwitchLanguage = ({ language, setAppLanguage }) => {
  return (
    <div className='switch-language'>
      <img className='d-inline-block mx-2 my-2' style={{ height: '1.5rem' }} src='/language-icon.png' alt='language pick' />
      {
        CONST_APP_TRANSLATIONS.map(({ flag, label }) =>
          <small
            key={flag}
            className={`d-inline-block px-2 py-2 cursor-pointer ${flag !== language ? 'text-muted' : 'text-underline'}`}
            onClick={() => setAppLanguage(flag)}>
            {label}
          </small>)
      }
    </div>
  )
}

const mapStateToProps = ({ app: { language } }, ownProps) => ({
  language
})
const mapDispatchToProps = (dispatch) => ({
  // set also cookie
  setAppLanguage: flag => {
    const cookieOptions = { path: '/', expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000) }
    // TODO: .env file
    if (process.env.NODE_ENV === 'production') {
      cookieOptions.secure = true
      cookieOptions.domain = '.infinite-edu.hu'
    }

    cookies.set('language', flag, cookieOptions)
    dispatch(setAppLanguage(flag))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwitchLanguage)
