import React, { Component } from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import { syncProjectlist } from '../../../actions/middleware/Projects.Actions'

import { Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import ProtectedComponent from '../../common/ProtectedComponent'
import Button from '../../common/Button'
import Pagination from '../../common/Pagination'

import Filters from './components/Filters'
import Project from './components/Project'

// add constant!
const PAGE_DISPLAY_ITEM_LIMIT = 10

const compare = (a, b) => {
  const pA = a.updatedAt
  const pB = b.updatedAt

  // DESC
  let comparison = 0
  if (pA < pB) {
    comparison = 1
  } else if (pA > pB) {
    comparison = -1
  }
  return comparison
}

class List extends Component {
  componentDidMount () {
    if (!this.props.syncedAt || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncProjectlist()
    }
  }

  render () {
    return (
      <>
        <Container fluid className='bg-light'>
          <Container>
            <Row className='align-items-center'>
              <Col>
                <Filters />
              </Col>
              <ProtectedComponent
                protectionOptions={{
                  group: { value: 2, exact: false },
                  level: { value: 2, exact: false }
                }}>
                <Col className='d-flex align-items-center justify-content-end py-2'>
                  <Button
                    color='add'
                    tag={Link}
                    to='/projects/create'>
                    Create new project
                  </Button>
                </Col>
              </ProtectedComponent>
            </Row>
          </Container>
        </Container>
        <Container>
          {this.props.projects.length > 0 && <Row>
            <Col>
              <small>
              Showing {this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT - PAGE_DISPLAY_ITEM_LIMIT + 1}-
                {(this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT) <= this.props.projects.length
                  ? (this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT)
                  : this.props.projects.length}{' '}
              / {this.props.projects.length} projects
              </small>
            </Col>
          </Row>}
          {this.props.search.query && <Row>
            <Col>
            Search results for: <b>"{this.props.search.query}"</b>
            </Col>
          </Row>}
          <Row>
            <Col>
              {this.props.projects.length > 0
                ? this.props.projects
                  .sort(compare)
                  .map((item, index) => (
                    index >= this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT - PAGE_DISPLAY_ITEM_LIMIT &&
                  index < this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT
                  ) && <Project key={item.uuid} data={item} />)
                : 'No projects'}
            </Col>
          </Row>
          <Row>
            <Col>
              <Pagination
                basepath='/projects/p/'
                inheritedQuery={this.props.location.search}
                {...this.props.pagination}
              />
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

const mapStateToProps = ({ projects }, ownProps) => {
  return {
    syncedAt: projects.syncedAt,
    projects: projects.items
  }
}

// fetchProjects, syncProjects ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncProjectlist: () => dispatch(syncProjectlist())
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  let { projects } = propsFromState
  delete propsFromState.projects

  const search = qs.parse(ownProps.location.search)
  let filterFunc = () => true
  if (search.query) {
    filterFunc = (el) => el.name.toLowerCase().includes(search.query.toLowerCase())
  }
  projects = projects.filter(filterFunc)

  const pageMax = Math.ceil(projects.length / PAGE_DISPLAY_ITEM_LIMIT)
  let pageNumber = ownProps.match.params.pageNumber
    ? !isNaN(Number(ownProps.match.params.pageNumber))
      ? Number(ownProps.match.params.pageNumber)
      : 1
    : 1
  if (pageNumber < 1 || pageNumber > pageMax) {
    pageNumber = 1
  }

  return Object.assign(
    {},
    ownProps,
    propsFromState,
    propsFromDispatch,
    {
      pagination: {
        current: pageNumber,
        max: pageMax
      },
      projects,
      search
    }
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(List)
