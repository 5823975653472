import React from 'react'

import {
  Row,
  Col
} from 'reactstrap'

import UpdateForm from './UpdateForm'
import UpdateStatus from './UpdateStatus'
import Participants from './Participants'

const Settings = ({ match: { params } }) => {
  return (
    <div>
      <Row className='py-3 border-bottom'>
        <Col md={3}>
          Update project status
        </Col>
        <Col md={9}>
          <UpdateStatus project={params.projectUuid} />
        </Col>
      </Row>
      <UpdateForm uuid={params.projectUuid} />
      <Participants uuid={params.projectUuid} />
      ide átkerülnek a résztvevők szerkesztési lehetőségei
    </div>
  )
}

export default Settings
