// import functors
import { groups } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const GroupsMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'GROUPS') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'SYNC_LIST':
      chain(
        groups.syncList(getState().groups.items)
      )(dispatch)
      break
    case 'SYNC':
      chain(
        groups.syncGroup(
          action.payload.uuid,
          getState().groups.items
            .findIndex(item => item.uuid === action.payload.uuid)
        )
      )(dispatch)
      break
    case 'CREATE':
      chain(
        groups.create(action.payload.form, getState().user.uuid)
      )(dispatch)
      break
    case 'UPDATE':
      chain(
        groups.update(action.payload.group, action.payload.form)
      )(dispatch)
      break
    case 'DELETE':
      chain(
        groups.delete(action.payload.group)
      )(dispatch)
      break
    case 'MEMBER_ADD':
      chain(
        groups.addMember(action.payload.group, action.payload.member)
      )(dispatch)
      break
    case 'MEMBER_REMOVE':
      chain(
        groups.removeMember(action.payload.group, action.payload.member)
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default GroupsMiddleware
