import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { syncTask } from '../../actions/middleware/Tasks.Actions'
// import { } from 'reactstrap'

import { CONST_LIFETIME_TASK } from '../../constants/Lifetime.Constants'

class TaskShortcut extends PureComponent {
  componentDidMount () {
    const { task } = this.props
    // case 1: no task
    // case 2: not syncing task, task is outdated but not syncing
    if (
      (!task) ||
      (task.syncedAt + CONST_LIFETIME_TASK < Date.now() && !task.syncing)
    ) {
      this.props.syncTask()
    }
  }

  render () {
    const { task } = this.props
    return task ? (
      task.error === 'NO_MATCH'
        ? null
        : <Link to={'/task/' + task.uuid}>{task.name}</Link>
    ) : <span className='link-placeholder' />
  }
}

const mapStateToProps = ({ tasks }, ownProps) => ({
  task: tasks.find(u => u.uuid === ownProps.uuid)
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTask: () => dispatch(syncTask(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskShortcut)
