import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { syncGroup } from '../../actions/middleware/Groups.Actions'
import {
  Spinner
} from 'reactstrap'

import UserShortcut from './UserShortcut'

class GroupShortcut extends PureComponent {
  constructor () {
    super()

    this.thumbnailClassname = this.thumbnailClassname.bind(this)
  }

  thumbnailClassname () {
    const { group } = this.props
    return group ? ` _groupshortcut` : ''
  }

  componentDidMount () {
    const { group, listSyncing } = this.props
    // case 1: not syncing list, no group
    // case 2: not syncing list, group is outdated but not syncing
    if (
      (!listSyncing && !group) ||
      (!listSyncing && group.syncedAt + (10 * 1000) < Date.now() && !group.syncing)
    ) {
      this.props.syncGroup()
    }
  }

  render () {
    const { group, listSyncing } = this.props
    return group ? (
      group.error === 'NO_MATCH'
        ? null
        : <div className='d-flex p-2 mb-1 group-shortcut'>
          <div style={{ width: '2rem', height: '2rem' }} className={'d-flex overflow-hidden align-items-center justify-content-center rounded-circle mr-2' + this.thumbnailClassname()}>
            {(listSyncing || group.syncing)
              ? <Spinner size='sm' color='dark' />
              : (group.thumb.s
                ? <img src={group.thumb.s} alt='thumb' className='rounded w-100' />
                : <div
                  style={{
                    fontSize: '0.8rem',
                    fontWeight: '500',
                    lineHeight: '1'
                  }}
                  className='text-white'>
                  {group.name.charAt(0).toUpperCase()}
                </div>)}
          </div>
          <div>
            <Link to={'/group/' + this.props.group.uuid}>
              {this.props.group.name} {this.props.group.syncing && <Spinner size='sm' color='secondary' />}
            </Link>
            {this.props.group.members.includes(this.props.currentUser) &&
            <div><small>You are a member of this group</small></div>}
            <div className='d-flex'>
              {
                this.props.group.members
                  // TODO: .slice(0, 3)
                  .map(m => <UserShortcut
                    className='mb-1'
                    disabled
                    display={{ thumb: true, username: false, size: 'small' }}
                    uuid={m} />)
              }
            ({this.props.group.members.length} member)
            </div>
          </div>
        </div>
    ) : (
      <Spinner size='sm' color='secondary' />
    )
  }
}

const mapStateToProps = ({ groups, user: { uuid } }, ownProps) => ({
  currentUser: uuid,
  group: groups.items.find(g => g.uuid === ownProps.uuid),
  listSyncing: groups.syncing
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncGroup: () => dispatch(syncGroup(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupShortcut)
