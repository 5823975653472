export const addTicket = tsk => ({
  type: 'TICKETS_ADD',
  payload: tsk
})

export const updateTicket = (uuid, props) => ({
  type: 'TICKETS_UPDATE',
  payload: { uuid, props }
})

export const removeTicket = uuid => ({
  type: 'TICKETS_REMOVE',
  payload: uuid
})

export const setTicketSyncing = (uuid, syncing) => ({
  type: 'TICKETS_SET_SYNCING',
  payload: {
    uuid,
    syncing
  }
})

export const setTicketActivitiesSyncing = (uuid, syncing) => ({
  type: 'TICKETS_SET_ACTIVITIES_SYNCING',
  payload: {
    uuid,
    syncing
  }
})

export const setTicketFilesSyncing = (uuid, syncing) => ({
  type: 'TICKETS_SET_FILES_SYNCING',
  payload: {
    uuid,
    syncing
  }
})

export const addTicketAssignee = (uuid, assignee) => ({
  type: 'TICKETS_ADD_ASSIGNEE',
  payload: { uuid, assignee }
})

export const removeTicketAssignee = (uuid, assignee) => ({
  type: 'TICKETS_REMOVE_ASSIGNEE',
  payload: { uuid, assignee }
})
