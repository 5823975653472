import { connect } from 'react-redux'

const ProtectedComponent = ({ isAuthenticated, userRole, protectionOptions, children }) => {
  if (isAuthenticated) {
    if ((protectionOptions.group.exact && userRole.group !== protectionOptions.group.value) ||
        (!protectionOptions.group.exact && userRole.group < protectionOptions.group.value)) {
      return null
    }
    if ((protectionOptions.level.exact && userRole.level !== protectionOptions.level.value) ||
        (!protectionOptions.level.exact && userRole.level < protectionOptions.level.value)) {
      return null
    }
    return children
  } else {
    return null
  }
}

const mapStateToProps = state => ({
  isAuthenticated: !!state.user.uuid,
  userRole: state.user.role
})

export default connect(
  mapStateToProps,
  {}
)(ProtectedComponent)
