import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncUser } from '../../../../actions/middleware/Users.Actions'
import { syncGroup } from '../../../../actions/middleware/Groups.Actions'
import { assignUserToTicket, unassignUserFromTicket } from '../../../../actions/middleware/Tickets.Actions'

import UserShortcut from '../../../common/UserShortcut'

import Button from '../../../common/Button'

import {
  Row,
  Col,
  Modal,
  ModalBody
} from 'reactstrap'

// TODO: error, chaining syncs no project uuid while render link to phase

class Assignees extends Component {
  constructor () {
    super()

    this.state = {
      modal: false
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle (e) {
    e.preventDefault()
    this.setState({ modal: !this.state.modal })
  }

  componentDidUpdate (prevProps, prevState) {
    // sync each new elem
    for (let acc of this.props.accesses) {
      if (prevProps.accesses.findIndex(a => a === acc) === -1) {
        acc.entityType === 0
          ? this.props.syncUser(acc.entity)
          : this.props.syncGroup(acc.entity)
      }
    }
    // sync each new group's members
    for (let g of this.props.groups) {
      if (prevProps.groups.findIndex(prevG => prevG.uuid === g.uuid) === -1) {
        for (const m of g.members) {
          this.props.syncUser(m)
        }
      }
    }
  }

  render () {
    return (<div>
      <div className='d-flex align-items-center justify-content-between'>
        <small className='text-muted'>Assignees</small>
        <Button size='small' color='modify' onClick={this.toggle}>
          Edit
        </Button>
      </div>
      <div className='d-flex'>
        {this.props.assigned.length > 0
          ? this.props.assigned.map(as =>
            <UserShortcut
              key={as}

              display={{ thumb: true, username: false, size: 'small' }}
              uuid={as} />)
          : <i className='text-muted'>Not assigned</i>}
      </div>
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        size='lg'
        scrollable
        backdrop
        keyboard>
        <ModalBody>
          <Row>
            <Col className='mb-3'>
              <h5>Munkatársak hozzárendelése vagy eltávolítása</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6 className='mb-3'>
                Projektben résztvevő munkatársak
              </h6>
              {this.props.users
                .filter(u => this.props.assigned.findIndex(a => a === u.uuid) === -1)
                .map(user => <Row
                  key={user.uuid}
                  className='mb-3'>
                  <Col className='d-flex align-items-center justify-content-between'>
                    <UserShortcut
                      disabled
                      display={{ thumb: true, username: true, name: true, size: 'large' }}
                      uuid={user.uuid} />
                    <Button
                      onClick={e => this.props.assignUser(user.uuid)}
                      size='small'
                      color='add'>
                        Add
                    </Button>
                  </Col>
                </Row>)}
            </Col>
            <Col>
              <h6 className='mb-3'>
                Hozzárendelt munkatársak
              </h6>
              {this.props.users
                .filter(u => this.props.assigned.findIndex(a => a === u.uuid) > -1)
                .map(user => <Row
                  className='mb-3'
                  key={user.uuid}>
                  <Col className='d-flex align-items-center justify-content-between'>
                    <UserShortcut
                      disabled
                      display={{ thumb: true, username: true, name: true, size: 'large' }}
                      uuid={user.uuid} />
                    <Button
                      onClick={e => this.props.unassignUser(user.uuid)}
                      size='small'
                      color='remove'>
                      Remove
                    </Button>
                  </Col>
                </Row>)}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>)
  }
}

const mapStateToProps = ({ users, groups }, ownProps) => {
  return {
    users: users.items
      .filter(u => u.role.group > 1),
    groups: groups.items
      .filter(g => ownProps.accesses
        .findIndex(a => a.entity === g.uuid) > -1 &&
        g.roleGroup > 1)
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => {
  const task = ownProps.task
  return {
    syncUser: uuid => dispatch(syncUser(uuid)),
    syncGroup: uuid => dispatch(syncGroup(uuid)),
    assignUser: uuid => dispatch(assignUserToTicket(task, uuid)),
    unassignUser: uuid => dispatch(unassignUserFromTicket(task, uuid))
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { users, groups } = stateProps
  let normalUserArr = []
  let groupUserArr = []
  for (const a of ownProps.accesses) {
    a.entityType === 0 && normalUserArr.push(a.entity)
  }
  for (const g of groups) {
    groupUserArr = groupUserArr.concat(g.members)
  }
  let mergeArray = [...normalUserArr, ...groupUserArr]
  mergeArray = mergeArray.filter((item, index) => mergeArray.indexOf(item) === index)

  // merge into one user array
  return Object.assign(
    {},
    ownProps,
    dispatchProps,
    {
      groups, // for bg sync
      users: users
        .filter(u => mergeArray.indexOf(u.uuid) > -1)
    }
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Assignees)
