export const addGroup = gr => ({
  type: 'GROUPS_ADD_ITEM',
  payload: gr
})

export const updateGroup = gr => ({
  type: 'GROUPS_UPDATE_ITEM',
  payload: gr
})

export const removeGroup = uuid => ({
  type: 'GROUPS_REMOVE_ITEM',
  payload: uuid
})

export const setGroupsSyncedAt = syncedAt => ({
  type: 'GROUPS_SET_SYNCED_AT',
  payload: syncedAt
})

export const setGroupsSyncing = isSyncing => ({
  type: 'GROUPS_SET_SYNCING',
  payload: isSyncing
})

export const addGroupMember = (uuid, member) => ({
  type: 'GROUPS_ADD_MEMBER',
  payload: {
    uuid,
    member
  }
})

export const removeGroupMember = (uuid, member) => ({
  type: 'GROUPS_REMOVE_MEMBER',
  payload: {
    uuid,
    member
  }
})

export const setGroupSyncing = (uuid, isSyncing) => ({
  type: 'GROUPS_SET_GROUP_SYNCING',
  payload: {
    uuid,
    isSyncing
  }
})
