import React, { Component } from 'react'
import { connect } from 'react-redux'
import qs from 'query-string'

import { syncTasks } from '../../../../actions/middleware/Projects.Actions'

import { Link } from 'react-router-dom'

import {
  Row,
  Col,
  Badge
} from 'reactstrap'

import {
  CONST_TASK_STATUSES,
  CONST_TASK_PRIORITIES
} from '../../../../constants/Task.Constants'

import Button from '../../../common/Button'
import Pagination from '../../../common/Pagination'
import ProtectedComponent from '../../../common/ProtectedComponent'
import Task from './components/Task'
import Filters from './components/Filters'

// add constant!
const PAGE_DISPLAY_ITEM_LIMIT = 10

const compare = (a, b) => {
  const pA = a.updatedAt
  const pB = b.updatedAt

  let comparison = 0
  if (pA < pB) {
    comparison = 1
  } else if (pA > pB) {
    comparison = -1
  }
  return comparison
}

class List extends Component {
  constructor () {
    super()

    this._statusBadge = this._statusBadge.bind(this)
    this._priorityBadge = this._priorityBadge.bind(this)
  }

  _statusBadge (status) {
    const { label } = CONST_TASK_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _taskstatus-${status}`}>
        {label}
      </Badge>
    )
  }

  _priorityBadge (priority) {
    const { label } = CONST_TASK_PRIORITIES.find(pr => pr.id === priority)
    return (
      <Badge className={`mr-2 _taskpriority-${priority}`}>
        {label}
      </Badge>
    )
  }

  componentDidMount () {
    if (!this.props.syncing || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncTasks()
    }
  }

  render () {
    return (
      <Row>
        <Col>
          <Row className='my-3'>
            <Col>
              <Filters uuid={this.props.match.params.projectUuid} />
            </Col>
            <Col md={3} className='d-flex justify-content-end align-items-center'>
              <ProtectedComponent
                protectionOptions={{
                  group: { value: 2, exact: false },
                  level: { value: 1, exact: false }
                }}>
                <Button
                  tag={Link}
                  size='normal'
                  color='add'
                  to={`/project/${this.props.match.params.projectUuid}/tasks/add`}
                >
                Create new task
                </Button>
              </ProtectedComponent>
            </Col>
          </Row>
          {this.props.tasks.length > 0 && <Row>
            <Col>
              <small>
              Showing {this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT - PAGE_DISPLAY_ITEM_LIMIT + 1}-
                {(this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT) <= this.props.tasks.length
                  ? (this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT)
                  : this.props.tasks.length}{' '}
              / {this.props.tasks.length} tasks
              </small>
            </Col>
          </Row>}
          {Object.keys(this.props.search).length > 0 && <Row>
            <Col>
              Search results for:{' '}
              {this.props.search.query &&
                <b>"{this.props.search.query}"</b>}
              {this.props.search.status !== undefined &&
                <b>állapot: {this._statusBadge(this.props.search.status)}</b>}
              {this.props.search.priority !== undefined &&
                <b>prioritás: {this._priorityBadge(this.props.search.priority)}</b>}
            </Col>
          </Row>}
          {this.props.tasks.length > 0
            ? this.props.tasks
              .sort(compare)
              .map((item, index) => (
                index >= this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT - PAGE_DISPLAY_ITEM_LIMIT &&
                  index < this.props.pagination.current * PAGE_DISPLAY_ITEM_LIMIT
              ) && <Task key={item.uuid} data={item} />)
            : 'No tasks'}
          <Row>
            <Col>
              <Pagination
                basepath={`/project/${this.props.match.params.projectUuid}/tasks/p/`}
                inheritedQuery={this.props.location.search}
                {...this.props.pagination}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ projects, tasks }, ownProps) => {
  return {
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid),
    tasks: tasks
      .filter(p => p.project === ownProps.match.params.projectUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  // from time flag?
  syncTasks: () => dispatch(syncTasks(ownProps.match.params.projectUuid))
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  let { project, tasks } = propsFromState
  let syncing = project ? project.tasks.syncing : false
  let syncedAt = project ? project.tasks.syncedAt : Date.now() - 10

  delete propsFromState.tasks

  const search = qs.parse(ownProps.location.search)
  if (search.query) {
    tasks = tasks.filter((el) => el.name.toLowerCase().includes(search.query.toLowerCase()))
  }
  if (search.status) {
    const status = Number(search.status)
    if (status >= 0 && status <= 2) {
      tasks = tasks.filter((el) => el.status === status)
      search.status = status
    } else {
      // invalid
      delete search.status
    }
  }
  if (search.priority) {
    const priority = Number(search.priority)
    if (priority >= 0 && priority <= 2) {
      tasks = tasks.filter((el) => el.priority === priority)
      search.priority = priority
    } else {
      // invalid
      delete search.priority
    }
  }

  const pageMax = Math.ceil(tasks.length / PAGE_DISPLAY_ITEM_LIMIT)
  let pageNumber = ownProps.match.params.pageNumber
    ? !isNaN(Number(ownProps.match.params.pageNumber))
      ? Number(ownProps.match.params.pageNumber)
      : 1
    : 1
  if (pageNumber < 1 || pageNumber > pageMax) {
    pageNumber = 1
  }

  return Object.assign({},
    ownProps,
    propsFromDispatch,
    {
      syncing,
      syncedAt,
      tasks,
      pagination: {
        current: pageNumber,
        max: pageMax
      },
      search
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(List)
