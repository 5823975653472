
import React, { PureComponent } from 'react'

import ReactMarkdown from 'react-markdown'
import linkify from 'markdown-linkify'

import UserShortcut from './UserShortcut'
import ProjectShortcut from './ProjectShortcut'
import TaskShortcut from './TaskShortcut'
import TicketShortcut from './TicketShortcut'
import FileShortcut from './FileShortcut'

import { Badge } from 'reactstrap'

import {
  CONST_TASK_STATUSES,
  CONST_TASK_PRIORITIES
} from '../../constants/Task.Constants'

import {
  CONST_TICKET_STATUSES
} from '../../constants/Ticket.Constants'

import {
  CONST_PROJECT_STATUSES
} from '../../constants/Project.Constants'

const ActivityContainer = ({ date, children }) => (
  <div className='activity-container w-100 position-relative border rounded mb-4 shadow-sm'>
    <div className='activity-date-flag px-2'>
      <small className='text-muted'>{new Date(date).toLocaleString()}</small>
    </div>
    <div className='activity-content p-3'>
      {children}
    </div>
  </div>
)

class TruncatedText extends PureComponent {
  constructor () {
    super()
    this.state = { hide: true }
  }

  render () {
    const { hide } = this.state
    const { text } = this.props
    return (
      <span>
        {hide
          ? text.substring(0, 128) + '...'
          : text}
        <button onClick={() => this.setState({ hide: !hide })}>{hide ? 'More' : 'Less'}</button>
      </span>
    )
  }
}

const ScopeHandler = ({ scope, task, project, ticket }) => {
  switch (scope) {
    case 'root':
      return (
        <>
          {task && <>task <TaskShortcut uuid={task} /></>}
          {ticket && <>ticket <TicketShortcut uuid={ticket} /></>}
          {(!task && !ticket) ? ' project ' : ' in project '}<ProjectShortcut uuid={project} />
        </>
      )
    case 'project':
      return (
          <>
            {task && <>task <TaskShortcut uuid={task} /></>}
            {ticket && <>ticket <TicketShortcut uuid={ticket} /></>}
            {(!task && !ticket) && 'project'}
          </>
      )
    case 'task':
      return 'task'
    case 'ticket':
      return 'ticket'
    default:
      return null
  }
}

const Activity = props => {
  let { scope } = props
  if (!scope) scope = 'root'

  switch (props.changelog.type) {
    case 'TASK_CREATED': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            created <ScopeHandler {...props} scope={scope} />{' '}
        </ActivityContainer>
      )
    }
    case 'TASK_STATUS_UPDATE': {
      const from = CONST_TASK_STATUSES.find(st => st.id === props.changelog.from)
      const to = CONST_TASK_STATUSES.find(st => st.id === props.changelog.to)
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
          updated the status of <ScopeHandler {...props} scope={scope} />{' '}
          from <Badge className={`_taskstatus-${from.id}`}>{from.label}</Badge>{' '}
          to <Badge className={`_taskstatus-${to.id}`}>{to.label}</Badge>{' '}
        </ActivityContainer>
      )
    }
    case 'TASK_ASSIGN': {
      const whom = props.changelog.whom
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            assigned to <ScopeHandler {...props} scope={scope} />{' '}
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={whom} />{' '}
        </ActivityContainer>
      )
    }
    case 'TASK_UNASSIGN': {
      const whom = props.changelog.whom
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            unassigned from <ScopeHandler {...props} scope={scope} />{' '}
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={whom} />{' '}
        </ActivityContainer>
      )
    }
    case 'TASK_UPDATE_PROPERTIES': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            updated the properties of <ScopeHandler {...props} scope={scope} />{' '}
            :
          {props.changelog.properties.map((act, index) =>
            <Activity key={index} scope={'task'} changelog={{ ...act }} />)}
        </ActivityContainer>
      )
    }
    case 'TASK_UPDATE_NAME': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated the name of <ScopeHandler {...props} scope={scope} /> from <i>{from}</i>{' '}
          to <i>{to}</i>
        </div>
      )
    }
    case 'TASK_UPDATE_DESCRIPTION': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated the description of <ScopeHandler {...props} scope={scope} /> from <i>{from.length > 128 ? <TruncatedText text={from} /> : from}</i>{' '}
          to <i>{to.length > 128 ? <TruncatedText text={to} /> : to}</i>
        </div>
      )
    }
    case 'TASK_UPDATE_PHASE': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated task phase from <i>{from}</i>{' '}
          to <i>{to}</i>
        </div>
      )
    }
    case 'TASK_UPDATE_PRIORITY': {
      const from = CONST_TASK_PRIORITIES.find(p => p.id === props.changelog.from)
      const to = CONST_TASK_PRIORITIES.find(p => p.id === props.changelog.to)
      return (
        <div className='subsequence'>
          updated task priority from <Badge className={`_taskpriority-${from.id}`}>{from.label}</Badge>{' '}
          to <Badge className={`_taskpriority-${to.id}`}>{to.label}</Badge>{' '}
        </div>
      )
    }
    case 'TASK_UPDATE_ESTIMATED_WORK': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated task estimated work from <i>{from}</i>{' '}
          to <i>{to}</i>
        </div>
      )
    }
    case 'TASK_COMMENT': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            commented <ScopeHandler {...props} scope={scope} />{' '}
          <div className='w-100'>
            <ReactMarkdown
              className='bg-light rounded p-3'
              source={linkify(props.changelog.body)} />
          </div>
        </ActivityContainer>
      )
    }
    case 'TICKET_CREATED': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            created <ScopeHandler {...props} scope={scope} />{' '}
        </ActivityContainer>
      )
    }
    case 'TICKET_STATUS_UPDATE': {
      const from = CONST_TICKET_STATUSES.find(st => st.id === props.changelog.from)
      const to = CONST_TICKET_STATUSES.find(st => st.id === props.changelog.to)
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
          updated the status of <ScopeHandler {...props} scope={scope} />{' '}
          from <Badge className={`_ticketstatus-${from.id}`}>{from.label.generic} - {from.label.detailed}</Badge>{' '}
          to <Badge className={`_ticketstatus-${to.id}`}>{to.label.generic} - {to.label.detailed}</Badge>{' '}
        </ActivityContainer>
      )
    }
    case 'TICKET_ASSIGN': {
      const whom = props.changelog.whom
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            assigned to <ScopeHandler {...props} scope={scope} />{' '}
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={whom} />{' '}
        </ActivityContainer>
      )
    }
    case 'TICKET_UNASSIGN': {
      const whom = props.changelog.whom
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            unassigned from <ScopeHandler {...props} scope={scope} />{' '}
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={whom} />{' '}
        </ActivityContainer>
      )
    }
    case 'TICKET_UPDATE_PROPERTIES': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            updated the properties of <ScopeHandler {...props} scope={scope} />{' '}
            :
          {props.changelog.properties.map((act, index) =>
            <Activity key={index} scope={'ticket'} changelog={{ ...act }} />)}
        </ActivityContainer>
      )
    }
    case 'TICKET_UPDATE_NAME': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated the name of <ScopeHandler {...props} scope={scope} /> from <strike style={{ color: 'rgba(0,0,0,.2)' }}><i className='text-dark'>{from}</i></strike>{' '}
          to <i>{to}</i>
        </div>
      )
    }
    case 'TICKET_UPDATE_DESCRIPTION': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated the description of <ScopeHandler {...props} scope={scope} />  from <i>{from.length > 128 ? <TruncatedText text={from} /> : from}</i>{' '}
          to <i>{to.length > 128 ? <TruncatedText text={to} /> : to}</i>
        </div>
      )
    }
    case 'TICKET_COMMENT': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            commented <ScopeHandler {...props} scope={scope} />{' '}
          <div className='w-100'>
            <ReactMarkdown
              className='bg-light rounded p-3'
              source={linkify(props.changelog.body)} />
          </div>
        </ActivityContainer>
      )
    }
    case 'PROJECT_CREATED': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            created <ScopeHandler {...props} scope={scope} />{' '}
        </ActivityContainer>
      )
    }
    case 'PROJECT_STATUS_UPDATE': {
      const from = CONST_PROJECT_STATUSES.find(st => st.id === props.changelog.from)
      const to = CONST_PROJECT_STATUSES.find(st => st.id === props.changelog.to)
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
          updated the status of <ScopeHandler {...props} scope={scope} />{' '}
          from <Badge className={`_projectstatus-${from.id}`}>{from.label}</Badge>{' '}
          to <Badge className={`_projectstatus-${to.id}`}>{to.label}</Badge>{' '}

        </ActivityContainer>
      )
    }
    case 'PROJECT_UPDATE_PROPERTIES': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            updated the properties of <ScopeHandler {...props} scope={scope} />{' '}
            :
          {props.changelog.properties.map((act, index) =>
            <Activity key={index} scope={'ticket'} changelog={{ ...act }} />)}
        </ActivityContainer>
      )
    }
    case 'PROJECT_UPDATE_NAME': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated the name of <ScopeHandler {...props} scope={scope} /> from <strike style={{ color: 'rgba(0,0,0,.2)' }}><i className='text-dark'>{from}</i></strike>{' '}
          to <i>{to}</i>
        </div>
      )
    }
    case 'PROJECT_UPDATE_DESCRIPTION': {
      const { from, to } = props.changelog
      return (
        <div className='subsequence'>
          updated the description of <ScopeHandler {...props} scope={scope} />  from <i>{from.length > 128 ? <TruncatedText text={from} /> : from}</i>{' '}
          to <i>{to.length > 128 ? <TruncatedText text={to} /> : to}</i>
        </div>
      )
    }
    case 'FILE_UPLOAD': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            uploaded file <FileShortcut uuid={props.changelog.file} /> to <ScopeHandler {...props} scope={scope} />{' '}
        </ActivityContainer>
      )
    }
    case 'FILE_REMOVED': {
      return (
        <ActivityContainer date={props.triggeredAt}>
          <UserShortcut display={{ thumb: true, username: true, size: 'small' }} className='d-inline-block' uuid={props.triggeredBy} />{' '}
            removed file <FileShortcut uuid={props.changelog.file} /> from <ScopeHandler {...props} scope={scope} />{' '}
        </ActivityContainer>
      )
    }
    default:
      return (
        <ActivityContainer date={props.triggeredAt}>
          <b>activity other type ({ /* JSON.stringify(props) */ })</b>
        </ActivityContainer>
      )
  }
}

export default React.memo(Activity)
