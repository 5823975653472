
import {
  updateTicket
} from '../../../actions/reducer/Ticket.Actions'

const updateStatus = (ticket, status) => (dispatch, _Backend) => {
  return _Backend.call('updateTicketStatus', ticket, status)
    .then((result) => {
      // TODO: update ticket!
      dispatch(updateTicket(ticket, { status }))
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
}

export default updateStatus
