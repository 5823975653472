const initialState = []

const FilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FILES_ADD':
      return state.some(el => el.uuid === action.payload.uuid)
        ? state
        : [...state, action.payload]
    case 'FILES_UPDATE':
      return state.map(p => {
        if (p.uuid === action.payload.uuid) {
          return Object.assign({}, p, action.payload.props)
        } else {
          return p
        }
      })
    case 'FILES_REMOVE':
      return state.filter(p => p.uuid !== action.payload)
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default FilesReducer
