import { createFileObject } from '../../../objects/create'
import { addFile } from '../../../actions/reducer/Files.Actions'
import {
  setFormSubmitting,
  resetForm,
  setFormMessage,
  setFormErrors
} from '../../../actions/reducer/Forms.Actions'

const upload = (
  project,
  {
    FormId,
    FormFields: {
      task, ticket, file
    }
  }) => (dispatch, _Backend) => {
  dispatch(resetForm(FormId))
  dispatch(setFormSubmitting(FormId, true))

  // maybe some pre-validation
  const form = {}
  if (task) form.task = task
  if (ticket) form.ticket = ticket
  if (file) form.file = file

  console.log('itt?')

  return _Backend.call('uploadFile', project, form)
    .then(fileDescriptor => {
      console.log('itt? kész')
      dispatch(
        addFile(createFileObject(fileDescriptor))
      )
      dispatch(setFormMessage(FormId, 'TXT_UPLOAD_FILE_FORM__SUCCESS_MESSAGE'))
      return Promise.resolve()
    })
    .catch(err => {
      console.log('itt? error')
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['TXT_UPLOAD_FILE_FORM__ERR_GENERIC']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          for (const error of errorArr) {
            errors.push('TXT_UPLOAD_FILE_FORM__' + error)
          }
          return errors
        }
      }
      dispatch(setFormErrors(FormId, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default upload
