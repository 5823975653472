import React from 'react'

import { CONST_ROLE_GROUPS } from '../../../../constants/Role.Constants'

import { Button } from 'reactstrap'

const ManageLevel = ({ update, role }) => {
  return <div>
    actual: {role.level}
    <hr />
    {CONST_ROLE_GROUPS
      .find(g => g.id === role.group)
      .levels
      .map(lev =>
        <Button
          onClick={() => update(lev.id)}
          disabled={lev.id === role.level}
        >
          Change user's level to: {lev.label}
        </Button>)
    }
  </div>
}

export default ManageLevel
