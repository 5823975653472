import React, { Component } from 'react'
import { connect } from 'react-redux'

import { uploadFile } from '../../../../../actions/middleware/Files.Actions'
import { resetForm } from '../../../../../actions/reducer/Forms.Actions'

import {
  Form,
  FormGroup,
  Input,
  FormText,
  Alert
} from 'reactstrap'

import Button from '../../../../common/Button'

import Preview from '../../../../common/PreviewFile'

class Upload extends Component {
  constructor () {
    super()

    this.state = {
      file: null
    }

    this.formRef = React.createRef()

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeFile = this.handleChangeFile.bind(this)
    this.handleRejectFile = this.handleRejectFile.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { file } = this.state
    if (file) file = file.file
    this.props.uploadFile(file)
  }

  async handleChangeFile (e) {
    let _file = e.target.files[0]
    const file = {
      file: _file,
      name: _file.name,
      size: _file.size
    }
    this.setState({
      file
    })
    this.props.resetForm()
  }

  handleRejectFile (e) {
    this.setState({
      file: null
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.form.submitting &&
      !this.props.form.submitting &&
      this.props.form.errors.length === 0) {
      this.formRef.current.reset()
      this.setState({ file: null })
    }
  }

  render () {
    return (
      <Form
        innerRef={this.formRef}
        onSubmit={this.handleSubmit}
        encType='multipart/form-data'
        className='p-3'>
        {this.props.form.message &&
          <Alert color='success' className='text-center'>
            {this.props.form.message}
          </Alert>}
        {this.props.form.errors.length > 0 &&
          <Alert color='danger' className='text-center'>
            {this.props.form.errors.map(el => el).join(' ')}
          </Alert>}
        <FormGroup>
          {this.state.file
            ? <Preview
              handleReject={this.handleRejectFile}
              details={this.state.file} />
            : <Input
              type='file'
              name='upload-file'
              disabled={this.props.form.submitting}
              accept='image/*,.doc,.docx,.pdf,.zip'
              id='upload-file'
              onChange={this.handleChangeFile} />
          }
          <FormText color='muted'>
            Maximum file upload size is 8MB.
          </FormText>
        </FormGroup>
        <Button color='add'
          type='submit'
          tabIndex={3}
          disabled={this.state.file === null || this.props.form.submitting}
        >
          Upload
        </Button>
      </Form>
    )
  }
}

const mapStateToProps = ({ form, user }, ownProps) => ({
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  uploadFile: file => dispatch(uploadFile(ownProps.project, {
    FormId: ownProps.form.id,
    FormFields: {
      file
    }
  })),
  resetForm: () => dispatch(resetForm(ownProps.form.id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Upload)
