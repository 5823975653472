export const createTask = (project, form) => ({
  type: 'TASKS',
  payload: {
    operation: 'CREATE',
    project,
    form
  }
})

export const syncTask = uuid => ({
  type: 'TASKS',
  payload: {
    operation: 'SYNC',
    uuid
  }
})

export const syncTaskActivities = uuid => ({
  type: 'TASKS',
  payload: {
    operation: 'SYNC_ACTIVITIES',
    uuid
  }
})

export const syncTaskFiles = uuid => ({
  type: 'TASKS',
  payload: {
    operation: 'SYNC_FILES',
    uuid
  }
})

export const assignUser = (task, user) => ({
  type: 'TASKS',
  payload: {
    operation: 'ASSIGN',
    task,
    user
  }
})

export const unassignUser = (task, user) => ({
  type: 'TASKS',
  payload: {
    operation: 'UNASSIGN',
    task,
    user
  }
})

export const updateTaskStatus = (task, status) => ({
  type: 'TASKS',
  payload: {
    operation: 'UPDATE_STATUS',
    task,
    status
  }
})

export const updateTaskProperties = (task, form) => ({
  type: 'TASKS',
  payload: {
    operation: 'UPDATE_PROPERTIES',
    task,
    form
  }
})

export const commentTask = (task, form) => ({
  type: 'TASKS',
  payload: {
    operation: 'COMMENT',
    task,
    form
  }
})
