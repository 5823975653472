import React from 'react'
import { connect } from 'react-redux'

import { updateTicketStatus } from '../../../../actions/middleware/Tickets.Actions'

import {
  Button
} from 'reactstrap'

const UpdateStatus = ({ status, role: { group, level }, update }) => {
  switch (status) {
    case 0: {
      // open - new
      // coworker and customer can invalidate
      // coworker can accept for pending status
      if (group === 1) {
        return (
          <Button onClick={() => update(4)}>
            Close ticket
          </Button>
        )
      } else {
        return (
          <>
            <Button onClick={() => update(1)}>
              Approve
            </Button>
            <Button onClick={() => update(4)}>
              Reject and close
            </Button>
          </>
        )
      }
    }
    case 1: {
      // open - pending
      // customer see disabled button
      // coworker can accept for pending status
      if (group === 1) {
        return (
          <Button disabled>
            Pending...
          </Button>
        )
      } else {
        return (
          <>
            <Button onClick={() => update(2)}>
              Start working on this ticket
            </Button>
          </>
        )
      }
    }
    case 2: {
      // open - in progress
      // customer see disabled button
      // coworker can pause progress or resolve status
      if (group === 1) {
        return (
          <Button disabled>
            In progress...
          </Button>
        )
      } else {
        return (
          <>
            <Button onClick={() => update(3)}>
              Resolve
            </Button>
            <Button onClick={() => update(1)}>
              Switch back to pending status
            </Button>
          </>
        )
      }
    }
    case 3:
    case 4: {
      // closed - resolved and closed - rejected
      // customer see disabled button
      // coworker can pause progress or resolve status
      return (
        <Button onClick={() => update(1)}>
          Re-open this ticket
        </Button>
      )
    }
    default:
      return null
  }
}

const mapStateToProps = ({ user, form }) => ({
  role: user.role,
  submitting: form.submitting
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  update: status => dispatch(updateTicketStatus(ownProps.ticket, status))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateStatus)
