import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncTicketFiles } from '../../../../actions/middleware/Tickets.Actions'
import FileListItem from '../../../common/FileListItem'

class FileList extends Component {
  componentDidMount () {
    if (this.props.syncedAt + (10 * 1000) < Date.now() && !this.props.syncing) {
      this.props.syncTicketFiles()
    }
  }

  render () {
    return (<div>
      {this.props.files
        .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : -1)
        .map(file =>
          <FileListItem
            key={file.uuid}
            withLink={false}
            {...file}
          />
        )
      }
    </div>)
  }
}

const mapStateToProps = ({ files }, ownProps) => {
  return {
    files: files
      .filter(a => a.ticket === ownProps.uuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const ticket = ownProps.uuid
  return {
    syncTicketFiles: () => dispatch(syncTicketFiles(ticket))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileList)
