import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { syncUserlist } from '../../../../actions/middleware/Users.Actions'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import Breadcrumbs from '../../../common/Breadcrumbs'
import UserShortcut from '../../../common/UserShortcut'

class List extends Component {
  constructor () {
    super()
    this.state = {}
  }

  componentDidMount () {
    if (!this.props.syncedAt || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncUserlist()
    }
  }

  render () {
    return (
      <Container>
        <Row>
          <Col>
            <Breadcrumbs items={[
              { label: 'Community', link: '/community' },
              { label: 'Users', link: '/community/users' }
            ]} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Users</h3>
            <Link to='/community/users/invite'>Invite user</Link>
          </Col>
        </Row>
        <Row>
          {this.props.users.map(u =>
            <Col
              key={u.uuid}
              className='d-flex'
              lg={3}
              md={4}
              sm={6}>
              <div className='align-self-stretch w-100 border rounded mb-4 p-2 shadow-sm'>
                <UserShortcut uuid={u.uuid}
                  display={{ thumb: true, username: true, size: 'large' }} />
              </div>
            </Col>)}
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = ({ users }, ownProps) => {
  return {
    syncedAt: users.syncedAt,
    users: users.items
  }
}

// syncUserEntities
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncUserlist: () => dispatch(syncUserlist())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List)
