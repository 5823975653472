import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { resetForm } from '../../../actions/reducer/Form.Actions'

import { syncTask, updateTaskProperties } from '../../../actions/middleware/Tasks.Actions'
import { syncProject, syncPhases } from '../../../actions/middleware/Projects.Actions'

import {
  CONST_TASK_STATUSES,
  CONST_TASK_PRIORITIES
} from '../../../constants/Task.Constants'

import NoMatch from '../noMatch/NoMatch'

import {
  Alert,
  Row,
  Col,
  Container,
  Badge,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  CustomInput,
  Button,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'

// TODO: error, chaining syncs no project uuid while render link to phase

class UpdateTask extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)
    this._priorityBadge = this._priorityBadge.bind(this)

    this.state = {
      name: '',
      description: '',
      estimatedWork: 1,
      phase: '',
      priority: 0,
      isValidName: false,
      isValidDescription: false,
      phasesLoaded: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)

    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeDescription = this.handleChangeDescription.bind(this)
    this.handleChangePriority = this.handleChangePriority.bind(this)
    this.handleChangePhase = this.handleChangePhase.bind(this)
    this.handleChangeEstimatedWork = this.handleChangeEstimatedWork.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let {
      name,
      description,
      estimatedWork,
      phase,
      priority
    } = this.state
    this.props.updateTaskProperties({
      name,
      description,
      estimatedWork,
      phase,
      priority
    })
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangeDescription (e) {
    let description = e.target.value
    this.setState({
      description,
      isValidDescription: !!description
    })
  }

  handleChangePriority (e) {
    let priority = Number(e.target.value)
    this.setState({
      priority
    })
  }

  handleChangePhase (e) {
    let phase = e.target.value
    this.setState({
      phase
    })
  }

  handleChangeEstimatedWork (e) {
    let estimatedWork = Number(e.target.value)
    this.setState({
      estimatedWork
    })
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  componentDidMount () {
    if (!this.props.task ||
      (this.props.task.syncedAt + (10 * 1000) < Date.now() && !this.props.task.syncing)) {
      this.props.syncTask()
    } else if (!this.props.project || !this.props.phase) {
      // TODO: better solution for sync chain
      const { project } = this.props.task
      this.props.syncProject(project)
      this.props.syncPhases(project)
      // and also set state from props
      this.setState({
        name: this.props.task.name,
        description: this.props.task.description,
        estimatedWork: this.props.task.estimatedWork,
        phase: this.props.task.phase,
        priority: this.props.task.priority,
        isValidName: true,
        isValidDescription: true
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // TODO: better solution for sync chain
    if (prevProps.task && prevProps.task.syncing && !this.props.task.syncing) {
      this.setState({
        name: this.props.task.name,
        description: this.props.task.description,
        estimatedWork: this.props.task.estimatedWork,
        phase: this.props.task.phase,
        priority: this.props.task.priority,
        isValidName: true,
        isValidDescription: true
      })

      const { project } = this.props.task
      this.props.syncProject(project)
      this.props.syncPhases(project)
    }
  }

  _statusBadge (status) {
    const { label } = CONST_TASK_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _taskstatus-${status}`}>
        {label}
      </Badge>
    )
  }

  _priorityBadge (priority) {
    const { label } = CONST_TASK_PRIORITIES.find(pr => pr.id === priority)
    return (
      <Badge className={`mr-2 _taskpriority-${priority}`}>
        {label}
      </Badge>
    )
  }

  render () {
    const existInState = !!this.props.task
    const { isValidName, isValidDescription } = this.state
    const canSubmit = true // isValidName && isValidDescription && !this.props.phasesSyncing
    return existInState
      ? (
        this.props.task.error === 'NO_MATCH'
          ? <NoMatch />
          : (!this.props.task.syncing && <Container>
            <Row className='mt-3'>
              <Col>
                <Breadcrumb className='bg-light rounded'>
                  <BreadcrumbItem><Link to={'/projects'}>Projects</Link></BreadcrumbItem>
                  {(this.props.project) && <>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid}>{this.props.project.name}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid + '/tasks'}>Tasks</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/task/' + this.props.task.uuid}>{this.props.task.name}</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Update task</BreadcrumbItem>
                </>}
                </Breadcrumb>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col md={{ size: 6, offset: 3 }}>
                <h4>Update properties of task: {this.props.task.name}</h4>
                {this._statusBadge(this.props.task.status)}<small className='text-muted'>ID {this.props.task.uuid}</small>
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 6, offset: 3 }}>
                <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
                  {this.props.errors.length > 0 &&
                  <Alert color='danger' className='text-center'>
                    {this.props.errors.map(el => el).join(' ')}
                  </Alert>}
                  <FormGroup>
                    <Label for='task-name'>Task name</Label>
                    <Input
                      type='text'
                      maxLength={128}
                      defaultValue={this.props.task.name}
                      name='task-name'
                      id='task-name'
                      tabIndex={1}
                      placeholder='Task name'
                      disabled={this.props.submitting}
                      invalid={!!this.state.name && !isValidName}
                      onChange={this.handleChangeName}
                      required />
                    <FormText color='muted'>
                  128/{this.state.name.length}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for='task-description'>Description</Label>
                    <Input
                      type='textarea'
                      maxLength={10000}
                      name='task-description'
                      defaultValue={this.props.task.description}
                      id='task-description'
                      tabIndex={2}
                      placeholder='description'
                      invalid={!!this.state.description && !isValidDescription}
                      onChange={this.handleChangeDescription}
                      disabled={this.props.submitting}
                      required />
                    <FormText color='muted'>
                      10000/{this.state.description.length}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for='project-description'>Priority (current: {this._priorityBadge(this.props.task.priority)})</Label>
                    <div>
                      {CONST_TASK_PRIORITIES
                        .map(pri => <CustomInput
                          type='radio'
                          value={pri.id}
                          key={pri.id}
                          id={'task-priority-' + pri.id}
                          onChange={this.handleChangePriority}
                          defaultChecked={pri.id === this.props.task.priority}
                          name='task-priority'
                          label={this._priorityBadge(pri.id)} />)}
                    </div>
                    <FormText color='muted'>
                      Select priority.
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for='estimated-work'>Estimated work (in hours, 0 means not set)</Label>
                    <Input
                      type='number'
                      max={200}
                      min={0}
                      name='estimated-work'
                      id='estimated-work'
                      tabIndex={1}
                      defaultValue={this.props.task.estimatedWork}
                      placeholder='(hours)'
                      disabled={this.props.submitting}
                      onChange={this.handleChangeEstimatedWork}
                    />
                    <FormText color='muted'>
                      128/{this.state.name.length}
                    </FormText>
                  </FormGroup>
                  todo: deadline
                  {this.props.phases && <FormGroup>
                    <Label for='project-description'>Phase</Label>
                    <div>
                      {this.props.phases
                        .map(phase => <CustomInput
                          type='radio'
                          key={phase.uuid}
                          value={phase.uuid}
                          id={'phase-' + phase.uuid}
                          defaultChecked={phase.uuid === this.props.task.phase}
                          name='project-phase'
                          onChange={this.handleChangePhase}
                          label={phase.name} />)}
                    </div>
                    <FormText color='muted'>
                      You can change the phase of this task
                    </FormText>
                  </FormGroup>}
                  <Button color='primary'
                    type='submit'
                    tabIndex={3}
                    disabled={!canSubmit || this.props.submitting}
                  >
            Update task
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>)
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ tasks, projects, phases, form }, ownProps) => {
  const task = tasks.find(u => u.uuid === ownProps.match.params.taskUuid)
  const project = task ? projects.items.find(u => u.uuid === task.project) : undefined
  return {
    task,
    project: task
      ? projects.items.find(u => u.uuid === task.project)
      : undefined,
    phases: task && project
      ? phases.filter(p => p.project === project.uuid)
      : undefined,
    submitting: form.submitting,
    errors: form.errors
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTask: () => dispatch(syncTask(ownProps.match.params.taskUuid)),
  syncProject: uuid => dispatch(syncProject(uuid)),
  syncPhases: uuid => dispatch(syncPhases(uuid)),
  updateTaskProperties: form => dispatch(updateTaskProperties(ownProps.match.params.taskUuid, form)),
  resetForm: () => dispatch(resetForm())
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(UpdateTask)
