import { CONST_APP_DEFAULT_TRANSLATION } from '../constants/App.Constants'

const initialState = {
  initialized: false,
  language: CONST_APP_DEFAULT_TRANSLATION.flag,
  dashboard: {
    syncing: false,
    syncedAt: 0 // sync after every minute maybe
  }
}

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'APP_SET_INITIALIZED':
      return Object.assign({}, state, {
        initialized: action.payload
      })
    case 'APP_SET_LANGUAGE':
      return Object.assign({}, state, {
        language: action.payload
      })
    case 'APP_SET_DASHBOARD_SYNCING':
      return Object.assign({}, state, {
        dashboard: {
          syncing: action.payload,
          syncedAt: !action.payload
            ? Date.now()
            : state.dashboard.syncedAt
        }
      })
    case 'RESET':
      // dont reset initialized flag :)
      return Object.assign({}, state, {
        dashboard: {
          syncing: false,
          syncedAt: 0
        }
      })
    default:
      return state
  }
}

export default AppReducer
