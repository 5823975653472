import {
  updateFile
} from '../../../actions/reducer/Files.Actions'

import qs from 'querystring'

const getSignature = (uuid) => async (dispatch, _Backend) => {
  await _Backend.auth
  const signature = qs.stringify({
    key: _Backend.access.uuid,
    token: _Backend.access.token
  })
  dispatch(updateFile(uuid, {
    signature,
    expiration: _Backend.access.expiration
  }))
  return Promise.resolve()
}

export default getSignature
