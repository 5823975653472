/**
 * Translations
 */

const en = {
  /* Sign in form */
  TXT_SIGN_IN_FORM__HEADING: 'Sign in',
  TXT_SIGN_IN_FORM__INPUT_LABEL_EMAIL: 'E-mail address',
  TXT_SIGN_IN_FORM__INPUT_PLACEHOLDER_EMAIL: 'E-mail address',
  TXT_SIGN_IN_FORM__INPUT_LABEL_PASSWORD: 'Password',
  TXT_SIGN_IN_FORM__INPUT_PLACEHOLDER_PASSWORD: 'Password',
  TXT_SIGN_IN_FORM__INPUT_LABEL_REMEMBER: 'Remember me',
  TXT_SIGN_IN_FORM__BUTTON_LABEL_SIGN_IN: 'Sign in',
  TXT_SIGN_IN_FORM__BUTTON_LABEL_FORGOTTEN_PASSWORD: 'Forgot your password?',
  TXT_SIGN_IN_FORM__ERR_GENERIC: 'Sign in failed, it seems we cannot receive your request at the moment, please try again later!',
  TXT_SIGN_IN_FORM__ERR_INVALID_EMAIL_OR_PASSWORD: 'Invalid e-mail address or password!',
  TXT_SIGN_IN_FORM__ERR_BLOCKED_ACCOUNT: 'This account is blocked!',
  TXT_SIGN_IN_FORM__FEEDBACK_PASSWORD_UPDATED: 'Password successfully updated, you can now sign in with the new password!',
  TXT_SIGN_IN_FORM__FEEDBACK_ACCOUNT_CREATED: 'Your account is ready to use, now you can sign in!',
  TXT_SIGN_IN_FORM__FEEDBACK_ACCOUNT_DELETED: 'Your account has been deleted!',

  /* Forgotten password form */
  TXT_FORGOTTEN_PASSWORD_FORM__HEADING: 'Forgotten password',
  TXT_FORGOTTEN_PASSWORD_FORM__INPUT_LABEL_EMAIL: 'E-mail address',
  TXT_FORGOTTEN_PASSWORD_FORM__INPUT_PLACEHOLDER_EMAIL: 'E-mail address',
  TXT_FORGOTTEN_PASSWORD_FORM__SUCCESS_MESSAGE: 'We sent you an email to reset your password!',
  TXT_FORGOTTEN_PASSWORD_FORM__ERR_GENERIC: 'It seems we cannot receive your request at the moment, please try again later!',
  TXT_FORGOTTEN_PASSWORD_FORM__BUTTON_LABEL_SEND: 'Send',
  TXT_FORGOTTEN_PASSWORD_FORM__BUTTON_LABEL_BACK_TO_SIGN_IN: 'Back to sign in',

  /* Reset password form */
  TXT_RESET_PASSWORD_FORM__HEADING: 'Reset password',
  TXT_RESET_PASSWORD_FORM__INPUT_LABEL_EMAIL: 'E-mail address',
  TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_EMAIL: 'E-mail address',
  TXT_RESET_PASSWORD_FORM__INPUT_LABEL_NEW_PASSWORD: 'New password',
  TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_NEW_PASSWORD: 'New password',
  TXT_RESET_PASSWORD_FORM__TOOLTIP_PASSWORD: 'Password requirements: minimum eight characters, at least one uppercase letter, one lowercase letter and one number.',
  TXT_RESET_PASSWORD_FORM__INPUT_LABEL_RETYPE_PASSWORD: 'Re-type new password',
  TXT_RESET_PASSWORD_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD: 'New password',
  TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_SEND: 'Reset',
  TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_GO_TO_SIGN_IN: 'Go to sign in',
  TXT_RESET_PASSWORD_FORM__BUTTON_LABEL_FORGOTTEN_PASSWORD: 'Forgotten password',
  TXT_RESET_PASSWORD_FORM__SUCCESS_MESSAGE: 'Password successfully set, you can now sign in with the new password!',
  TXT_RESET_PASSWORD_FORM__ERR_GENERIC: 'It seems we cannot receive your request at the moment, please try again later!',
  TXT_RESET_PASSWORD_FORM__ERR_INVALID_EMAIL: 'E-mail address is invalid!',
  TXT_RESET_PASSWORD_FORM__ERR_INVALID_REQUEST: 'This request is invalid, please check the link you opened!',
  TXT_RESET_PASSWORD_FORM__ERR_INVALID_PASSWORD: 'Password does not meet the requirements!',
  TXT_RESET_PASSWORD_FORM__ERR_NO_USER: 'It seems you have typed your e-mail address not correctly!',
  TXT_RESET_PASSWORD_FORM__ERR_WRONG_OR_EXPIRED_REQUEST: 'This request is invalid or expired, please check the link you opened or request a new one!',

  /* Update password form */
  TXT_UPDATE_PASSWORD_FORM__HEADING: 'Update password',
  TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_CURRENT_PASSWORD: 'Current password',
  TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_CURRENT_PASSWORD: 'Current password',
  TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_NEW_PASSWORD: 'New password',
  TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_NEW_PASSWORD: 'New password',
  TXT_UPDATE_PASSWORD_FORM__TOOLTIP_PASSWORD: 'Password requirements: minimum eight characters, at least one uppercase letter, one lowercase letter and one number.',
  TXT_UPDATE_PASSWORD_FORM__INPUT_LABEL_RETYPE_PASSWORD: 'Re-type new password',
  TXT_UPDATE_PASSWORD_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD: 'New password',
  TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_SEND: 'Update',
  TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_MODAL_OK: 'Ok, update',
  TXT_UPDATE_PASSWORD_FORM__BUTTON_LABEL_MODAL_CANCEL: 'Cancel',
  TXT_UPDATE_PASSWORD_FORM__ERR_GENERIC: 'It seems we cannot receive your request at the moment, please try again later!',
  TXT_UPDATE_PASSWORD_FORM__ERR_INVALID_OLD_PASSWORD: 'Current password does not meet the requirements, maybe you have typed it not correctly!',
  TXT_UPDATE_PASSWORD_FORM__ERR_INVALID_NEW_PASSWORD: 'New password does not meet the requirements!',
  TXT_UPDATE_PASSWORD_FORM__ERR_WRONG_PASSWORD: 'Current password incorrect!',

  /* Create account form */
  TXT_CREATE_ACCOUNT_FORM__HEADING: 'Create account',
  TXT_CREATE_ACCOUNT_FORM__TOOLTIP_EMAIL: 'Creating new account for e-mail address:',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_USERNAME: 'Username',
  TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_USERNAME: 'username',
  TXT_CREATE_ACCOUNT_FORM__TOOLTIP_USERNAME: 'Username is a readable unique name to identify you. Can only contain lowercase letters and numbers. (no accented or special chars)',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_PASSWORD: 'Password',
  TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_PASSWORD: 'Password',
  TXT_CREATE_ACCOUNT_FORM__TOOLTIP_PASSWORD: 'Password requirements: minimum eight characters, at least one uppercase letter, one lowercase letter and one number.',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_RETYPE_PASSWORD: 'Re-type password',
  TXT_CREATE_ACCOUNT_FORM__INPUT_PLACEHOLDER_RETYPE_PASSWORD: 'Password',
  TXT_CREATE_ACCOUNT_FORM__INPUT_LABEL_TERMS: 'I have read and understand the terms of use.',
  TXT_CREATE_ACCOUNT_FORM__BUTTON_LABEL_SEND: 'Create',
  TXT_CREATE_ACCOUNT_FORM__BUTTON_LABEL_GO_TO_SIGN_IN: 'Go to sign in',
  TXT_CREATE_ACCOUNT_FORM__ERR_GENERIC: 'It seems we cannot receive your request at the moment, please try again later!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_HASH: 'This request is invalid, please check the link you opened!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_EMAIL: 'E-mail address is invalid!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_USERNAME: 'Username does not meet the requirements!',
  TXT_CREATE_ACCOUNT_FORM__ERR_INVALID_PASSWORD: 'Password does not meet the requirements!',
  TXT_CREATE_ACCOUNT_FORM__ERR_USERNAME_EXISTS: 'The username is already in use! Choose a different one!',

  TXT_DEMO: 'Demo of %1$s',
  TXT_STATUS: 'Status',
  TXT_STATUS_OPEN: 'Open',
  TXT_STATUS_PENDING: 'Pending',
  TXT_STATUS_CLOSED: 'Closed',
  TXT_PRIORITY: 'Priority',
  TXT_PRIORITY_LOW: 'Low',
  TXT_PRIORITY_MEDIUM: 'Medium',
  TXT_PRIORITY_HIGH: 'High',
  TXT_PROJECT: 'Project',
  TXT_ASSIGNEES: 'Assignees',
  TXT_NO_ASSIGNEES: 'Not assigned yet',
  TXT_ASSIGN_ADD: 'Assign to',
  TXT_ASSIGN_REMOVE: 'Remove',
  TXT_PARTICIPANTS: 'Participants',
  TXT_ADD_TO_QUICK_OPEN: 'Add to quick open',
  TXT_ACTIVITIES: 'Activities',
  TXT_ACTIVITIES_CHANGED_STATUS: 'changed status to',
  TXT_ACTIVITIES_CHANGED_PRIORITY: 'changed priority to',
  TXT_ACTIVITIES_ASSIGNED: 'assigned',
  TXT_ACTIVITIES_REMOVED_ASSIGNMENT: 'removed assignment of',
  TXT_ACTIVITIES_COMMENTED: 'commented',
  TXT_ACTIVITIES_CREATED_TASK: 'created this task',
  TXT_ACTIVITIES_CREATED_TICKET: 'created this ticket',
  TXT_ACTIVITIES_CHANGED_ESTIMATED_WORK: 'changed estimated work value to',
  TXT_ESTIMATED_WORK: 'Estimated work',
  TXT_NO_ESTIMATED_WORK: 'No estimated value given',
  TXT_MAN_HOURS: '%1$s man-hours'
}

export default en
