export const initializeForm = id => ({
  type: 'FORMS_INITIALIZE',
  payload: id
})

export const destroyForm = id => ({
  type: 'FORMS_DESTROY',
  payload: id
})

export const setFormSubmitting = (id, submitting) => ({
  type: 'FORMS_SET_SUBMITTING',
  payload: {
    id,
    submitting
  }
})

export const setFormProperties = (id, props = {}) => ({
  type: 'FORMS_SET_PROPERTIES',
  payload: {
    id,
    props
  }
})

export const setFormErrors = (id, errors) => ({
  type: 'FORMS_SET_ERRORS',
  payload: {
    id,
    errors
  }
})

export const setFormMessage = (id, message) => ({
  type: 'FORMS_SET_MESSAGE',
  payload: {
    id,
    message
  }
})

export const resetForm = id => ({
  type: 'FORMS_RESET',
  payload: id
})
