import React from 'react'
import { connect } from 'react-redux'

import { syncPhases } from '../../../../actions/middleware/Projects.Actions'

import {
  Row,
  Col
} from 'reactstrap'

import { Link } from 'react-router-dom'

const Phase = props => {
  return (
    <Row>
      <Col>
        <h3>Phase details</h3>
        <hr />
          TODO: list of tasks in this phase maybe
        <hr />
        <Link to={props.location.pathname + '/update'}>Update phase name</Link>
      </Col>
    </Row>
  )
}

const mapStateToProps = ({ projects, phases }, ownProps) => {
  const { projectUuid, phaseUuid } = ownProps.match.params
  return {
    project: projects.items.find(pr => pr.uuid === projectUuid),
    phase: phases
      .find(p => p.project === projectUuid && p.uuid === phaseUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncPhases: () => dispatch(syncPhases(ownProps.match.params.projectUuid))
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { project, phase } = propsFromState
  let syncing = project ? project.phases.syncing : false
  let syncedAt = project ? project.phases.syncedAt : Date.now() - 10

  return Object.assign({},
    ownProps,
    propsFromDispatch,
    {
      syncing,
      syncedAt,
      phase
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Phase)
