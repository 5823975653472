import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  updateUserProfile
} from '../../../actions/reducer/User.Actions'

import { push } from 'connected-react-router'

const updateProfile = ({ name, phone, thumb }) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(true))

  return _Backend.call('updateProfile', { name, phone, thumb })
    .then(({ name, phone, thumb }) => {
      dispatch(updateUserProfile({ name, phone, thumb }))
      dispatch(push('/account/profile'))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default updateProfile
