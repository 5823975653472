import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap'
import classnames from 'classnames'

import FormStateWrapper from '../../../common/FormStateWrapper'

import CommentForm from './CommentForm'
import Upload from './Upload'

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1') }}
          >
            Comment
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2') }}
          >
            Upload file
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        activeTab={activeTab}
        className='bg-white border-left border-right border-bottom'>
        <TabPane tabId='1'>
          <Row>
            <Col>
              <FormStateWrapper
                uuid={props.uuid}
                component={CommentForm} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId='2'>
          <Row>
            <Col>
              <FormStateWrapper
                uuid={props.uuid}
                project={props.project}
                component={Upload} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  )
}

export default Tabs
