import React from 'react'

import { Switch, Route } from 'react-router-dom'
import ProtectedRoute from '../../../common/ProtectedRoute'

import List from './List'
import Create from './Create'

import NoMatch from '../../noMatch/NoMatch'

const Groups = props => {
  return (
    <Switch>
      <ProtectedRoute
        protectionOptions={{
          group: { value: 2, exact: true },
          level: { value: 2, exact: false }
        }}
        exact
        path={`${props.match.path}/create`}
        component={Create} />
      <Route path={`${props.match.path}/list`} component={List} />
      <Route exact path={`${props.match.path}/`} component={List} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default Groups
