import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
// link to files maybe? import { Link } from 'react-router-dom'
import { syncFileDescriptor } from '../../actions/middleware/Files.Actions'

class FileShortcut extends PureComponent {
  componentDidMount () {
    const { file } = this.props
    // case 1: no file
    // FYI files cannot be changed but deleted
    if (!file) {
      this.props.syncFileDescriptor()
    }
  }

  render () {
    const { file } = this.props
    return file
      ? file.error === 'NO_MATCH'
        ? <i>This file no longer exists</i>
        : <i style={{ wordWrap: 'break-word' }}>{file.filename}</i>
      : <span className='link-placeholder' />
  }
}

const mapStateToProps = ({ files }, ownProps) => ({
  file: files.find(u => u.uuid === ownProps.uuid)
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncFileDescriptor: () => dispatch(syncFileDescriptor(ownProps.uuid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FileShortcut)
