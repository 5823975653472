import {
  setFormSubmitting,
  setFormProperties,
  setFormErrors
} from '../../../actions/reducer/Forms.Actions'

const resolve = (
  {
    FormId,
    FormFields: { secret }
  }
) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(FormId, true))
  return _Backend.call('resolveEmail', secret)
    .then(({ email }) => {
      dispatch(setFormProperties(FormId, { resolvedEmail: email }))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['TXT_CREATE_ACCOUNT_FORM__ERR_GENERIC']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          for (const error of errorArr) {
            errors.push('TXT_CREATE_ACCOUNT_FORM__' + error)
          }
          return errors
        }
      }
      dispatch(setFormErrors(FormId, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default resolve
