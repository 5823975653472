import { createPhaseObject } from '../../../objects/create'

import {
  setFormSubmitting,
  setFormError
} from '../../../actions/reducer/Form.Actions'

import {
  addPhase
} from '../../../actions/reducer/Phases.Actions'

import { push } from 'connected-react-router'

const addPhaseOp = (project, name, creator) => (dispatch, _Backend) => {
  dispatch(setFormSubmitting(true))
  return _Backend.call('addPhase', project, name)
    .then(({ uuid }) => {
      const item = createPhaseObject({
        uuid,
        project,
        name,
        creator
      })

      dispatch(addPhase(item))
      dispatch(push(`/project/${project}/phases`))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['An error occurred during sign up. Please try again later!']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          if (errorArr.includes('NO_USER')) {
            errors.push('Invalid email address or password!')
          }

          if (errors.length === 0) {
            // unindentified error
            return fallBack
          } else {
            return errors
          }
        }
      }
      dispatch(setFormError(extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(false)))
}

export default addPhaseOp
