import React from 'react'
import { connect } from 'react-redux'

// import { Link } from 'react-router-dom'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import SwitchLanguage from '../common/SwitchLanguage'

const Footer = ({ isAuthenticated }) => (
  isAuthenticated && <Container fluid className='mt-3 py-3 bg-light'>
    <Container>
      <Row>
        <Col>
          <small>v0.0.21</small>
        </Col>
        <Col className='d-flex justify-content-end'>
          <SwitchLanguage />
        </Col>
      </Row>
    </Container>
  </Container>
)

const mapStateToProps = ({ user }) => ({
  isAuthenticated: !!user.uuid
})
const mapDispatchToProps = dispatch => ({ })

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer)
