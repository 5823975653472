export const addTask = tsk => ({
  type: 'TASKS_ADD',
  payload: tsk
})

export const updateTask = (uuid, props) => ({
  type: 'TASKS_UPDATE',
  payload: { uuid, props }
})

export const removeTask = uuid => ({
  type: 'TASKS_REMOVE',
  payload: uuid
})

export const setTaskSyncing = (uuid, syncing) => ({
  type: 'TASKS_SET_SYNCING',
  payload: {
    uuid,
    syncing
  }
})

export const setTaskActivitiesSyncing = (uuid, syncing) => ({
  type: 'TASKS_SET_ACTIVITIES_SYNCING',
  payload: {
    uuid,
    syncing
  }
})

export const setTaskFilesSyncing = (uuid, syncing) => ({
  type: 'TASKS_SET_FILES_SYNCING',
  payload: {
    uuid,
    syncing
  }
})

export const addTaskAssignee = (uuid, assignee) => ({
  type: 'TASKS_ADD_ASSIGNEE',
  payload: { uuid, assignee }
})

export const removeTaskAssignee = (uuid, assignee) => ({
  type: 'TASKS_REMOVE_ASSIGNEE',
  payload: { uuid, assignee }
})
