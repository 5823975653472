import React from 'react'
import { connect } from 'react-redux'

import {
  Row,
  Col
} from 'reactstrap'

import Add from './Add'
import List from './List'

import { Switch, Route, Redirect } from 'react-router-dom'

// import ProtectedComponent from '../../../common/ProtectedComponent'

const TicketsRouter = ({ match, projectStatus }) => (
  <Row>
    <Col>
      <Switch>
        {projectStatus === 2 && <Route
          exact
          path={`${match.path}/add`}
          component={Add} />}
        <Route
          exact
          path={`${match.path}/`}
          component={List} />
        <Redirect to='/404' />
      </Switch>
    </Col>
  </Row>
)

const mapStateToProps = ({ projects }, ownProps) => {
  const project = projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid)
  const projectStatus = project ? project.status : 0
  return {
    projectStatus
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketsRouter)
