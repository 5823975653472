import React from 'react'
import PropTypes from 'prop-types'

import { Button } from 'reactstrap'

const PreviewFile = ({ details, handleReject }) => {
  const { name, size } = details

  return (<div className='w-100 position-relative'>
    <Button close onClick={handleReject} />
    <div>Selected file: {name}</div>
    <span className='small text-muted'>File size: {(size / 1024000).toFixed(2)}MB</span>
  </div>)
}

PreviewFile.propTypes = {
  imagefile: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.file,
      name: PropTypes.string,
      size: PropTypes.number
    })
  )
}

export default PreviewFile
