import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Link } from 'react-router-dom'

import { syncTicket, syncTicketActivities } from '../../../actions/middleware/Tickets.Actions'
import { syncProject, syncParticipants } from '../../../actions/middleware/Projects.Actions'

import {
  CONST_TICKET_STATUSES
} from '../../../constants/Ticket.Constants'

import UserShortcut from '../../common/UserShortcut'
import ProtectedComponent from '../../common/ProtectedComponent'

import NoMatch from '../noMatch/NoMatch'

import {
  Row,
  Col,
  Container,
  Badge,
  Button,
  Breadcrumb,
  BreadcrumbItem
} from 'reactstrap'

import ReactMarkdown from 'react-markdown'
import linkify from 'markdown-linkify'

import Assignees from './components/Assignees'
import Activities from './components/Activities'
import FileList from './components/FileList'
import UpdateStatus from './components/UpdateStatus'

import Tabs from './components/Tabs'

// TODO: error, chaining syncs no project uuid while render link to phase

class Ticket extends Component {
  constructor () {
    super()
    this._statusBadge = this._statusBadge.bind(this)
  }

  componentDidMount () {
    if (!this.props.ticket ||
      (this.props.ticket.syncedAt + (10 * 1000) < Date.now() && !this.props.ticket.syncing)) {
      this.props.syncTicket()
    } else if (!this.props.project || !this.props.phase) {
      // TODO: better solution for sync chain
      const { project } = this.props.ticket
      this.props.syncProject(project)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // TODO: better solution for sync chain
    if (prevProps.ticket && prevProps.ticket.syncing && !this.props.ticket.syncing) {
      const { project } = this.props.ticket
      this.props.syncProject(project)
      this.props.syncParticipants(project)
    }
  }

  _statusBadge (status) {
    const { label } = CONST_TICKET_STATUSES.find(s => s.id === status)
    return (
      <Badge className={`mr-2 _ticketstatus-${status}`}>
        {label.generic} - {label.detailed}
      </Badge>
    )
  }

  render () {
    let existInState = !!this.props.ticket
    return existInState
      ? (
        this.props.ticket.error === 'NO_MATCH'
          ? <NoMatch />
          : <Container>
            <Row className='mt-3'>
              <Col>
                <Breadcrumb className='bg-light rounded'>
                  <BreadcrumbItem><Link to={'/projects'}>Projects</Link></BreadcrumbItem>
                  {(this.props.project) && <>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid}>{this.props.project.name}</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to={'/project/' + this.props.project.uuid + '/tickets'}>Tickets</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.props.ticket.name}</BreadcrumbItem>
                </>}
                </Breadcrumb>
              </Col>
            </Row>
            <Row className='my-3'>
              <Col>
                <h4>{this.props.ticket.name}</h4>
                {this._statusBadge(this.props.ticket.status)}<small className='text-muted'>ID {this.props.ticket.uuid}</small>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Row>
                  <Col className='mb-3'>
                    <Assignees
                      task={this.props.ticket.uuid}
                      assigned={this.props.ticket.assignees}
                      accesses={this.props.accesses} />
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <small className='text-muted'>Created by</small>
                    <div>
                      {this.props.ticket.creator && <UserShortcut
                        display={{ thumb: true, username: false, size: 'small' }}
                        uuid={this.props.ticket.creator} />}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button tag={Link} to={'/ticket/' + this.props.ticket.uuid + '/update'}>
                        Update ticket
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <small className='text-muted'>Files</small>
                    <FileList
                      uuid={this.props.ticket.uuid}
                      {...this.props.ticket.files}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={8}>
                <ReactMarkdown
                  className='bg-light rounded p-3'
                  source={linkify(this.props.ticket.description)} />
                <Activities
                  uuid={this.props.ticket.uuid}
                  {...this.props.ticket.activities}
                />
                {this.props.project && <Tabs
                  uuid={this.props.ticket.uuid}
                  project={this.props.project.uuid} />}
                <ProtectedComponent
                  protectionOptions={{
                    group: { value: 1, exact: false },
                    level: { value: 0, exact: false }
                  }}>
                  <Row>
                    <Col>
                      <UpdateStatus
                        ticket={this.props.ticket.uuid}
                        status={this.props.ticket.status} />
                    </Col>
                  </Row>
                </ProtectedComponent>
              </Col>
            </Row>
          </Container>
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ tickets, projects, users, groups, accesses }, ownProps) => {
  const ticket = tickets.find(u => u.uuid === ownProps.match.params.ticketUuid)
  return {
    ticket,
    project: ticket
      ? projects.items.find(u => u.uuid === ticket.project)
      : undefined,
    accesses: ticket
      ? accesses.filter(x => x.project === ticket.project)
      : [],
    users: ticket
      ? users.items
        .filter(u => accesses
          .filter(x => x.project === ticket.project)
          .findIndex(a => a.entity === u.uuid) > -1 &&
          u.role.group > 1)
      : [],
    groups: ticket
      ? groups.items
        .filter(g => accesses
          .filter(x => x.project === ticket.project)
          .findIndex(a => a.entity === g.uuid) > -1)
      : []
  }
}

// fetchProject, syncProject ??
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncTicket: () => dispatch(syncTicket(ownProps.match.params.ticketUuid)),
  syncTicketActivities: () => dispatch(syncTicketActivities(ownProps.match.params.ticketUuid)),
  syncProject: uuid => dispatch(syncProject(uuid)),
  syncParticipants: uuid => dispatch(syncParticipants(uuid))
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Ticket)
