import {
  createActivityObject
} from '../../../objects/create'

import {
  setAppDashboardSyncing
} from '../../../actions/reducer/App.Actions'

import {
  addActivity
} from '../../../actions/reducer/Activities.Actions'

const getActivities = () => (dispatch, _Backend) => {
  dispatch(setAppDashboardSyncing(true))
  return _Backend.call('getAllActivities')
    .then(activities => {
      for (const act of activities) {
        dispatch(
          addActivity(
            createActivityObject(act)
          )
        )
      }
      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setAppDashboardSyncing(false)))
}

export default getActivities
