import React from 'react'

import { Switch, Route } from 'react-router-dom'

import Community from './Community'
import Users from './users/Index'
import Groups from './groups/Index'

import NoMatch from '../noMatch/NoMatch'

const CommunityRoutes = props => {
  return (
    <Switch>
      <Route path={`${props.match.path}/users`} component={Users} />
      <Route path={`${props.match.path}/groups`} component={Groups} />
      <Route exact path={`${props.match.path}/`} component={Community} />
      <Route component={NoMatch} />
    </Switch>
  )
}

export default CommunityRoutes
