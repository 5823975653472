const initialState = []

const TasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'TASKS_ADD':
      return state.some(task => task.uuid === action.payload.uuid)
        ? state
        : [...state, action.payload]
    case 'TASKS_UPDATE':
      return state.map(tsk => {
        if (tsk.uuid === action.payload.uuid) {
          return Object.assign({}, tsk, action.payload.props)
        } else {
          return tsk
        }
      })
    case 'TASKS_REMOVE':
      return state.filter(tsk => tsk.uuid !== action.payload)
    case 'TASKS_SET_SYNCING':
      return state.map(tsk => {
        if (tsk.uuid === action.payload.uuid) {
          return Object.assign({}, tsk, { syncing: action.payload.syncing })
        } else {
          return tsk
        }
      })
    case 'TASKS_ADD_ASSIGNEE':
      return state.map(tsk => {
        if (tsk.uuid === action.payload.uuid) {
          return Object.assign(
            {},
            tsk,
            { // remove duplicates :)
              assignees: [action.payload.assignee, ...tsk.assignees].filter((item, pos, self) => {
                return self.indexOf(item) === pos
              })
            }
          )
        } else {
          return tsk
        }
      })
    case 'TASKS_REMOVE_ASSIGNEE':
      return state.map(tsk => {
        if (tsk.uuid === action.payload.uuid) {
          return Object.assign(
            {},
            tsk,
            {
              assignees: tsk.assignees.filter(item => item !== action.payload.assignee)
            }
          )
        } else {
          return tsk
        }
      })
    case 'TASKS_SET_ACTIVITIES_SYNCING':
      return state.map(tsk => {
        if (tsk.uuid === action.payload.uuid) {
          return Object.assign({}, tsk, {
            activities: {
              syncing: action.payload.syncing,
              syncedAt: action.payload.syncing ? tsk.activities.syncedAt : Date.now()
            }
          }
          )
        } else {
          return tsk
        }
      })
    case 'TASKS_SET_FILES_SYNCING':
      return state.map(tsk => {
        if (tsk.uuid === action.payload.uuid) {
          return Object.assign({}, tsk, {
            files: {
              syncing: action.payload.syncing,
              syncedAt: action.payload.syncing ? tsk.files.syncedAt : Date.now()
            }
          }
          )
        } else {
          return tsk
        }
      })
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default TasksReducer
