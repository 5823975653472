import { createFileObject } from '../../../objects/create'

import {
  addFile
} from '../../../actions/reducer/Files.Actions'

import {
  setTicketFilesSyncing
} from '../../../actions/reducer/Ticket.Actions'

const syncFiles = (ticket) => (dispatch, _Backend) => {
  dispatch(setTicketFilesSyncing(ticket, true))
  return _Backend.call('getTicketFiles', ticket)
    .then(descriptors => {
      for (let fileDescriptor of descriptors) {
        dispatch(addFile(
          createFileObject(fileDescriptor)
        ))
      }

      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setTicketFilesSyncing(ticket, false)))
}

export default syncFiles
