import { connect } from 'react-redux'

import React from 'react'

import translate from '../../utils/translations/Translate'

const Translated = ({ language, txtid, bind = [] }) => (
  <React.Fragment>{translate(language, txtid, ...bind)}</React.Fragment>
)

const mapStateToProps = ({ app: { language } }) => ({
  language
})

export default connect(
  mapStateToProps,
  {}
)(Translated)
