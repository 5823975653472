import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import { push } from 'connected-react-router'

// import { CONST_PROJECT_STATUSES } from '../../../../constants/Project.Constants'

import {
  Form,
  FormGroup,
  Input
} from 'reactstrap'

// import Button from '../../../common/Button'

const removeEmptyProps = (obj) => {
  Object.keys(obj).forEach(key => (obj[key] === '') && delete obj[key])
  return obj
}

class Filters extends Component {
  constructor () {
    super()
    this.state = {
      query: '',
      order: '', // later maybe
      group: '' // later maybe
    }

    this.delayedSearch = null

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleQueryChange = this.handleQueryChange.bind(this)
  }

  componentWillUnmount () {
    clearTimeout(this.delayedSearch)
  }

  handleQueryChange (e) {
    clearTimeout(this.delayedSearch)
    const query = e.target.value
    this.setState({ query })
    const searchParams = removeEmptyProps({ query })
    this.delayedSearch = setTimeout(
      () => this.props.search(searchParams),
      300 // prevent spamming
    )
  }

  handleSubmit (e) {
    e.preventDefault()
    const { query } = this.state
    clearTimeout(this.delayedSearch)
    const searchParams = removeEmptyProps({ query })
    this.delayedSearch = setTimeout(
      () => this.props.search(searchParams),
      300
    )
  }

  render () {
    const searchParams = qs.parse(this.props.location.search)
    return (
      <Form onSubmit={this.handleSubmit} inline>
        <FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
          <Input
            type='text'
            required
            defaultValue={searchParams.query || ''}
            onChange={this.handleQueryChange}
            name='search-query'
            placeholder='Projekt keresése...'
          />
        </FormGroup>
      </Form>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = (dispatch, ownProps) => ({
  search: (paramsObj) => dispatch(push(
    {
      pathname: '/projects', // no re-assign, always reset page number
      search: qs.stringify(paramsObj)
    }
  ))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Filters))
