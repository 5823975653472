import { createFileObject } from '../../../objects/create'

import {
  addFile
} from '../../../actions/reducer/Files.Actions'

import {
  setTaskActivitiesSyncing
} from '../../../actions/reducer/Task.Actions'

const syncFiles = (task) => (dispatch, _Backend) => {
  dispatch(setTaskActivitiesSyncing(task, true))
  return _Backend.call('getTaskFiles', task)
    .then(descriptors => {
      for (let fileDescriptor of descriptors) {
        dispatch(addFile(
          createFileObject(fileDescriptor)
        ))
      }

      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => dispatch(setTaskActivitiesSyncing(task, false)))
}

export default syncFiles
