import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncPhases } from '../../../../actions/middleware/Projects.Actions'

import {
  Row,
  Col,
  Button
} from 'reactstrap'

import Add from './Add'
import Update from './Update'
import Phase from './Phase'
import List from './List'

import { Switch, Route, NavLink } from 'react-router-dom'

import ProtectedComponent from '../../../common/ProtectedComponent'
// import ProtectedRoute from '../../../common/ProtectedRoute'

class Phases extends Component {
  componentDidMount () {
    if (!this.props.syncedAt || this.props.syncedAt + (10 * 1000) < Date.now()) {
      this.props.syncPhases()
    }
  }

  render () {
    // TODO: no render if not synced
    return (
      <Row>
        <Col>
          <ProtectedComponent
            protectionOptions={{
              group: { value: 2, exact: false },
              level: { value: 2, exact: false }
            }}>
            <Button onClick={this.props.syncPhases}>Force sync phases</Button>
            <NavLink to={`/project/${this.props.projectUuid}/phases/add`}>Add phase</NavLink>
          </ProtectedComponent>
          <Switch>
            <Route exact path={`${this.props.match.path}/add`} component={Add} />
            <Route exact path={`${this.props.match.path}/:phaseUuid/update`} component={Update} />
            <Route path={`${this.props.match.path}/:phaseUuid`} component={Phase} />
            <Route component={List} />
          </Switch>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = ({ projects, phases }, ownProps) => {
  return {
    project: projects.items.find(pr => pr.uuid === ownProps.match.params.projectUuid),
    phases: phases
      .filter(p => p.project === ownProps.match.params.projectUuid)
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  syncPhases: () => dispatch(syncPhases(ownProps.match.params.projectUuid))
})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => {
  const { project, phases } = propsFromState
  let syncing = project ? project.phases.syncing : false
  let syncedAt = project ? project.phases.syncedAt : Date.now() - 10

  return Object.assign({},
    ownProps,
    propsFromDispatch,
    {
      projectUuid: project.uuid,
      syncing,
      syncedAt,
      phases
    })
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Phases)
