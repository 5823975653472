import { createGroupObject } from '../../../objects/create'

import {
  setGroupSyncing,
  addGroup,
  updateGroup
} from '../../../actions/reducer/Groups.Actions'

const syncGroup = (uuid, index) => (dispatch, _Backend) => {
  // TODO: set syncing!
  // TODO: create at this point if no index!
  if (index > -1) {
    dispatch(setGroupSyncing(uuid, true))
  } else {
    dispatch(addGroup(createGroupObject({
      uuid,
      syncing: true
    })))
  }
  return _Backend.call('getGroup', uuid)
    .then((group) => {
      let item = createGroupObject(group)
      dispatch(updateGroup(item))
      return Promise.resolve()
    })
    .catch(err => {
      const errors = err.properties || []

      const item = createGroupObject({
        uuid,
        error: errors.length === 0 ? 'FAILED_TO_FETCH' : errors[0]
      })
      dispatch(updateGroup(item))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setGroupSyncing(uuid, false)))
}

export default syncGroup
