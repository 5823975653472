import {
  addAccess,
  removeAccess
} from '../../../actions/reducer/Access.Actions'

const participants = (projectUuid, stateAccesses) => (dispatch, _Backend) => {
  // update project participants syncing flag
  const oldAccesses = stateAccesses.filter(access => access.project === projectUuid)
  return _Backend.call('listParticipants', projectUuid)
    .then(({ accesses }) => {
      let newAccesses = []
      // handle accesses
      if (accesses) {
        for (let access of accesses) {
          newAccesses.push({
            project: projectUuid,
            entity: access.uuid,
            entityType: access.type,
            permanent: access.permanent
          })
        }
      }

      // handle access refreshing
      // difference between old and new array

      let removeArr = oldAccesses.filter(x => !newAccesses.includes(x))
      let addArr = newAccesses.filter(x => !oldAccesses.includes(x))

      for (const access of removeArr) {
        dispatch(removeAccess(access))
      }
      for (const access of addArr) {
        dispatch(addAccess(access))
      }

      return Promise.resolve()
    })
    .catch(err => {
      return Promise.reject(err)
    })
    .finally(() => console.log('fetch project finally'))
}

export default participants
