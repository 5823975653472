export const updateProfile = form => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'UPDATE_PROFILE',
    form
  }
})

export const updatePassword = form => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'UPDATE_PASSWORD',
    form
  }
})

export const deleteAccount = password => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'DELETE_ACCOUNT',
    password
  }
})

export const getSessions = () => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'GET_SESSIONS'
  }
})

export const invalidateSession = session => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'INVALIDATE_SESSION',
    session
  }
})

export const invalidateOtherSessions = () => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'INVALIDATE_OTHER_SESSIONS'
  }
})

// sign out from all session
export const invalidateAllSession = session => ({
  type: 'ACCOUNT',
  payload: {
    operation: 'INVALIDATE_ALL_SESSION',
    session
  }
})
