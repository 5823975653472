
import SyncList from './SyncList'
import SyncUser from './SyncUser'
import InviteUser from './InviteUser'
import UpdateRoleGroup from './UpdateRoleGroup'
import UpdateRoleLevel from './UpdateRoleLevel'

const users = API => {
  return {
    syncList: (...params) => dispatch => SyncList(...params)(dispatch, API),
    syncUser: (...params) => dispatch => SyncUser(...params)(dispatch, API),
    invite: (...params) => dispatch => InviteUser(...params)(dispatch, API),
    updateRoleGroup: (...params) => dispatch => UpdateRoleGroup(...params)(dispatch, API),
    updateRoleLevel: (...params) => dispatch => UpdateRoleLevel(...params)(dispatch, API)
  }
}

export default users
