const initialState = []

// elems: { project: '', task: '', ticket: '' ... }

const ActivitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVITIES_ADD':
      return state.some(el => el.uuid === action.payload.uuid)
        ? state
        : [...state, action.payload]
    case 'RESET':
      return initialState
    default:
      return state
  }
}

export default ActivitiesReducer
