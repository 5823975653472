const defaultObject = {
  uuid: '',
  project: '',
  task: '',
  ticket: '',
  createdBy: '',
  createdAt: Date.now(),
  filename: '',
  baseURL: '',
  signature: '',
  expiration: 0,
  error: '',
  syncedAt: Date.now(),
  syncing: false
}

export const createFileObject = p => {
  return Object.assign(
    {},
    defaultObject,
    p
  )
}
