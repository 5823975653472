import Create from './Create'
import SyncProjectTickets from './SyncProjectTickets'

const tickets = API => {
  return {
    create: (...params) => dispatch => Create(...params)(dispatch, API),
    syncProjectTickets: (...params) => dispatch => SyncProjectTickets(...params)(dispatch, API)
  }
}

export default tickets
