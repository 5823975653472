import {
  setAppInitialized,
  setAppLanguage
} from '../../../actions/reducer/App.Actions'
import {
  CONST_APP_DEFAULT_TRANSLATION,
  CONST_APP_TRANSLATIONS
} from '../../../constants/App.Constants'
import {
  setUser
} from '../../../actions/reducer/User.Actions'
import Cookies from 'universal-cookie'
const cookies = new Cookies()

const _getlanguage = () => {
  let language = cookies.get('language')
  if (language) {
    let isValidLanguage = CONST_APP_TRANSLATIONS.findIndex(l => l.flag === language) > -1
    return isValidLanguage
      ? language : CONST_APP_DEFAULT_TRANSLATION.flag
  } else {
    return CONST_APP_DEFAULT_TRANSLATION.flag
  }
}

const validateSession = () => (dispatch, _Backend) => {
  dispatch(setAppInitialized(false))
  let token = cookies.get('token')
  let session = cookies.get('session')

  const language = _getlanguage()
  dispatch(setAppLanguage(language))

  if (token && session) {
    return _Backend.call('requestAccess', session, token)
      .then(() => {
        // got access, set the session
        _Backend.session = { expiration: Date.now() + (30 * 24 * 60 * 60 * 1000), token, uuid: session }
        return _Backend.call('currentUserData')
      })
      .then(user => {
        // Improve expiration (or set for the first time)
        dispatch(setUser(user))
        return Promise.resolve()
      })
      .catch(err => {
        // no need extra actions, remove cookie
        cookies.remove('token', { path: '/' })
        cookies.remove('session', { path: '/' })
        return Promise.reject(err)
      })
      .finally(() => dispatch(setAppInitialized(true)))
  } else {
    dispatch(setAppInitialized(true))
    return Promise.resolve()
  }
}

export default validateSession
