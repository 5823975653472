import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap'

import FormStateWrapper from '../../../common/FormStateWrapper'
import UpdatePassword from './components/UpdatePassword'

const Settings = props => {
  return (
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <FormStateWrapper
            component={UpdatePassword} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>settings</h3>
          <ul>TODOS:
            <li>Security - invalidate sessions</li>
            <li>delete account</li>
          </ul>
        </Col>
      </Row>
    </Container>

  )
}

export default Settings
