// import functors
import { files, task, ticket } from './lib/Operations'

const chain = operations => dispatch => {
  if (!Array.isArray(operations)) {
    operations = [ operations ]
  }
  const promiseChain = operations.reduce((prev, curr) => {
    return prev.then(() => curr(dispatch))
  }, Promise.resolve())
  return promiseChain.catch(err => err)
}

const FilesMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type !== 'FILES') {
    return next(action)
  }
  switch (action.payload.operation) {
    case 'SYNC_LIST':
      chain(
        files.syncList(
          action.payload.project
        )
      )(dispatch)
      break
    case 'SYNC':
      chain(
        files.sync(
          action.payload.uuid
        )
      )(dispatch)
      break
    case 'UPLOAD':
      const chainedActions = [files.upload(
        action.payload.project,
        action.payload.form
      )]
      action.payload.form.FormFields.task && chainedActions.push(task.syncActivities(
        action.payload.form.FormFields.task
      ))
      action.payload.form.FormFields.ticket && chainedActions.push(ticket.syncActivities(
        action.payload.form.FormFields.ticket
      ))
      chain(
        chainedActions
      )(dispatch)
      break
    case 'GET_SIGNATURE':
      chain(
        files.sign(
          action.payload.uuid
        )
      )(dispatch)
      break
    case 'DELETE':
      chain(
        files.delete(
          action.payload.uuid
        )
      )(dispatch)
      break
    default:
      return next(action)
  }
}

export default FilesMiddleware
