import React, { Component } from 'react'
import { connect } from 'react-redux'

import { syncGroup, updateGroup } from '../../../../actions/middleware/Groups.Actions'
import { resetForm } from '../../../../actions/reducer/Form.Actions'

import { Link } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from 'reactstrap'

import GroupShortcut from '../../../common/GroupShortcut'

import NoMatch from '../../noMatch/NoMatch'
import Preview from './Preview'

import { compressImage } from '../../../common/Utils'

class UpdateGroup extends Component {
  constructor () {
    super()
    this.state = {
      name: '',
      thumb: null,
      isValidName: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChangeName = this.handleChangeName.bind(this)
    this.handleChangeRole = this.handleChangeRole.bind(this)
    this.handleChangeThumb = this.handleChangeThumb.bind(this)
    this.handleRejectThumb = this.handleRejectThumb.bind(this)
  }

  handleSubmit (e) {
    e.preventDefault()
    let { name, role, thumb } = this.state
    if (thumb) thumb = thumb.file
    this.props.updateGroup({ name, role, thumb })
  }

  componentDidMount () {
    if (this.props.group) {
      this.setState({ name: this.props.group.name, isValidName: true })
    }
    if (!this.props.group ||
      (this.props.group.syncedAt + (10 * 1000) < Date.now() && !this.props.group.syncing)) {
      this.props.syncGroup()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.group && this.props.group) {
      this.setState({ name: this.props.group.name, isValidName: true })
    }
  }

  componentWillUnmount () {
    this.props.resetForm()
  }

  handleChangeName (e) {
    let name = e.target.value
    this.setState({
      name,
      isValidName: !!name
    })
  }

  handleChangeRole (e) {
    let role = e.target.value
    this.setState({
      role
    })
  }

  async handleChangeThumb (e) {
    // let _thumb = e.target.files[0]
    let _thumb = await compressImage(e, { width: 256, height: 256 })
    const thumb = {
      file: _thumb,
      name: _thumb.name,
      preview: URL.createObjectURL(_thumb),
      size: _thumb.size
    }

    this.setState({
      thumb
    })
  }

  handleRejectThumb (e) {
    this.setState({
      thumb: null
    })
  }

  render () {
    const { isValidName } = this.state
    const canSubmit = isValidName
    let existInState = !!this.props.group
    return existInState
      ? (
        this.props.group.error === 'NO_MATCH'
          ? <NoMatch />
          : <Container>
            <Row className='justify-content-center'>
              <Col md={10} lg={6}>
                <h1>Update group</h1>
                <div>
                  <GroupShortcut uuid={this.props.group.uuid} />
                </div>
                <Form onSubmit={this.handleSubmit} encType='multipart/form-data' className='mb-4'>
                  {this.props.errors.length > 0 &&
                  <Alert color='danger' className='text-center'>
                    {this.props.errors.map(el => el).join(' ')}
                  </Alert>}
                  <FormGroup>
                    <Label for='project-name'>Group name</Label>
                    <Input
                      type='text'
                      maxLength={128}
                      name='group-name'
                      id='group-name'
                      value={this.state.name}
                      tabIndex={1}
                      placeholder='Group name'
                      disabled={this.props.submitting}
                      invalid={!!this.state.name && !isValidName}
                      onChange={this.handleChangeName}
                      required />
                    <FormText color='muted'>
                    128/{this.state.name.length}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for='group-thumb'>{this.props.group.thumb.n ? 'Replace' : 'Upload'} thumbnail for group</Label>
                    {this.state.thumb
                      ? <Preview
                        handleReject={this.handleRejectThumb}
                        details={this.state.thumb} />
                      : <Input
                        type='file'
                        name='group-thumb'
                        id='group-thumb'
                        onChange={this.handleChangeThumb} />
                    }
                    <FormText color='muted'>
                    Thumbnail will be cropped to square and resized.
                    </FormText>
                  </FormGroup>
                  <Link to={`/group/${this.props.group.uuid}`}>Back</Link>
                  <Button color='primary'
                    type='submit'
                    tabIndex={3}
                    disabled={!canSubmit || this.props.submitting}
                  >
                  Update
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
      ) : 'not exist yet in state, show loading'
  }
}

const mapStateToProps = ({ form, groups }, ownProps) => ({
  group: groups.items.find(g => g.uuid === ownProps.match.params.groupUuid),
  submitting: form.submitting,
  errors: form.errors
})
const mapDispatchToProps = (dispatch, ownProps) => ({
  syncGroup: () => dispatch(syncGroup(ownProps.match.params.groupUuid)),
  updateGroup: form => dispatch(updateGroup(ownProps.match.params.groupUuid, form)),
  resetForm: () => dispatch(resetForm())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateGroup)
