import {
  setFormSubmitting,
  resetForm,
  setFormErrors
} from '../../../actions/reducer/Forms.Actions'
import {
  setUser
} from '../../../actions/reducer/User.Actions'
import { push } from 'connected-react-router'
import Cookies from 'universal-cookie'
import qs from 'query-string'

const cookies = new Cookies()

const saveSession = ({ uuid, token, expiration }, remember) => {
  const expires = remember ? new Date(expiration) : undefined
  const cookieOptions = { path: '/', expires }
  // TODO: .env file
  if (process.env.NODE_ENV === 'production') {
    cookieOptions.secure = true
    cookieOptions.domain = '.infinite-edu.hu'
  }

  cookies.set('session', uuid, cookieOptions)
  cookies.set('token', token, cookieOptions)
}

const signIn = (
  {
    FormId,
    FormFields: { email, password, remember }
  },
  query
) => (dispatch, _Backend) => {
  dispatch(resetForm(FormId))
  dispatch(setFormSubmitting(FormId, true))

  let { returnto } = qs.parse(query)

  return _Backend.call('signIn', { email, password })
    .then(session => {
      _Backend.session = session
      saveSession(session, remember)
      return _Backend.call('currentUserData')
    })
    .then(user => {
      dispatch(setUser(user))
      dispatch(push(returnto || '/'))
      return Promise.resolve()
    })
    .catch(err => {
      const errorArr = err.properties || []
      const extractErrorMessages = errorArr => {
        const fallBack = ['TXT_SIGN_IN_FORM__ERR_GENERIC']
        if (errorArr.length === 0) {
          return fallBack
        } else {
          const errors = []
          for (const error of errorArr) {
            errors.push('TXT_SIGN_IN_FORM__' + error)
          }
          return errors
        }
      }
      dispatch(setFormErrors(FormId, extractErrorMessages(errorArr)))
      return Promise.reject(err)
    })
    .finally(() => dispatch(setFormSubmitting(FormId, false)))
}

export default signIn
